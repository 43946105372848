@if (checkTypeCode !== 'dpe') {
  @if (checkResult) {
    <div class="dpe-cell fx-layout-row fx-justify-start fx-align-center">
      <div class="label-container">
        <div class="label-content"
        [ngClass]="checkResult | checkResultColour: {checkType: checkTypes[checkTypeCode]}"></div>
      </div>
      <div class="result-text">
        {{ checkResult }}
      </div>
    </div>
  }
  @if (!checkResult) {
    <span> {{ emptyValue }}</span>
  }
} @else {
  <div class="dpe-cell fx-layout-row fx-justify-start fx-align-center fx-gap-5">
    @if (climateLabel) {
      <div [ngClass]="'climate-color-' + climateLabel.toLowerCase()"
        class="label-content dpe-label-content not-hoverable">
        {{ climateLabel }}
      </div>
    }
    @if (powerLabel) {
      <div [ngClass]="'power-color-' + powerLabel.toLowerCase()"
        class="label-content dpe-label-content not-hoverable">
        {{ powerLabel }}
      </div>
    }
    @if (!climateLabel || !powerLabel) {
      <span> {{ emptyValue }}</span>
    }
  </div>
}
