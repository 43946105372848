import { inject, Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Document } from '@app/core/model/entities/document/document';
import { Entity } from '@app/core/model/entities/entity';
import { Investment } from '@app/core/model/entities/investment/investment';
import { PopupManager, PopupSize } from '@app/core/services/managers/popup.manager';
import { InvestmentsService } from '@app/features/main/views/investments/investment-sheet/features/investments.service';
import {
  InvestmentCreateModalComponent
} from '@app/features/main/views/investments/investments-inventory/modals/investment-create-modal/investment-create-modal.component';
import { SpinnerService } from '@app/shared/popup/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, finalize, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DocumentModalService } from '../../../organization-documents/modals/document-modal.service';

@Injectable()
export class InvestmentModalService {

  private popupManager = inject(PopupManager);
  private analyticsService = inject(AnalyticsService);
  private spinnerService = inject(SpinnerService);
  private documentModalService = inject(DocumentModalService);
  private investmentsService = inject(InvestmentsService);
  private translate = inject(TranslateService);

  /**
   * Display a dialog for the user to fill data then create a new Investment with the data.
   * @param entity Entity to link the new Investment to.
   * @param entityType Type of the entity (ASSET, WORK, PROJECT or INVESTMENT).
   * @return Created Investment.
   */
  public openCreateInvestmentDialog(entity: Entity, entityType: EntityTypeEnum): Observable<Investment> {
    const dialogRef = this.popupManager.showGenericPopup(
      InvestmentCreateModalComponent,
      PopupSize.MEDIUM,
      {entity, entityType}
    );

    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const event = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.INVESTMENT);

          if (dialogResponse === 'yes') {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE});
            this.analyticsService.trackEvent(event);
            this.spinnerService.showSpinner();
            return this.investmentsService.createInvestment(dialogRef.componentInstance.getGeneratedObject())
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(event);
            return EMPTY;
          }
        })
      );
  };

  /**
   * Display a dialog asking the User to confirm Investments' deletion, then delete the Investments if confirmed.
   * @param investments Investments to delete.
   * @return True if the Investments were successfully deleted, false otherwise.
   * @throws Any encountered errors.
   */
  public openDeleteInvestmentsDialog(investments: Investment[]): Observable<boolean> {
    const dialogRef = this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant(investments.length === 1 ? 'TITLE.DELETE_INVESTMENT' : 'TITLE.DELETE_INVESTMENTS'),
      dialogMessage: this.translate.instant(investments.length === 1 ? 'MESSAGE.DELETE_INVESTMENT_AND_DOCUMENTS' : 'MESSAGE.DELETE_INVESTMENTS_AND_DOCUMENTS'),
      type: 'warning',
      okText: this.translate.instant('LABEL.DELETE')
    });

    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const event = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.INVESTMENT);

          if (dialogResponse === 'yes') {
            event.addProperties({
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.DELETE,
              [AnalyticsKeyEnum.ENTITY_ID]: investments.map(investment => investment.id).toString()
            });
            this.analyticsService.trackEvent(event);
            this.spinnerService.showSpinner();
            return this.investmentsService.deleteInvestments(investments)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(event);
            return of(false);
          }
        })
      );
  }

  /**
   * Display a dialog for the User to upload files and create Documents related to an Investment.
   * @param investmentId ID of the Investment to link Documents to.
   * @return Created Documents.
   * @throws Any encountered errors.
   */
  public openUploadInvestmentDocumentsDialog(investmentId: string): Observable<Document[]> {
    return this.documentModalService.openUploadEntityDocumentsDialog(
      investmentId,
      EntityTypeEnum.INVESTMENT,
      DocumentTypeEnum.INVESTMENT_DOCUMENT
    );
  }

  /**
   * Display a dialog asking the User to confirm an Investment's Document's deletion.
   * @param document Document to delete.
   * @return True if the Document was successfully deleted of false if the operation was canceled by the User.
   * @throws Any encountered errors.
   */
  public openDeleteInvestmentDocumentDialog(document: Document): Observable<boolean> {
    return this.documentModalService.openDeleteEntityDocumentDialog(document);
  }
}
