<div class="inline-field-container">

  @if (!editMode) {
    <div class="field-container fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div (click)="makeEditable()" (keyup.enter)="makeEditable()" [ngClass]="{'disabled': disabled}" class="read-value"
        tabindex="0">
        <span (click)="makeEditable()" class="inline-edit-value selectable">
          {{ control?.value }}
        </span>
        @if (!disabled) {
          <div (click)="makeEditable()" class="inline-edit-content">
            <span class="overlay-icon">
              <i class="mdi mdi-pencil selectable"></i>
            </span>
          </div>
        }
      </div>
    </div>
  }

  @if (editMode && !disabled) {
    <div class="field-container fx-flex-100-percent fx-layout-col">
      <form autocomplete="off">
        <mat-form-field appearance="outline" >
          <mat-label>{{ label }}</mat-label>
          <input #inputField (keyup.enter)="onKey($event)" (keyup.escape)="onKey($event)"
            [formControl]="control" class="edit-value" matInput type="text">
          @for (validator of validators; track validator.type) {
            <mat-error>
              @if (control.hasError(validator.type)) {
                <span>
                  {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                </span>
              }
            </mat-error>
          }
        </mat-form-field>
      </form>
      @if (editMode) {
        <div class="save-options">
          <button (click)="cancelEditable()" (keyup.escape)="onKey($event)" class="button">
            <i class="mdi mdi-close"></i>
          </button>
          <button (click)="callSave()" (keyup.escape)="onKey($event)" [disabled]="preventSave()"
            class="button save">
            <i class="mdi mdi-check"></i>
          </button>
        </div>
      }
    </div>
  }
</div>
