<div class="inline-field-container">

  @if (!editMode) {
      <div (click)="makeEditable()" (keyup.enter)="makeEditable()"
        class="field-container fx-layout-row fx-wrap fx-justify-start fx-align-baseline" tabindex="0">
        <div [ngClass]="{'disabled': disabled}" class="read-value">
          <span (click)="makeEditable()" class="inline-edit-value selectable">
            {{ (control?.value ?? 0) | fieldFormatType: 'precise_currency' : appManager.currentOrganization.currency }}
          </span>
          @if (suffix) {
            <span>{{ suffix }}</span>
          }
          @if (!disabled) {
            <div (click)="makeEditable()" class="inline-edit-content">
              <span class="overlay-icon">
                <i class="mdi mdi-pencil selectable"></i>
              </span>
            </div>
          }
        </div>
      </div>
  } @else {
    <div class="field-container fx-flex-100-percent fx-layout-col">
      <form autocomplete="off">
        <mat-form-field [floatLabel]="'auto'" appearance="outline">
          <input #inputField (beforeinput)="validateKeypressEvent($event)" (focus)="onFocus()"
            (keyup.enter)="onKey($event)" (keyup.escape)="onKey($event)" (paste)="validatePasteEvent($event)"
            [formControl]="control" class="edit-value" matInput tabindex="0" type="text">
          @if (suffix) {
            <span matSuffix class="unit-crv-suffix">{{ suffix }}</span>
          }
          @if (control.hasError('required')) {
            <mat-error
              class="small-font text-danger support-text">
              {{ 'ERROR.FIELD_REQUIRED' | translate }}
            </mat-error>
          }
          @if (control.hasError('decimal')) {
            <mat-error
              class="small-font text-danger support-text">
              {{ 'ERROR.FIELD_DECIMAL' | translate }}
            </mat-error>
          }
          @if (control.hasError('gte')) {
            <mat-error class="small-font text-danger support-text">
              {{ 'ERROR.MIN_VALUE' | translate: {value: 0} }}
            </mat-error>
          }
        </mat-form-field>
      </form>
      <ng-container *ngTemplateOutlet="saveOptions"></ng-container>
    </div>
  }

  <ng-template #empty>
    <div class="empty-field-container">
      <div [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
        class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <div class="empty-value">
            @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
              <ng-container>
                <add-field-link (click)="onActivate($event)" (keyup.enter)="onActivate($event)"
                [inputMessage]="'LABEL.EMPTY_' + fieldCode.toUpperCase()"></add-field-link>
              </ng-container>
            } @else {
              <ng-template>
                {{ appConfig.EMPTY_FIELD_VALUE }}
              </ng-template>
            }
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="saveOptions"></ng-container>
    </div>
  </ng-template>


  <ng-template #saveOptions>
    @if (editMode) {
      <div class="save-options">
        <button (click)="cancelEditable()" (keyup.escape)="onKey($event)" class="button">
          <i class="mdi mdi-close"></i>
        </button>
        <button (click)="callSave()" (keyup.escape)="onKey($event)" [disabled]="preventSave()"
          class="button save">
          <i class="mdi mdi-check"></i>
        </button>
      </div>
    }
  </ng-template>
</div>
