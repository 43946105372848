import { inject, Injectable } from '@angular/core';
import { Client } from '@app/core/model/client/client';
import { User } from '@app/core/model/client/user';
import { Entity } from '@app/core/model/entities/entity';
import { thenReturn } from '@app/shared/extra/utils';
import ApiService from '@services/api.service';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { map, merge, Observable, of, tap } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UsersService {
  private apiService = inject(ApiService);

  /**
   * Check whether an email address is currently being used by a user.
   * @param email Email address to check.
   * @return Observable that emits a single boolean value indicating whether a user was found.
   */
  public userExists(email: string): Observable<boolean> {
    const query = gql`
      query UserExists($email: String!) {
        userExists(email: $email)
      }
    `;
    const variables = {email};
    return this.apiService.query({query, variables})
      .pipe(map(data => data['userExists'] as boolean));
  }

  /**
   * Retrieve information about the User that created an entity and the last User who updated it
   * and update the entity's fields accordingly. First looks for the User in the Apollo cache, then makes an API request
   * if the User was not found in it.
   * @param entity Entity which creation User and last change User's info should be filled.
   * @return An Observable that will emit the updated Entity.
   */
  public fetchUsersInfo<T extends Entity>(entity: T): Observable<T> {
    // If creationUserId or lastChangeUserId is missing
    if (!entity.creationUserId || !entity.lastChangeUserId) {
      return of(entity);
    }

    const query = gql`
      query UserInfo($userId: Int!) {
        userInfo(id: $userId) {
          id
          name
        }
      }
    `;

    return merge(
      // Split into two queries because of Apollo caching optimizations.
      this.apiService.query({query, variables: {userId: entity.creationUserId}, fetchPolicy: 'cache-first'})
        .pipe(tap(data => entity.creationUser = plainToInstance(User, data['userInfo']))),
      this.apiService.query({query, variables: {userId: entity.lastChangeUserId}, fetchPolicy: 'cache-first'})
        .pipe(tap(data => entity.lastChangeUser = plainToInstance(User, data['userInfo'])))
    )
      .pipe(thenReturn(entity));
  }

  /**
   * Send or resend an invitation to a User. A related Client must be provided to generate an invitation which will
   * allow the user to sign up using the authentication method of that client.
   * @param user User to whom to send an invitation.
   * @param client Client through which to invite the user to the app.
   * @return An Observable that will emit the User once their invitation has been sent, with lastInvite updated.
   */
  public resendInvitation(user: User, client: Client): Observable<User> {
    const mutation = gql`
      mutation ResendInvitation($userId: Int!, $clientId: Int!) {
        resendUserInvitation(userId: $userId, clientId: $clientId) {
          id
          name
          lastInvite
        }
      }
    `;
    const variables = {
      userId: user.id,
      clientId: client.id
    };
    return this.apiService.mutate({mutation, variables})
      .pipe(map(data => plainToInstance(User, data['resendUserInvitation'])));
  }
}
