import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentStateEnum } from '@app/core/enums/document/document-state.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Document } from '@app/core/model/entities/document/document';
import { Equipment, EquipmentInput } from '@app/core/model/entities/equipments/equipment';
import {
  EquipmentModalService
} from '@app/features/main/views/equipments/equipments-inventory/modals/equipment-modal.service';
import { EquipmentsService } from '@app/features/main/views/equipments/equipments.service';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ImageDocument } from '@app/shared/extra/image-document';
import { getPictureComparator } from '@app/shared/extra/utils';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@services/file.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BaseEquipmentSheet implements OnInit, OnDestroy {

  protected equipment?: Equipment;
  protected images: ImageDocument[] = [];
  protected hasDocuments = false;

  protected Permission = PermissionEnum;
  protected permissionsForEdition = [PermissionEnum.EDIT_EQUIPMENT];

  protected destroy$ = new Subject<void>();
  protected photosLoaded$ = new Subject<void>();

  protected accessManager = inject(AccessManager);
  protected appConfig = inject(AppConfig);
  protected equipmentsService = inject(EquipmentsService);
  protected fileService = inject(FileService);
  protected appManager = inject(AppManager);
  protected analyticsService = inject(AnalyticsService);
  protected equipmentModalService = inject(EquipmentModalService);
  protected documentModalService = inject(DocumentModalService);
  protected documentsService = inject(DocumentsService);
  protected formStateService = inject(FormStateService);
  protected translate = inject(TranslateService);
  protected snackbarManager = inject(SnackbarManager);

  /**
   * Listen for inputs and update Equipment entity when necessary.
   */
  public ngOnInit(): void {
    // Listen for properties updates
    this.formStateService.saved$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(({code, data}) => {
          Equipment.transformPropertiesForInput(data);
          return this.equipmentsService.updateEquipment(this.equipment, data as EquipmentInput)
            .pipe(
              tap(updatedEquipment => {
                this.equipment = updatedEquipment;

                // force computed fields to recalculate
                this.formStateService.getPath(['computed']).next();
                // release the SAVING state to the next state
                this.formStateService.getPath([code, 'state']).next(FieldStateMode.AFTER_SAVE);
              }),
              // Resubscribe to the saved observable if an error occurred.
              catchError(() => {
                this.formStateService.getPath([code, 'state']).next(FieldStateMode.ERROR);
                return this.formStateService.saved$;
              })
            );
        })
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EDIT_SAVED'));
      });
  }

  /**
   * Load the current Equipment entity's pictures and documents.
   * @param fetchOnePicture define if only one picture is fetch
   * @protected
   */
  protected loadEquipmentData(fetchOnePicture: boolean = false): void {
    if (this.equipment) {
      // Load Equipment's documents
      this.documentsService.loadEntityDocuments(
        this.equipment.id,
        EntityTypeEnum.EQUIPMENT,
        DocumentTypeEnum.EQUIPMENT_DOCUMENT
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe(documents => {
          this.equipment.documents = documents;
          this.hasDocuments = this.equipment.documents.length > 0;
        });

      // Load Equipment's pictures
      this.documentsService.loadEntityDocuments(
        this.equipment.id,
        EntityTypeEnum.EQUIPMENT,
        DocumentTypeEnum.EQUIPMENT_PICTURE
      )
        .pipe(
          takeUntil(this.destroy$),
          tap(pictures => {
            this.equipment.pictures = pictures;
            this.fetchPictureFiles(this.equipment.pictures, fetchOnePicture);
          })
        )
        .subscribe();
    }
  }

  /**
   * Stop listening to Observables.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Open a dialog to upload Documents and link updated Documents to the Equipment entity.
   */
  public uploadEquipmentDocuments(): void {
    this.equipmentModalService.openUploadEquipmentDocumentsDialog(this.equipment.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(documents => {
        this.equipment.documents.push(...documents);
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_UPLOADED'));
      });
  }

  /**
   * Download a document.
   * @param documentId ID of the document.
   */
  public downloadEquipmentDocument(documentId: string): void {
    this.fileService.downloadFile(documentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /**
   * Open a dialog to upload pictures and link updated pictures to the Equipment entity.
   */
  public uploadEquipmentPictures(): void {
    this.equipmentModalService.openUploadEquipmentPicturesDialog(this.equipment.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(pictures => {
        if (pictures.length > 0) {
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.PICTURE_UPLOADED'));

          // If Equipment has no pictures, set the first one to be uploaded to be the main one
          if (!this.equipment.pictures || this.equipment.pictures.length === 0) {
            this.equipment.properties['mainPictureDocumentId'] = pictures[0].id;
          }

          // Set Equipment's pictures and fetch image files' URLs
          this.equipment.pictures.push(...pictures);
          this.fetchPictureFiles(pictures);
        }
      });
  }

  /**
   * Open a dialog asking for confirmation to delete a document linked to the Equipment entity.
   * @param document Document to delete.
   */
  public deleteEquipmentDocument(document: Document): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SHEET,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.DOCUMENT,
      document.id
    );
    this.equipmentModalService.openDeleteEquipmentDocumentDialog(document)
      .pipe(
        takeUntil(this.destroy$),
        filter(result => result)
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_DELETED'));
        this.equipment.documents = this.equipment.documents.filter(doc => doc.id != document.id);
      });
  }

  /**
   * Retrieve image URLs corresponding to the picture documents to be displayed in the gallery.
   * @param documents Documents whose files' URLs should be fetched.
   * @param fetchOnePicture define if only one picture is fetch
   * @private
   */
  protected fetchPictureFiles(documents: Document[], fetchOnePicture: boolean = false): void {
    // Filter archived documents out and place main picture at first position
    const activePictures = documents.filter(picture => picture.documentState === DocumentStateEnum.ACTIVE)
      .sort(getPictureComparator(this.equipment.properties['mainPictureDocumentId']));

    if (activePictures.length > 0) {
      const documentIds = fetchOnePicture ? [activePictures.first().id] : activePictures.map(picture => picture.id);
      // Fetch images' URLs, update images list then notify loading is completed
      this.fileService.getDocumentFileUrls(documentIds)
        .subscribe(urls => {
          this.images.push(...activePictures.map(picture => {
            return {
              id: picture.id,
              url: urls[picture.id],
              document: picture
            };
          }));
          this.photosLoaded$.next();
        });
    } else {
      this.photosLoaded$.next();
    }
  }
}
