import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig,
} from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { AbstractFieldBuilder } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

type BudgetLine = {
  label?: string,
  quantity: number,
  unit?: string,
  unitPrice: number,
  total?: number
};

@Component({
  selector: 'budget-field-builder',
  templateUrl: './budget-field-builder.component.html',
  styleUrls: ['./budget-field-builder.component.scss']
})
export class BudgetFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {

  public budgetRows: FormArray<
    FormGroup<{
      id: FormControl<string>,
      label: FormControl<string>,
      quantity: FormControl<number>,
      unit: FormControl<string>,
      unitPrice: FormControl<number>
    }>
  >;
  public filteredUnits: Observable<string[]>;
  public valueChanges = new Subject<string>();

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService,
              private fb: UntypedFormBuilder) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );
  }

  /**
   * Initialize field value, state and create initial budget rows.
   */
  public ngOnInit(): void {
    this.form = this.fb.group({
      budgetRows: this.budgetRows,
      budgetTotal: this.fb.control(0)
    }, {validators: this.computeValidators()});
    this.setFieldValue(this.fieldInitValue || []);
    this.setFieldInitialValue(this.fieldInitValue || []);
    this.getNextState();
    this.resetBudgetRows();

    // Fetch units to show in autocomplete panel
    const units = this.fieldConfig.field.fieldValues as string[];
    this.filteredUnits = this.valueChanges.pipe(
      startWith(''),
      map((value: string) => units.filter(unit => unit.includes(value)))
    );
  }

  /**
   * Setup hooks.
   */
  public ngAfterViewInit(): void {
    this.setupHooks();
  }

  /**
   * Get the current list of budget rows representing the field's value.
   * @return Field value.
   */
  public getFieldValue(): BudgetLine[] {
    return super.getFieldValue() as BudgetLine[];
  }

  /**
   * Set the current list of budget rows.
   * @param value New list of budget rows to use.
   */
  public setFieldValue(value?: BudgetLine[]): void {
    // Filter out total field
    super.setFieldValue(value?.map(({label, quantity, unit, unitPrice}) => {
      return {
        label,
        quantity,
        unit,
        unitPrice
      };
    }));
  }

  /**
   * Set the initial list of budget rows.
   * @param value Initial list of budget rows.
   */
  public setFieldInitialValue(value?: BudgetLine[]): void {
    // Filter out total field
    super.setFieldInitialValue(value?.map(({label, quantity, unit, unitPrice}) => {
      return {
        label,
        quantity,
        unit,
        unitPrice
      };
    }));
  }

  /**
   * Save value and reset budget rows to match the new value.
   */
  public onClickSave(): void {
    super.onClickSave();
    this.resetBudgetRows();
  }

  /**
   * Cancel changes and reset budget rows to initial value.
   */
  public onClickCancel(): void {
    super.onClickCancel();
    this.resetBudgetRows();
  }

  /**
   * Calculate the total value of the budget. Multiply each rows quantity by its unit price then add all results.
   * @return Total value
   */
  public calculateBudgetTotal(): void {
    const budgetTotal = this.getFieldValue()?.reduce((total, entry) => {
      total += entry.quantity * entry.unitPrice;
      return total;
    }, 0);
    this.form.get('budgetTotal').setValue(budgetTotal);
  }

  /**
   * Add a new row to the form. Optional data can be passed to fill the new fields, otherwise empty initial values are
   * used, except for quantity that is set to 1 by default.
   * @param budgetData Optional data to fill the new row's fields.
   */
  public addBudgetRow(budgetData?: BudgetLine): void {
    const group = this.fb.group({
      id: this.fb.control(crypto.randomUUID()),
      label: this.fb.control(budgetData?.label || '', Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)),
      quantity: this.fb.control(budgetData?.quantity || 1, Validators.compose([
        Validators.required,
        Validators.min(0)
      ])),
      unit: this.fb.control(budgetData?.unit || '', Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)),
      unitPrice: this.fb.control(budgetData?.unitPrice, Validators.compose([
        Validators.required,
        Validators.min(0)
      ]))
    });
    group.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateFormData();
      this.calculateBudgetTotal();
    });

    // Merge all valueChanges events from unit fields
    group.get('unit').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.valueChanges);

    this.budgetRows.push(group);
  }

  /**
   * Remove a budget row at the given index.
   * @param index Index of row to remove.
   */
  public removeBudgetRow(index: number): void {
    this.budgetRows.removeAt(index);
    this.updateFormData();
  }

  /**
   * Update the field's value with data entered in the budget rows.
   */
  public updateFormData(): void {
    this.setFieldValue(this.budgetRows.getRawValue());
  }

  // TODO TTT-4135 Remove, iterate over errors instead of validators
  /**
   * List of validators for which an error has been raised by the corresponding validator function.
   */
  public override get erroredValidators(): FieldValidator[] {
    return this.fieldConfig.field.validators.filter(validator => !!this.form.errors?.[validator.code]);
  }

  /**
   * Clear all rows and create new rows based on the field's value. To reset to original value, first reset the field's
   * value to its original value.
   * @private
   */
  private resetBudgetRows(): void {
    this.budgetRows = this.fb.array([]);
    this.getFieldValue()?.forEach(budgetEntry => {
      this.addBudgetRow(budgetEntry);
    });
    this.calculateBudgetTotal();
  }
}
