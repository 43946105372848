import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { UsersService } from '@app/shared/services/users.service';
import { map, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

/**
 * Return a validator function for checking whether a control's value matches an email address
 * currently in use by a user.
 * @param usersService UsersService for making the email checking request.
 * @return Validator function.
 */
export const userExists = (usersService: UsersService): AsyncValidatorFn => {
  return (control: AbstractControl<string>) => {
    return control.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((newValue) => {
        return usersService.userExists(newValue);
      }),
      map(userExists => userExists ? {userExists: true} : null)
    );
  };
};
