@if (currentMode === FieldMode.READ) {
  <div class="read-field-container">
    <div [hidden]="!toggled" [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
         class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span [matTooltipPosition]="'below'"
                [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
             [ngClass]="{
           'read-value':accessManager.hasAllNeededPermissions(permissionsForEdition) &&  preconditionsForEdition,
           'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)
           }">
          <mat-list class="compress">
            @for (listItem of getFieldValue(); track listItem) {
              <mat-list-item [className]="">
                <span><i class="mdi mdi-chevron-right"></i></span>
                {{ fieldConfig.customOptions?.translate ? (('VALUE.' + fieldConfig.fieldCode + '_' + listItem) | uppercase | translate | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE}) : listItem }}
              </mat-list-item>
            }
          </mat-list>
          @if (!getFieldValue()) {
            <span>{{ appConfig.EMPTY_FIELD_VALUE }}</span>
          }
          @if (fieldConfig.suffixType) {
            <span>
              {{ fieldConfig.suffixType | translate }}
            </span>
          }
          @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
            <span class="overlay-icon">
              @if (preconditionsForEdition) {
                <i class="mdi mdi-pencil selectable"></i>
              } @else {
                <i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate"
                   class="mdi mdi-pencil-off tooltip-icon"></i>
              }
            </span>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (currentMode === FieldMode.EDIT) {
  <div>
    <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span [matTooltipPosition]="'below'"
                [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div class="field-container fx-flex-100-66-percent-min-500 fx-layout-col">
        <form autocomplete="off" [formGroup]="form">
          <mat-form-field appearance="outline">
            <mat-label>{{ fieldConfig.label }}</mat-label>
            <mat-select formControlName="field" multiple (keyup.escape)="onEscapeCancel($event)">
              @for (fieldValue of fieldConfig.field.fieldValues; track fieldValue) {
                <mat-option [value]="fieldValue">
                  {{
                    fieldConfig.customOptions?.translate ?
                      (('VALUE.' + fieldConfig.fieldCode + '_' + fieldValue)
                        | uppercase
                        | translate
                        | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE}) : fieldValue
                  }}
                </mat-option>
              }
            </mat-select>
            @if (fieldConfig.suffixType) {
              <span matTextSuffix>
                {{ fieldConfig.suffixType | translate }}
              </span>
            }
            @for (validator of erroredValidators; track validator.type) {
              <mat-error>
                {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
              </mat-error>
            }
          </mat-form-field>
        </form>
        @if (isSingleField) {
          <div class="save-options">
            <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" class="button">
              <i class="mdi mdi-close"></i>
            </button>
            <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
                    class="button save">
              <i class="mdi mdi-check"></i>
            </button>
          </div>
        }
      </div>
    </div>
  </div>
}
