import { SafeStyle } from '@angular/platform-browser';
import { Currency } from '@app/core/enums/currency.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { OrganizationType } from '@app/core/enums/organization-type.enum';
import { Entity } from '@app/core/model/entities/entity';
import { Expose } from 'class-transformer';

export class Organization extends Entity {

  public acronym: string;
  public storageSpace: number;
  public locale: string;
  public currency: Currency;
  public organizationType: string;
  public isDemo: boolean;
  public isTb: boolean;
  public objectType = EntityTypeEnum.ORGANIZATION;

  @Expose()
  public toString(): string {
    return this.name;
  }

  public static getName(organization: Organization): string {
    return organization?.toString();
  }

  @Expose()
  public getTitleText(): string {
    if (this.acronym && this.acronym !== '') {
      return this.acronym;
    }
    return this.name;
  }

  public get logoDocumentId(): string {
    return this.properties['logoDocumentId'];
  }
}

/**
 * Interface containing an Organization with a style url of the logo related to the organization.
 *
 * @member organization entity Object.
 * @member logoUrl style url of the logo related to the Organization.
 */
export interface OrganizationWithLogo {
  organization: Organization;
  logoUrl: SafeStyle;
}

export interface OrganizationInput {
  name: string,
  currency: Currency,
  locale: string,
  organizationType?: OrganizationType,
  isTb: boolean,
  organizationLogoBase64?: string
}
