import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { LegacyRelatedAsset, RelatedAsset } from '@app/core/model/entities/asset/asset';
import { Document } from '@app/core/model/entities/document/document';
import {
  FIELD_ASYNC_PRECONDITIONS_INJECTION,
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import {
  AssetFieldBuilderComponent
} from '@app/shared/components/fields/asset-field-builder/asset-field-builder.component';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import {
  MatSelectAutocompleteComponent
} from '@app/shared/components/mat-select-autocomplete/mat-select-autocomplete.component';
import { getValue } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';

@Component({
  selector: 'document-assets-field-builder',
  templateUrl: './document-assets-field-builder.component.html'
})
export class DocumentAssetsFieldBuilderComponent extends AssetFieldBuilderComponent implements OnInit {

  @ViewChild('inputField') public declare inputField: MatSelectAutocompleteComponent<RelatedAsset>;

  protected readonly DocumentTypeEnum = DocumentTypeEnum;

  constructor(@Inject(FIELD_ENTITY_INJECTION) public document: Document,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_ASYNC_PRECONDITIONS_INJECTION) public asyncPreconditionsForEdition$: Observable<boolean>,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService,
              assetsService: AssetsService) {
    super(
      document,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      asyncPreconditionsForEdition$,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService,
      assetsService
    );
  }

  /**
   * Set the init value of the asset according to the init value of the field.
   */
  protected setAssetInitValue(): void {
    if (this.assetInitValue || !this.fieldInitValue.first()) return;

    const legacyRelatedAssets = plainToInstance(LegacyRelatedAsset, (this.fieldInitValue ?? []) as LegacyRelatedAsset[]);

    if (this.entity['documentType'] !== DocumentTypeEnum.PROJECT_DOCUMENT) {
      this.assetInitValue = legacyRelatedAssets.first().toRelatedAsset();
    } else {
      this.assetInitValue = legacyRelatedAssets.map(legacyRelatedAsset => legacyRelatedAsset.toRelatedAsset());
    }
  }

  public getFieldValue(): RelatedAsset[] {
    const legacyRelatedAssets = (getValue(this.entity, this.fieldConfig.fieldPath) ?? []) as LegacyRelatedAsset[];
    return legacyRelatedAssets.map(legacyRelatedAsset => legacyRelatedAsset.toRelatedAsset());
  }

  /**
   * Navigate to the Dashboard of the related Asset
   * @param assetId
   */
  public async navigateToAssetDashboardByAssetId(assetId: string): Promise<void> {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.FIELD_CLICK,
      NavigateToEnum.SHEET,
      EntityTypeEnum.ASSET,
      assetId
    );
    await this.assetsService.navigateToAssetSheet(assetId, 'dashboard');
  }
}
