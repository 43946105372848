import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './error-page-not-found.component.html'
})
export class ErrorPageNotFoundComponent implements OnInit {

  constructor(private titleService: Title,
              private translate: TranslateService) {
  }

  public ngOnInit(): void {
    this.titleService.setTitle(this.translate.instant('TITLE.PAGE_NOT_FOUND') + ' - myA');
  }

}
