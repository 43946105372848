import { Inject, Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DatagridVisualisationEnum } from '@app/core/enums/datagrid-visualisation.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { DatagridVisualisation } from '@app/core/model/entities/visualisation/datagrid-visualisation';
import {
  CreateDatagridVisualisationModalComponent
} from '@app/shared/components/visualisation-tool-panel/create-datagrid-visualisation-modal/create-datagrid-visualisation-modal.component';
import { GridState, GridStateService } from '@app/shared/services/grid-state.service';
import { TranslateService } from '@ngx-translate/core';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { DocumentNode } from 'graphql';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DatagridVisualisationService {

  public datagridVisualisations: DatagridVisualisation[] = [];

  private datagridVisualisationsLoadedSubject = new Subject<void>();
  private applyDatagridVisualisationSubject = new Subject<DatagridVisualisation>();
  private deleteDatagridVisualisationSubject = new Subject<string | null | undefined>();

  private readonly datagridVisualisationGraphqlFragment: DocumentNode;

  constructor(private apiService: ApiService,
              private appManager: AppManager,
              private gridStateService: GridStateService,
              private translate: TranslateService,
              private snackbarManager: SnackbarManager,
              private popupManager: PopupManager,
              private analyticsService: AnalyticsService,
              @Inject('datagrid') private datagrid: DatagridVisualisationEnum) {
    this.datagridVisualisationGraphqlFragment = gql`
      fragment DatagridVisualisationInfo on DatagridVisualisation {
        id
        name
        config
        isDefault
        assetTypeId
      }
    `;
  }

  public clearData(): void {
    this.datagridVisualisations = [];
  }

  public get datagridVisualisationsLoaded$(): Observable<void> {
    return this.datagridVisualisationsLoadedSubject.asObservable();
  }

  public get applyDatagridVisualisation$(): Observable<DatagridVisualisation> {
    return this.applyDatagridVisualisationSubject.asObservable();
  }

  public get deleteDatagridVisualisation$(): Observable<string | null | undefined> {
    return this.deleteDatagridVisualisationSubject.asObservable();
  }

  /**
   * Open dialog to create a datagrid visualisation, can be created for a specific asset type
   * @param assetTypeId related asset type ID for the new datagrid visualisation
   */
  public openCreateVisualisationDialog(assetTypeId?: string): void {
    const dialogRef = this.popupManager.showGenericPopup(CreateDatagridVisualisationModalComponent, PopupSize.SMALL, {
      datagrid: this.datagrid
    });
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      const analyticsEvent = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.DATAGRID_VISUALISATION);
      if (dialogResponse === 'yes') {
        analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE});
        const dataObject = dialogRef.componentInstance.getGeneratedObject();
        this.saveDatagridVisualisation(dataObject.visualisationName, assetTypeId);
      } else {
        analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
      }
      this.analyticsService.trackEvent(analyticsEvent);
    });
  }

  /**
   * Open a dialog to delete a datagrid visualisation
   * @param datagridVisualisation the datagrid visualisation to delete
   */
  public openDeleteVisualisationDialog(datagridVisualisation: DatagridVisualisation): void {
    const dialogRef = this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant('TITLE.DELETE_VISUALISATION'),
      dialogMessage: this.translate.instant('MESSAGE.DELETE_VISUALISATION'),
      okText: this.translate.instant('BUTTON.DELETE'),
      type: 'warning'
    });
    dialogRef.afterClosed().subscribe(dialogResponse => {
      const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.DATAGRID_VISUALISATION);
      if (dialogResponse === 'yes') {
        analyticsEvent.addProperties({
          [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE,
          [AnalyticsKeyEnum.ENTITY_ID]: datagridVisualisation.id
        });
        this.deleteDatagridVisualisation(datagridVisualisation);
      } else {
        analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
      }
      this.analyticsService.trackEvent(analyticsEvent);
    });
  }

  /**
   * Call the API to create a datagrid visualisation, can be created for a specific asset type
   * @param name for the new datagrid visualisation
   * @param assetTypeId related asset type ID for the new datagrid visualisation
   */
  public saveDatagridVisualisation(name: string, assetTypeId?: string): void {
    const config = this.gridStateService.gridState;
    delete config.modified;

    const mutation = gql`
      mutation CreateCustomDatagridVisualisation($datagridVisualisationInput: DatagridVisualisationInput!, $organizationId: String!){
        createCustomDatagridVisualisation(datagridVisualisation: $datagridVisualisationInput, organizationId: $organizationId) {
          ...DatagridVisualisationInfo
        }
      }
      ${this.datagridVisualisationGraphqlFragment}
    `;
    const variables = {
      datagridVisualisationInput: {
        name: name,
        datagrid: this.datagrid,
        config: config,
        assetTypeId: assetTypeId
      },
      organizationId: this.appManager.currentOrganization.id
    };
    this.apiService.mutate({mutation, variables})
      .subscribe(data => {
        const newDatagridVisualisation = plainToInstance(
          DatagridVisualisation,
          data['createCustomDatagridVisualisation']
        );
        this.datagridVisualisations.push(newDatagridVisualisation);
        this.applyDatagridVisualisationSubject.next(newDatagridVisualisation);
        this.sortDatagridVisualisationList();
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.VISUALISATION_ADDED'));
      });
  }

  /**
   * Call the API to delete a datagrid visualisation
   * @param datagridVisualisationToDelete the datagrid visualisation to delete
   */
  public deleteDatagridVisualisation(datagridVisualisationToDelete: DatagridVisualisation): void {
    const mutation = gql`
      mutation DeleteCustomDatagridVisualisation($id: Int!) {
        deleteCustomDatagridVisualisation(id: $id)
      }
    `;
    const variables = {
      id: datagridVisualisationToDelete.id
    };
    this.apiService.mutate({mutation, variables})
      .subscribe(() => {
        this.datagridVisualisations = this.datagridVisualisations.filter(datagridVisualisation => datagridVisualisation.id !== datagridVisualisationToDelete.id);
        this.deleteDatagridVisualisationSubject.next(datagridVisualisationToDelete.assetTypeId);
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.VISUALISATION_DELETED'));
      });
  }

  /**
   * Calls the API to load all datagrid visualisations in an organization
   */
  public loadData(): Observable<DatagridVisualisation[]> {
    this.datagridVisualisations = [];
    const query = gql`
      query DatagridVisualisations($datagrid: DatagridVisualisationEnum!, $organizationId: String!) {
        datagridVisualisations(datagrid: $datagrid, organizationId: $organizationId) {
          ...DatagridVisualisationInfo
        }
      }
      ${this.datagridVisualisationGraphqlFragment}
    `;
    const variables = {
      datagrid: this.datagrid,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.apiService.query({query, variables})
      .pipe(
        map(data => {
          this.datagridVisualisations = plainToInstance(
            DatagridVisualisation,
            data['datagridVisualisations'] as DatagridVisualisation[]
          );
          // FIXME TTT-2950 Only default visualisations' names should be translated
          this.datagridVisualisations.forEach(datagridVisualisation => {
            datagridVisualisation.name = this.translate.instant(datagridVisualisation.name);
          });
          this.sortDatagridVisualisationList();
          this.datagridVisualisationsLoadedSubject.next();
          return this.datagridVisualisations;
        })
      );
  }

  /**
   * Call the API to update a datagrid visualisation, can be updated for a specific asset type
   * @param datagridVisualisationToUpdate the datagrid visualisation to update
   * @param config the new config of the datagrid visualisation
   * @param assetTypeId related asset type ID for the datagrid visualisation
   * @return Observable that emits a boolean indicating whether the datagrid visualisation was successfully updated.
   */
  public updateDatagridVisualisation(datagridVisualisationToUpdate: DatagridVisualisation,
                                     config: GridState, assetTypeId?: string): Observable<boolean> {
    delete config.modified;
    const mutation = gql`
      mutation UpdateCustomDatagridVisualisation($id: Int!, $datagridVisualisation: DatagridVisualisationInput!) {
        updateCustomDatagridVisualisation(id: $id, datagridVisualisation: $datagridVisualisation) {
          ...DatagridVisualisationInfo
        }
      }
      ${this.datagridVisualisationGraphqlFragment}
    `;
    const variables = {
      id: datagridVisualisationToUpdate.id,
      datagridVisualisation: {
        config: config,
        assetTypeId: assetTypeId
      }
    };
    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => {
          this.analyticsService.trackEvent(
            new AnalyticsEvent(ActionEnum.UPDATE, EntityTypeEnum.DATAGRID_VISUALISATION)
              .addProperties({[AnalyticsKeyEnum.ENTITY_ID]: datagridVisualisationToUpdate.id})
          );
          const updatedDatagridVisualisation = plainToInstance(
            DatagridVisualisation,
            data['updateCustomDatagridVisualisation']
          );
          const index = this.datagridVisualisations.findIndex(viz => viz.id === updatedDatagridVisualisation.id);
          this.datagridVisualisations.splice(index, 1, updatedDatagridVisualisation);

          this.applyDatagridVisualisationSubject.next(updatedDatagridVisualisation);
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.VISUALISATION_UPDATED'));
          return true;
        })
      );
  }

  /**
   * Sorts datagridVisualisations based on isDefault status and name.
   */
  private sortDatagridVisualisationList(): void {
    this.datagridVisualisations.sort((visualisationA, visualisationB) => {
      if (visualisationA.isDefault && !visualisationB.isDefault) {
        return -1;
      }
      if (!visualisationA.isDefault && visualisationB.isDefault) {
        return 1;
      }
      return visualisationA.name.localeCompare(visualisationB.name);
    });
  }
}
