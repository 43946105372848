@if (currentMode === FieldMode.READ) {
  <div class="read-field-container">
    <div [hidden]="!toggled"
      [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !asyncPreconditionsForEdition}"
      class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
          [ngClass]="{'read-value':accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
          @if (assetValues?.length > 0) {
            <mat-chip-listbox tabIndex="-1">
              @for (chip of (assetValues| slice:0:5); track chip.id) {
                <mat-chip-option class="clickable-field" (click)="onClickActivate($event)"
                  (keyup.enter)="onEnterActivate($event)"
                  >
                  <a (click)="navigateToAssetDashboard(chip.id)">
                    {{ (chip.toString().length > 30 ? chip.toString().slice(0, 27) + '...' : chip.toString()) }}
                  </a>
                </mat-chip-option>
              }
              @if (assetValues?.length > 5) {
                <div class="chips-container fx-layout-row fx-align-center">
                  <span>
                    {{ 'TOOLTIP.AND_MORE' | translate: {quantity: (assetValues.length - 5)} }}
                  </span>
                </div>
              }
            </mat-chip-listbox>
          } @else {
            <span>{{ appConfig.EMPTY_FIELD_VALUE }}</span>
          }
          @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
            <span
              class="overlay-icon">
              @if (preconditionsForEdition && asyncPreconditionsForEdition) {
                <i
                class="mdi mdi-pencil selectable"></i>
              } @else {
                <i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i>
              }
            </span>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (currentMode === FieldMode.EDIT) {
  <div>
    <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div #fieldContainer class="field-container fx-flex-100-66-percent-min-500 fx-layout-col">
        <form autocomplete="off" class="chips-field-builder-form" [formGroup]="form">
          <mat-form-field appearance="outline">
            <mat-label>{{ fieldConfig.label }}</mat-label>
            <mat-chip-grid #chipList>
              @for (relatedAsset of (selectedEntities| slice:0:5); track relatedAsset.id) {
                <mat-chip-row (removed)="selectToggle(relatedAsset)"
                  [removable]="true">
                  {{ (relatedAsset.toString().length > 30 ? relatedAsset.toString().slice(0, 27) + '...' : relatedAsset.toString()) }}
                  @if (asyncPreconditionsForEdition) {
                    <button matChipRemove>
                      <i class="mdi mdi-close-circle"></i>
                    </button>
                  }
                </mat-chip-row>
              }
              @if (selectedEntities.length > 5) {
                <div class="chips-container fx-layout-row fx-align-center">
                  <span>
                    {{ 'TOOLTIP.AND_MORE' | translate: {quantity: (selectedEntities.length - 5)} }}
                  </span>
                </div>
              }
              @if (asyncPreconditionsForEdition) {
                <input #inputField #trigger="matAutocompleteTrigger"
                  formControlName="entityName"
                  (keyup.escape)="onEscapeCancel($event)"
                  [matAutocomplete]="suggestions"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  class="chip-input"
                  type="text"
                  >
              }
            </mat-chip-grid>
            <mat-autocomplete #suggestions="matAutocomplete"
              [panelWidth]="fieldContainer.offsetWidth-90"
              (optionSelected)="keepPanelOpen()">
              @if (filteredEntities | async; as allFilteredAssets) {
                @if (allFilteredAssets.length > 0) {
                  <mat-option class="suggestion">
                    <div class="checkbox-container fx-layout-row fx-align-center"
                      (click)="optionClicked(allFilteredAssets, $event)">
                      <mat-checkbox [checked]="allSelected" class="checkbox">
                        {{ 'GRID.SELECT_ALL' | translate }}
                      </mat-checkbox>
                    </div>
                  </mat-option>
                }
                @for (filteredAsset of allFilteredAssets; track filteredAsset.asset.id) {
                  <mat-option class="suggestion">
                    <div class="checkbox-container fx-layout-row fx-align-center"
                      (click)="optionClicked( [filteredAsset], $event)">
                      <mat-checkbox [checked]="filteredAsset.checked" class="checkbox">
                        {{ filteredAsset.asset.toString() }}
                      </mat-checkbox>
                    </div>
                  </mat-option>
                }
              }
            </mat-autocomplete>
            @for (validator of erroredValidators; track validator.type) {
              <mat-error>
                {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
              </mat-error>
            }
            @if (form.get('selectedAssetIds').hasError('required')) {
              <mat-error>
                {{ 'ERROR.FIELD_REQUIRED' | translate }}
              </mat-error>
            }
            @if (!asyncPreconditionsForEdition) {
              <mat-hint style="width:80%">
                {{ 'VALIDATION.PROJECT_IS_NOT_EDITABLE' | translate }}
              </mat-hint>
            }
          </mat-form-field>
        </form>
        @if (isSingleField) {
          <div class="save-options">
            <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" class="button">
              <i class="mdi mdi-close"></i>
            </button>
            <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)"
              [disabled]="!form.valid || !asyncPreconditionsForEdition"
              class="button save">
              <i class="mdi mdi-check"></i>
            </button>
          </div>
        }
      </div>
    </div>
  </div>
}
