import { Component } from '@angular/core';
import { DocumentStateEnum } from '@app/core/enums/document/document-state.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { CreateDocumentInput, Document } from '@app/core/model/entities/document/document';
import {
  OrganizationDocumentCreateModalComponent
} from '@app/features/main/views/organization-documents/modals/organization-document-create-modal/organization-document-create-modal.component';
import dayjs from 'dayjs';

@Component({
  templateUrl: './work-documents-create-modal.component.html',
})
export class WorkDocumentsCreateModalComponent extends OrganizationDocumentCreateModalComponent {

  constructor() {
    super();

    // Add columns that are not relevant for a Work's Document
    this.propertiesGridColumnState = [
      {colId: 'defaultSortCol', sort: 'asc'},
      {colId: 'assets', hide: true},
      {colId: 'assetDocumentSection', hide: true},
      {colId: 'documentState', hide: true}
    ];
  }

  /**
   * Create a Document input for each row of the properties grid.
   * @return Document inputs.
   */
  public getCreateDocumentsInput(): CreateDocumentInput[] {
    const documentsToUpdate: CreateDocumentInput[] = [];
    if (this.gridApi) {
      this.gridApi.forEachNode(node => {
        documentsToUpdate.push(
          <CreateDocumentInput>{
            fileName: node.data.fileName,
            documentType: DocumentTypeEnum.WORK_DOCUMENT,
            documentState: DocumentStateEnum.ACTIVE,
            documentName: (node.data.document.documentName)
              ? node.data.document.documentName + (node.data.document as Document).getExtension()
              : node.data.document.name,
            properties: {
              // Use spread to add optional field if condition is fulfilled
              ...(node.data.document.documentCategory && {documentCategory: node.data.document.documentCategory}),
              ...(node.data.document.date && {documentDate: dayjs(node.data.document.date).format(this.appConfig.DATE_FORMAT)}),
              ...(node.data.document.description && {description: node.data.document.description})
            }
          }
        );
      });
    }
    return documentsToUpdate;
  }
}
