import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Investment } from '@app/core/model/entities/investment/investment';
import { AccessManager } from '@app/core/services/managers/access.manager';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { InvestmentsService } from '@app/features/main/views/investments/investment-sheet/features/investments.service';
import {
  InvestmentModalService
} from '@app/features/main/views/investments/investments-inventory/modals/investment-modal.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'investment-header',
  templateUrl: './investment-header.component.html',
  styleUrls: ['./investment-header.component.scss'],
  providers: [InvestmentModalService, DocumentModalService]
})
export class InvestmentHeaderComponent implements OnInit {

  protected investment: Investment;
  protected readonly Permission = PermissionEnum;

  private destroyRef = inject(DestroyRef);
  private appManager = inject(AppManager);
  private titleService = inject(Title);
  private translate: TranslateService = inject(TranslateService);
  private activationEndService = inject(ActivationEndService);
  private investmentService = inject(InvestmentsService);
  private investmentModalService = inject(InvestmentModalService);
  private router = inject(Router);
  private analyticsService = inject(AnalyticsService);
  private snackbarManager = inject(SnackbarManager);
  protected accessManager = inject(AccessManager);

  /**
   * Get current Investment entity, initialize form fields and set page title.
   */
  public ngOnInit(): void {
    this.investment = this.appManager.currentEntity as Investment;

    // Update Investment as it is updated outside the header
    this.investmentService.investmentUpdated$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(updatedInvestment => this.investment = updatedInvestment);

    this.activationEndService.pushOptionalParams({
      'TITLE.INVESTMENT_IDENTIFIER': {
        identifier: this.investment.identifier.toString()
      }
    });
    this.titleService.setTitle(`${this.translate.instant(
      'TITLE.INVESTMENT_IDENTIFIER',
      {identifier: this.investment.identifier}
    )} - myA`);
  }

  /**
   * Opens a confirmation dialog to delete the current Investment.
   * If the User confirms, the Investment is deleted and the user is redirected
   * to the Investments page.
   */
  protected deleteInvestment(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SHEET,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.INVESTMENT,
      this.investment.id
    );

    this.investmentModalService.openDeleteInvestmentsDialog([this.investment]).pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(result => !!result),
      tap(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.INVESTMENT_DELETED'))),
      switchMap(() => this.router.navigate(
        ['organization', this.appManager.currentOrganization.id, 'investments'],
        {replaceUrl: true}
      ))
    )
      .subscribe();
  }
}
