import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Document } from '@app/core/model/entities/document/document';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@services/file.service';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput, UploadStatus } from 'ngx-uploader';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ErrorMessage {
  // ID of the file that has an error
  id: string;

  // Text of the message.
  error: string;
}

export interface UploadData {
  concurrency?: number;
  allowedContentTypes?: string[];
  maxUploads?: number;
  maxFileSize?: number;
  error?: string,
  organizationId: string,
  entityId?: string,
  entityType?: EntityTypeEnum,
  documentType?: DocumentTypeEnum,
  import?: boolean,
}

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit, OnDestroy {

  public uploadStatus = UploadStatus; // ref to enum for use in html file
  public files: UploadFile[]; // contains all files to upload, uploaded and in error

  public uploadInput: EventEmitter<UploadInput>;
  public options: UploaderOptions;
  public errors: ErrorMessage[] = [];
  public preventUploadFiles: boolean;
  public destroy$ = new Subject<void>();
  public isMultiple: boolean = true;

  private filesToAdd: UploadFile[]; // Files to add in queue before an input event is created for uploading this file

  @Input() public uploadData: UploadData;

  @Input() public removeAllFilesObservable: Observable<void>;

  @Output() public uploadInProgress = new EventEmitter<boolean>();
  @Output() public fileUploaded = new EventEmitter<CreateDocumentData>();

  @ViewChild('fileInput') public fileInput: ElementRef;

  constructor(public translate: TranslateService,
              public appConfig: AppConfig,
              public fileService: FileService) {
  }

  public ngOnInit(): void {
    this.files = [];
    this.filesToAdd = [];
    this.preventUploadFiles = false;
    this.options = {
      concurrency: this.uploadData?.concurrency ?? 1,
      allowedContentTypes: this.uploadData.allowedContentTypes,
      maxFileSize: this.uploadData.maxFileSize || this.appConfig.MAX_FILE_SIZE,
      maxUploads: this.uploadData?.maxUploads
    };
    this.isMultiple = this.uploadData.maxUploads ? this.uploadData.maxUploads > 1 : true;
    this.uploadInput = new EventEmitter<UploadInput>();
    this.uploadInProgress
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    // When emitted, cancel and remove all files in the file input.
    if (this.removeAllFilesObservable) {
      this.removeAllFilesObservable
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.cancelAllUpload());
    }
  }

  public onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        if (this.filesToAdd.length > 0) {
          // upload file on current file system
          this.uploadInProgress.emit(true);
          const {entityType, entityId, organizationId, documentType, import: importTemplate} = this.uploadData;
          this.fileService.uploadFiles(
            this.filesToAdd,
            entityId,
            entityType,
            organizationId,
            documentType,
            importTemplate ?? false
          )
            .subscribe(uploadInput => this.uploadInput.emit(uploadInput));
          this.filesToAdd = [];
        }
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.isMultiple ? this.files.push(output.file) : this.files[0] = output.file;
          if (Document.validFileName(output.file.name)) {
            this.isMultiple ? this.filesToAdd.push(output.file) : this.filesToAdd[0] = output.file;
          } else {
            this.errors.push({id: output.file.id, error: this.translate.instant('ERROR.INVALID_FILE_NAME')});
          }
        }
        break;
      case 'start':
        if (typeof output.file !== 'undefined') {
          const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
          // set status in start event because uploading event is not fired
          output.file.progress.status = UploadStatus.Uploading;
          this.files[index] = output.file;
          this.uploadInProgress.emit(true);
        }
        break;
      case 'cancelled':
      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        this.uploadInProgress.emit(false);
        break;
      case 'removedAll':
        // cancel other file (an upload is start before cancel all)
        this.files.forEach(file => this.cancelUpload(file.id));
        this.uploadInProgress.emit(false);
        break;
      case 'rejected':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
          if (this.options.maxUploads && output.file.fileIndex >= this.options.maxUploads) {
            this.errors.push({
              id: output.file.id,
              error: this.translate.instant(
                'ERROR.UPLOAD_MAX_NUMBER',
                {'max': this.options.maxUploads}
              )
            });
          } else if (output.file.size > this.options.maxFileSize) {
            this.errors.push({
              id: output.file.id,
              error: this.translate.instant(
                'ERROR.UPLOAD_FILE_SIZE',
                {'max': this.options.maxFileSize / this.appConfig.MEGA_TO_BYTE}
              )
            });
          } else if (Array.isArray(this.options.allowedContentTypes)
            && !this.options.allowedContentTypes.includes(output.file.type)) {
            this.errors.push({id: output.file.id, error: this.translate.instant('ERROR.UPLOAD_FILE_TYPE')});
          } else {
            this.errors.push({id: output.file.id, error: this.translate.instant('ERROR.UPLOAD_FILE')});
            console.error(output);
          }
          this.uploadInProgress.emit(false);
        }
        break;
      case 'done':
        if (output.file.responseStatus !== 200) {
          this.errors.push({id: output.file.id, error: this.translate.instant('ERROR.UPLOAD_FILE')});
        }
        if (output.file.response.error) { // upload widget error
          this.errors.push({id: output.file.id, error: this.translate.instant(this.uploadData.error)});
          this.uploadInProgress.emit(false);
        } else if (output.file.response.errors) { // graphql error
          this.errors.push({
            id: output.file.id,
            error: this.translate.instant(
              'ERROR.UPLOAD_FILE_CANCELLED') + this.translate.instant(output.file.response.errors[0].message
            )
          });
          this.uploadInProgress.emit(false);
        } else if (output.file.name) {
          const createDocumentData = {
            fileName: this.fileService.getFileNameFromUploadFileResponse(output.file),
            documentName: output.file.nativeFile.name,
            mimeType: output.file.type
          };
          this.fileUploaded.emit(createDocumentData);
          this.uploadInProgress.emit(false);
        }
        break;
    }
  }

  public cancelUpload(id: string): void {
    this.uploadInput.emit({type: 'cancel', id: id});
    this.uploadInput.emit({type: 'remove', id: id});
    this.files = this.files.filter(file => file.id !== id);
  }

  /**
   * Cancel and remove all files in the file input.
   */
  public cancelAllUpload(): void {
    this.fileInput.nativeElement.value = '';
    this.uploadInput.emit({type: 'removeAll'});
    this.files = [];
    this.filesToAdd = [];
  }

  public getError(id: string): ErrorMessage {
    return this.errors.find((error) => {
      return (error.id === id);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export class CreateDocumentData {
  public fileName: string;
  public documentName: string;
  public mimeType: string;
}
