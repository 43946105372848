import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorModalDialogComponent } from '@app/shared/popup/error/error-modal-dialog.component';
import { OkCancelModalDialogComponent } from '@app/shared/popup/ok-cancel/ok-cancel.modal-dialog';
import { OkModalDialogComponent } from '@app/shared/popup/ok/ok.modal-dialog';
import { YesNoCancelModalDialogComponent } from '@app/shared/popup/yes-no-cancel/yes-no-cancel.modal-dialog';

export class PopupSize {
  public static SMALL = new PopupSize( {width: '20vw', height: '50vh'});
  public static MEDIUM_SMALL = new PopupSize( {width: '30vw', height: '40vh'});
  public static MEDIUM = new PopupSize( {width: '50vw', height: '90vh'});
  public static LARGE = new PopupSize( {width: '70vw', height: '90vh'});
  public static XLARGE = new PopupSize( {width: '90vw', height: '90vh'});

  private constructor(private size: { width: string, height: string }) {
  }

  /**
   * Popup dialog's CSS-formatted width.
   */
  public get width(): string {
    return this.size.width;
  }

  /**
   * Popup dialog's CSS-formatted width.
   */
  public get height(): string {
    return this.size.height;
  }
}

@Injectable()
export class PopupManager {
  private defaultSize = PopupSize.MEDIUM_SMALL;
  private defaultMinWidth = '300px';
  private defaultMinHeight = '350px';

  constructor(private dialog: MatDialog) {
  }

  public showOkPopup(injectedData: any): MatDialogRef<OkModalDialogComponent, any> {
    return this.dialog.open(OkModalDialogComponent, {
      disableClose: true,
      width: this.defaultSize.width,
      height: this.defaultSize.height,
      minWidth: this.defaultMinWidth,
      minHeight: this.defaultMinHeight,
      data: injectedData
    });
  }

  public showOkCancelPopup(injectedData: any): MatDialogRef<OkCancelModalDialogComponent, any> {
    return this.dialog.open(OkCancelModalDialogComponent, {
      disableClose: true,
      width: this.defaultSize.width,
      height: this.defaultSize.height,
      minWidth: this.defaultMinWidth,
      minHeight: this.defaultMinHeight,
      data: injectedData,
      panelClass: 'generic-popup'
    });
  }

  public showYesNoCancelPopup(injectedData: any): MatDialogRef<YesNoCancelModalDialogComponent, any> {
    return this.dialog.open(YesNoCancelModalDialogComponent, {
      disableClose: true,
      width: this.defaultSize.width,
      height: this.defaultSize.height,
      minWidth: this.defaultMinWidth,
      minHeight: this.defaultMinHeight,
      data: injectedData,
      panelClass: 'generic-popup'
    });
  }

  public showErrorPopup(data: any): MatDialogRef<ErrorModalDialogComponent> {
    return this.dialog.open(ErrorModalDialogComponent, {
      width: this.defaultSize.width,
      height: this.defaultSize.height,
      minWidth: this.defaultMinWidth,
      minHeight: this.defaultMinHeight,
      data
    });
  }

  public showGenericPopup<T>(
    renderComponent: ComponentType<T>,
    size: PopupSize,
    injectedData: any,
    backdropClass?: string,
    autofocus: boolean = true
  ): MatDialogRef<T> {
    return this.dialog.open(renderComponent, {
      disableClose: true,
      width: size.width,
      maxWidth: '95vw',
      maxHeight: '95vw',
      data: injectedData,
      panelClass: 'generic-popup',
      backdropClass: backdropClass,
      autoFocus: autofocus
    });
  }
}
