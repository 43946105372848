import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorManager {
  private translate = inject(TranslateService);

  /**
   * Parse and translate list of server message from query update and save entity
   * @param errorMessages : string array of server error
   * @return string array of translated message
   */
  public transformErrorMessageQuerySaveUpdate(errorMessages: string[]): string[] {
    const translatedMessagesList: string[] = [];
    for (const message of errorMessages) {
      translatedMessagesList.push(this.formatErrorValidationMsg(message));
    }
    return translatedMessagesList;
  }

  /**
   * Parse and translate server error message from query save and update
   * each error message is a string with a specific format
   * message format: ERROR_CODE,propertyCode,value,otherPropertyCode, the separate char is ','
   * only the ERROR_CODE is required on all error message, the other property is additional information for validation error
   * the property ERROR_CODE,propertyCode,otherPropertyCode is translate
   * the property propertyCode,value,otherPropertyCode are params in the ERROR_CODE message
   * @param errorMessage server error message
   * @return translated message
   */
  private formatErrorValidationMsg(errorMessage: string): string {
    if (errorMessage == null) {
      return this.translate.instant('TITLE.UNKNOWN_ERROR');
    }
    const messageVar = errorMessage.split(',');
    const errorCode = messageVar[0];
    let propertyLabel = '';
    if (messageVar.length >= 2) {
      propertyLabel = this.translate.instant(messageVar[1].toUpperCase());
      // If it couldn't translate the property, then use the original value
      if (propertyLabel === messageVar[1].toUpperCase()) {
        propertyLabel = messageVar[1];
      }
    }
    let value = '';
    if (messageVar.length >= 3) {
      value = messageVar[2];
    }
    let otherPropertyLabel = '';
    if (messageVar.length === 4) {
      otherPropertyLabel = this.translate.instant(messageVar[3].toUpperCase());
      // If it couldn't translate the property, then use the original value
      if (otherPropertyLabel === messageVar[3].toUpperCase()) {
        otherPropertyLabel = messageVar[3];
      }
    }
    return this.translate.instant(errorCode, {
      'propertyLabel': propertyLabel,
      'value': value,
      'otherPropertyLabel': otherPropertyLabel
    });
  }
}
