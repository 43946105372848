import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppConfig } from '@app/core/app.config';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { RelatedAsset } from '@app/core/model/entities/asset/asset';
import { Space } from '@app/core/model/entities/asset/space';
import { Equipment } from '@app/core/model/entities/equipments/equipment';
import { WorkInput } from '@app/core/model/entities/works/work';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import { WorksService } from '@app/features/main/views/works/works.service';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { GridOptionsService } from '@app/shared/grid/grid-options.service';
import { GeneratesObject } from '@app/shared/interfaces/generates-object';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import { TranslateService } from '@ngx-translate/core';
import { GridApi, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './work-create-modal.component.html'
})
export class WorkCreateModalComponent implements OnInit, OnDestroy, GeneratesObject {

  protected readonly EntityTypeEnum = EntityTypeEnum;

  public workForm: FormGroup<{
    action: FormControl<string>,
    state: FormControl<string>,
    asset: FormControl<RelatedAsset>,
    equipments: FormControl<Equipment[]>,
    spaces: FormControl<Space[]>,
    properties: FormControl<Record<string, any>>
  }>;

  // Ag-grid
  public gridApi: GridApi;
  public gridOptions: GridOptions;

  // Data
  public workStates: Observable<string[]>;

  public spaces = new BehaviorSubject<Space[]>([]);

  protected destroy$ = new Subject<void>();

  private invalidRows: string[] = [];

  // Injection
  public appConfig = inject(AppConfig);
  protected fb = inject(UntypedFormBuilder);
  protected assetsService = inject(AssetsService);
  protected spacesService= inject(SpacesService);
  protected worksService = inject(WorksService);
  protected columnBuilder = inject(ColumnBuilder);
  protected translate: TranslateService = inject(TranslateService);
  protected gridOptionsService = inject(GridOptionsService);

  constructor() {
    this.gridOptions = {
      ...this.gridOptionsService.staticSelectSpacesGridOptions,
      localeText: this.columnBuilder.localeTextCommon(),
      rowClassRules: {
        'invalid-row': ({data}: { data: Space }): boolean => this.invalidRows.includes(data.id)
      },
      onRowClicked: this.onRowSelected.bind(this),
      onRowSelected: this.onRowSelected.bind(this)
    };

    this.workForm = this.fb.group({
      action: this.fb.control(this.translate.instant('VALUE.NEW_WORK'), Validators.compose([
        Validators.required,
        Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)
      ])),
      state: this.fb.control(void 0, Validators.compose([
        Validators.required,
        Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)
      ])),
      asset: this.fb.control(undefined),
      equipments: this.fb.control([]),
      spaces: this.fb.control([], ExtraValidators.checkSpaces),
      properties: this.fb.control({})
    });
  }

  /**
   * Fetch possible values for state and accessible Assets and Spaces.
   */
  public ngOnInit(): void {
    // Fetch available Work states
    this.workStates = this.worksService.loadWorkStates()
      .pipe(takeUntil(this.destroy$));

    // Listen for Spaces to be displayed in the grid
    this.workForm.get('asset').valueChanges
      .pipe(
        takeUntil(this.destroy$),
        switchMap(asset => {
          return asset ? this.spacesService.loadSpaces(asset.id) : of([]);
        })
      )
      .subscribe(this.spaces);
  }

  /**
   * Stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.spaces.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Load the grid's column definitions and fill with the selected Asset's Spaces.
   * @param params Ag-grid params.
   */
  public onGridReady(params): void {
    this.gridApi = params.api;
    this.gridOptions.columnApi.applyColumnState({
      state: [
        {colId: 'ag-Grid-AutoColumn', sort: 'asc'}
      ]
    });

    // Resize columns when receiving new Spaces
    this.spaces.subscribe(spaces => {
      this.gridApi.setRowData(spaces);
      this.gridApi.sizeColumnsToFit();
      this.workForm.get('spaces').reset([]);
    });
  }

  /**
   * Data from the filled form.
   * @return Work data.
   */
  public getGeneratedObject(): WorkInput {
    const {action, asset, spaces, state, equipments, properties} = this.workForm.getRawValue();
    const spaceIds = spaces.map(space => space.id);
    const equipmentIds = equipments.map(equipment => equipment.id);
    return {action, assetId: asset?.id, equipmentIds, spaceIds, state, properties};
  }

  /**
   * Update value in the form and refresh invalid rows.
   * @param event Event thrown by the grid.
   * @private
   */
  private onRowSelected(event: RowSelectedEvent): void {
    const spaces = this.gridApi.getSelectedRows() as Space[];
    this.workForm.get('spaces').setValue(spaces);
    this.workForm.get('spaces').markAsTouched();

    const nodesToRefresh = [event.node];

    // Check if row is invalid and update invalid rows
    if (event.node.isSelected() && (this.hasParentSelected(event.data) || this.hasChildrenSelected(event.data))) {
      this.invalidRows.push(event.data.id);
    } else if (!event.node.isSelected()) {
      this.invalidRows = this.invalidRows.filter(row => {
        const rowNode = this.gridApi.getRowNode(row);
        nodesToRefresh.push(rowNode);
        return row !== event.data.id
          && (this.hasParentSelected(rowNode.data) || this.hasChildrenSelected(rowNode.data));
      });
    }

    // Refresh rows
    this.gridApi.redrawRows({
      rowNodes: nodesToRefresh
    });
  }

  /**
   * Checks if a Space has ancestors that are selected in the grid.
   * @param space Space to check.
   * @return True if at least one ancestor is selected, false otherwise.
   * @private
   */
  private hasParentSelected(space: Space): boolean {
    return this.gridApi.getSelectedRows()
      .some(selectedRow => space.parentPath.includes(selectedRow.id));
  }

  /**
   * Checks if a Space has children that are selected in the grid.
   * @param space Space to check.
   * @return True is at least one child is selected, false otherwise.
   * @private
   */
  private hasChildrenSelected(space: Space): boolean {
    return this.gridApi.getSelectedRows()
      .some(selectedRow => selectedRow.parentPath.includes(space.id));
  }
}
