import { Component, HostListener, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Investment } from '@app/core/model/entities/investment/investment';
import { BaseInvestmentSheet } from '@app/features/main/views/investments/investment-sheet/base-investment-sheet';
import {
  InvestmentModalService
} from '@app/features/main/views/investments/investments-inventory/modals/investment-modal.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { FieldService } from '@services/field.service';
import { filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'investments-sidebar',
  templateUrl: './investments-sidebar.component.html',
  providers: [InvestmentModalService, DocumentModalService]
})
export class InvestmentsSidebarComponent extends BaseInvestmentSheet implements OnInit {

  @Input() protected formId: string;

  protected eventsOrigin = EventOriginEnum.SIDEPANEL;

  protected fieldService = inject(FieldService);
  protected singleEditService = inject(SingleEditService);

  /**
   * Listen for sidebar toggle actions and updates from the FormStateService. Update sidebar data and call Investment service
   * when necessary.
   */
  public ngOnInit(): void {
    super.ngOnInit();

    // Update the side panel's content whenever a new Investment is selected
    this.investmentsService.sidePanelToggle$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          this.singleEditService.singleEditSubject.next(null);

          // Unload current Investment
          if (this.appManager.currentEntity instanceof Investment) {
            this.appManager.unloadCurrentEntity();
          }
        }),
        filter(investment => !!investment),
        switchMap(investment => this.loadInvestmentDocuments(investment))
      )
      .subscribe(investment => {
        this.investment = investment;
        this.appManager.currentEntity = investment;
      });
  }

  /**
   * Opens a confirmation dialog to delete the current Investment.
   * If the User confirms, the Investment is deleted and the sidebar is closed.
   */
  public deleteInvestment(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.INVESTMENT,
      this.investment.id
    );

    this.investmentModalService.openDeleteInvestmentsDialog([this.investment])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(result => !!result)
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.INVESTMENT_DELETED'));
        this.closeInvestmentSidebar();
      });
  }

  /**
   * Close the sidebar.
   */
  public closeInvestmentSidebar(): void {
    this.investmentsService.closeInvestmentSidePanel();
  }

  /**
   * Bypass Material escape handler to call closeInvestmentSidebar,
   * which manage the current entity stacks.
   * @param event
   */
  @HostListener('keyup', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeInvestmentSidebar();
    }
  }

  /**
   * Navigate to the Investment's sheet.
   */
  public async navigateToInvestmentSheet(): Promise<void> {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.SHEET,
      EntityTypeEnum.INVESTMENT,
      this.investment.id
    );
    await this.investmentsService.navigateToInvestmentSheet(this.investment.id);
  }
}
