import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import {
  TextFieldBuilderComponent
} from '@app/shared/components/fields/text-field-builder/text-field-builder.component';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';

@Component({
  selector: 'email-field-builder',
  templateUrl: './email-field-builder.component.html',
  styleUrls: ['./email-field-builder.component.scss']
})
export class EmailFieldBuilderComponent extends TextFieldBuilderComponent implements OnInit, AfterViewInit {

  /**
   * Validators that are default to the field type
   * @private
   */
  private defaultValidators = [
    new FieldValidator(ValidatorType.EMAIL)
  ];

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );
  }

  /**
   * Get a validator function which combines all validators from the field's config.
   * @param data Additional data that can be passed to composing validators when required.
   * @returns Composed validator function.
   */
  protected override computeValidators(data?: { [key: string]: any }): ValidatorFn {
    return Validators.compose([
      ...this.defaultValidators.map(validator => this.validationService.getValidator(validator)),
      ...this.fieldConfig.field?.validators.map((validator) => {
        return this.validationService.getValidator(validator, this.entity, data);
      })
    ]);
  }

  /**
   * List of validators for which an error has been raised by the corresponding validator function.
   */
  public override get erroredValidators(): FieldValidator[] {
    return [...this.fieldConfig.field.validators, ...this.defaultValidators]
      .filter((validator) => !!this.form.get('field').errors?.[validator.code]);
  }

  /**
   * Prevent entering in edition mode when clicking on the mailto link.
   * @param event
   */
  public onMailTo(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
  }
}
