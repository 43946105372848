import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { AssetInput } from '@app/core/model/entities/asset/asset';
import { AssetType } from '@app/core/model/entities/asset/asset-type';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { AssetTypesService } from '@app/features/main/views/management/organization/asset-types/asset-types.service';
import { simplifyStringForSearch } from '@app/shared/extra/utils';
import { GeneratesObject } from '@app/shared/interfaces/generates-object';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { Observable, Subject, switchMap } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './asset-create-modal.component.html',
  providers: [AssetTypesService]
})
export class AssetCreateModalComponent implements OnInit, OnDestroy, GeneratesObject {

  public assetForm: FormGroup<{
    assetName: FormControl<string>,
    assetState: FormControl<string>,
    assetType: FormControl<AssetType>
  }>;
  public filteredAssetStates$: Observable<string[]>;
  public assetTypes$: Observable<AssetType[]>;

  protected formatAssetTypes = (assetType: AssetType): string => assetType.toString();

  private destroy$ = new Subject<void>();

  constructor(protected dialogRef: MatDialogRef<AssetCreateModalComponent>,
              protected accessManager: AccessManager,
              public appConfig: AppConfig,
              public appManager: AppManager,
              protected assetsService: AssetsService,
              protected assetTypesService: AssetTypesService,
              protected fb: UntypedFormBuilder
  ) {
    this.assetForm = this.fb.group({
      assetName: this.fb.control('', Validators.compose([
        Validators.required,
        Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)
      ])),
      assetState: this.fb.control(void 0, Validators.compose([
        Validators.required,
        Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)
      ])),
      assetType: this.fb.control(void 0, Validators.compose([
        Validators.required
      ]))
    });
  }

  public ngOnInit(): void {
    // Fetch available Asset states
    this.filteredAssetStates$ = this.assetsService.availableAssetStates$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(states => {
          return this.assetForm.get('assetState').valueChanges
            .pipe(
              takeUntil(this.destroy$),
              startWith(''),
              map((stringValue: string): string[] => {
                return states.filter(state =>
                  simplifyStringForSearch(state).includes(simplifyStringForSearch(stringValue))
                );
              })
            );
        })
      );

    // Fetch Asset Type of the current organization
    this.assetTypes$ = this.assetTypesService.assetTypes$
  }

  /**
   * Stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Return the Asset data that was filled in the form.
   * @return Asset data.
   */
  public getGeneratedObject(): AssetInput {
    return {
      name: this.assetForm.get('assetName').value,
      assetState: this.assetForm.get('assetState').value,
      typeId: this.assetForm.get('assetType').value.id
    };
  }
}

