import { Injectable } from '@angular/core';
import { LeaseStatusEnum } from '@app/core/enums/asset-occupant/lease-status-enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Lease, LeaseInput } from '@app/core/model/entities/asset/lease';
import { Occupant, OccupantInput } from '@app/core/model/entities/asset/occupant';
import { Document } from '@app/core/model/entities/document/document';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { thenReturn } from '@app/shared/extra/utils';
import { UsersService } from '@app/shared/services/users.service';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { from, Observable, Subject } from 'rxjs';
import { map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class OccupantsService {
  private sidePanelToggleSubject = new Subject<Lease | null>();

  private addLeaseSubject = new Subject<Lease>();
  private updateOccupantSubject = new Subject<Occupant>();
  private updateLeaseSubject = new Subject<Lease>();
  private renewLeaseSubject = new Subject<{ renewedLease: Lease, oldLease: Lease }>();
  private deleteLeasesSubject = new Subject<Lease[]>();

  private readonly leaseInfoGraphQlFragment = gql`
    fragment LeaseInfo on Lease {
      id
      assetId
      occupantId
      spaceIdsList
      status
      properties
      computedProperties
      creationDate
      creationUserId
      lastChangeDate
      lastChangeUserId
      dataDate
    }
  `;

  constructor(private apiService: ApiService,
              private appManager: AppManager,
              private documentsService: DocumentsService,
              private assetsService: AssetsService,
              private usersService: UsersService) {
  }

  /**
   * Fetches all Occupants belonging to the current Organization from the API, along with related Leases.
   */
  public get occupants$(): Observable<Occupant[]> {
    const query = gql`
      query OccupantsByOrganizationId($organizationId: String!) {
        occupantsByOrganizationId(organizationId: $organizationId) {
          id
          name
          organizationId
          properties
          computedProperties
          leasesList {
            ...LeaseInfo
          }
        }
      }
      ${this.leaseInfoGraphQlFragment}
    `;
    const variables = {
      organizationId: this.appManager.currentOrganization.id
    };

    return this.apiService.query({query, variables})
      .pipe(
        map(data => plainToInstance(Occupant, data['occupantsByOrganizationId'] as Occupant[])),
        // Fetch Users who created and last updated the Lease
        switchMap(occupants => from(occupants)),
        mergeMap((occupant) => {
          return from(occupant.leases)
            .pipe(
              mergeMap(lease => this.usersService.fetchUsersInfo(lease)),
              toArray(),
              thenReturn(occupant)
            );
        }),
        toArray()
      );
  }

  /**
   * Emits Occupants of the current Asset.
   */
  // TODO TTT-2814 merge occupants$ and assetOccupants$ in a new method
  public get assetOccupants$(): Observable<Occupant[]> {
    const query = gql`
      query AssetOccupants($assetId: String!) {
        occupantsByAssetId(assetId: $assetId) {
          id
          name
          organizationId
          properties
          computedProperties
          leasesList {
            ...LeaseInfo
          }
        }
      }
      ${this.leaseInfoGraphQlFragment}
    `;
    const variables = {
      assetId: this.appManager.currentAsset.id
    };

    return this.apiService.query({query, variables})
      .pipe(
        map(data => plainToInstance(Occupant, data['occupantsByAssetId'] as Occupant[])),
        // Fetch Users who created and last updated the Lease
        switchMap(occupants => from(occupants)),
        mergeMap((occupant) => {
          return from(occupant.leases)
            .pipe(
              mergeMap(lease => this.usersService.fetchUsersInfo(lease)),
              toArray(),
              thenReturn(occupant)
            );
        }),
        toArray()
      );
  }

  /**
   * Emits all Occupants' names belonging to the current Organization.
   */
  public get availableOccupantNames$(): Observable<string[]> {
    const query = gql`
      query OccupantNamesByOrganizationId($organizationId: String!) {
        occupantNamesByOrganizationId(organizationId: $organizationId)
      }
    `;
    const variables = {organizationId: this.appManager.currentOrganization.id};

    return this.apiService.query({query, variables})
      .pipe(map(data => data['occupantNamesByOrganizationId'] as string[]));
  }

  /**
   * Emits all types of Lease that are available for the current Organization.
   */
  public get availableLeaseTypes$(): Observable<string[]> {
    const query = gql`
      query OrganizationLeasesTypes($organizationId: String!) {
        leasesTypesByOrganizationId(organizationId: $organizationId)
      }`;
    const variables = {organizationId: this.appManager.currentOrganization.id};

    return this.apiService.query({query, variables})
      .pipe(map(data => data['leasesTypesByOrganizationId'] as string[]));
  }

  /**
   * Emits Lease once it has been created.
   */
  public get leaseAdded$(): Observable<Lease> {
    return this.addLeaseSubject.asObservable();
  }

  /**
   * Emits an Occupant whenever it has been updated.
   */
  public get occupantUpdated$(): Observable<Occupant> {
    return this.updateOccupantSubject.asObservable();
  }

  /**
   * Emits a Lease whenever it has been updated.
   */
  public get leaseUpdated$(): Observable<Lease> {
    return this.updateLeaseSubject.asObservable();
  }

  /**
   * After a Lease is renewed, emits the old Lease and the new one.
   */
  public get leaseRenewed$(): Observable<{ renewedLease: Lease, oldLease: Lease }> {
    return this.renewLeaseSubject.asObservable();
  }

  /**
   * Emits a list of Leases once they have been deleted.
   */
  public get leasesDeleted$(): Observable<Lease[]> {
    return this.deleteLeasesSubject.asObservable();
  }

  /**
   * Emits a Lease whenever the side panel opens, or null whenever it closes.
   */
  public get sidePanelToggle$(): Observable<Lease | null> {
    return this.sidePanelToggleSubject.asObservable();
  }

  /**
   * Call the API to create a new Lease for the Occupant.
   * @param organizationId Occupant's Organization ID.
   * @param assetId ID of Asset the new Lease should be linked to.
   * @param occupantInput Occupant data. Can be data from an existing Occupant.
   * @param leaseInput New Lease's data.
   */
  public createLease(
    organizationId: string,
    assetId: string,
    occupantInput: OccupantInput,
    leaseInput: LeaseInput,
  ): Observable<Lease> {
    const mutation = gql`
      mutation CreateOccupant( $occupantInput: OccupantInput!, $assetId: String!, $organizationId: String!, $leaseInput: LeaseInput! ){
        createOccupant(occupantInput: $occupantInput, assetId: $assetId, organizationId: $organizationId, leaseInput: $leaseInput){
          name
          id
          organizationId
          properties
          computedProperties
          leasesList {
            ...LeaseInfo
          }
        }
      }
      ${this.leaseInfoGraphQlFragment}
    `;
    const variables = {occupantInput, assetId, organizationId, leaseInput};

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => {
          const occupant = plainToInstance(Occupant, data['createOccupant']);
          occupant.leases = occupant.getSortedLeases();
          return occupant.lastLease;
        }),
        switchMap(lease => this.usersService.fetchUsersInfo(lease)),
        tap(lease => this.addLeaseSubject.next(lease))
      );
  }

  /**
   * Call the API to update an Occupant.
   * @param occupant Occupant to update.
   * @param occupantInput Data for updating the Occupant.
   * @return Updated Occupant.
   */
  public updateOccupant(occupant: Occupant, occupantInput: OccupantInput): Observable<Occupant> {
    const mutation = gql`
      mutation UpdateOccupant($occupantId: String!, $occupantInput: OccupantInput!) {
        updateOccupant(occupantId: $occupantId, occupantInput: $occupantInput) {
          name
          id
          organizationId
          properties
          computedProperties
          leasesList {
            ...LeaseInfo
          }
        }
      }
      ${this.leaseInfoGraphQlFragment}
    `;
    const variables = {
      occupantId: occupant.id,
      occupantInput: {
        name: occupant.name,
        ...occupantInput
      }
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(Occupant, data['updateOccupant'])),
        switchMap(occupant =>
          from(occupant.leases)
            .pipe(
              mergeMap(lease => this.usersService.fetchUsersInfo(lease)),
              // Update occupant reference in each lease belonging to the updated occupant
              tap(lease => {
                lease.occupant = occupant;
                this.updateLeaseSubject.next(lease);
              }),
              toArray(),
              thenReturn(occupant)
            )
        ),
        tap(updatedOccupant => this.updateOccupantSubject.next(updatedOccupant))
      );
  }

  /**
   * Call the API to update a Lease.
   * @param lease Lease to update.
   * @param leaseInput Data for updating the Lease.
   * @return Updated Lease.
   */
  public updateLease(lease: Lease, leaseInput: LeaseInput): Observable<Lease> {
    const mutation = gql`
      mutation UpdateLease($leaseId: String!, $leaseInput: LeaseInput!){
        updateLease(leaseId: $leaseId, leaseInput: $leaseInput){
          ...LeaseInfo
        }
      }
      ${this.leaseInfoGraphQlFragment}
    `;
    const variables = {
      leaseId: lease.id,
      leaseInput: {
        spaceIds: lease.spaceIds,
        ...leaseInput
      }
    };
    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(Lease, data['updateLease'])),
        switchMap(lease => this.usersService.fetchUsersInfo(lease)),
        tap(updatedLease => {
          updatedLease.occupant = lease.occupant;
          this.updateLeaseSubject.next(updatedLease);
        })
      );
  }

  /**
   * Renew an existing Lease.
   * @param leaseToRenew Lease to be renewed.
   * @param spaceIds New Lease's Spaces.
   * @return Observable that will emit the new Lease once created.
   */
  public renewLease(leaseToRenew: Lease, spaceIds: string[]): Observable<Lease> {
    const mutation = gql`
      mutation RenewLease($assetId: String!, $occupantId: String!, $leaseInput: LeaseInput!, $oldLeaseId: String!){
        renewLease(assetId: $assetId, occupantId: $occupantId, leaseInput: $leaseInput, oldLeaseId: $oldLeaseId){
          ...LeaseInfo
        }
      }
      ${this.leaseInfoGraphQlFragment}
    `;
    const variables = {
      assetId: leaseToRenew.assetId,
      occupantId: leaseToRenew.occupant.id,
      leaseInput: {spaceIds},
      oldLeaseId: leaseToRenew.id
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(Lease, data['renewLease'])),
        switchMap(lease => this.usersService.fetchUsersInfo(lease)),
        tap(renewedLease => {
          leaseToRenew.occupant.addLease(renewedLease);
          leaseToRenew.status = LeaseStatusEnum.LEASE_CLOSED;
          this.renewLeaseSubject.next({renewedLease: renewedLease, oldLease: leaseToRenew});
        })
      );
  }

  /**
   * Make an API call to delete the given Leases.
   * @param leases Leases to be deleted.
   * @return True if all Leases were deleted, false otherwise.
   */
  public deleteLeases(leases: Lease[]): Observable<boolean> {
    const mutation = gql`
      mutation DeleteLeases($leaseIds: [String!]) {
        deleteLeases(leaseIds: $leaseIds)
      }
    `;
    const variables = {
      leaseIds: leases.map(lease => lease.id)
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => data['deleteLeases'] as boolean),
        tap(success => {
          if (success) {
            const removedLeases = leases.map(lease => lease.occupant.removeLease(lease.id));
            this.deleteLeasesSubject.next(removedLeases);
          }
        })
      );
  }

  /**
   * Navigate to the occupants tab of the Asset's sheet.
   * @param assetId Id of the Asset to navigate to.
   */
  public async navigateToAssetSheet(assetId: string): Promise<void> {
    await this.assetsService.navigateToAssetSheet(assetId, 'occupants');
  }

  /**
   * Load all the Documents related to a given Lease.
   * @param leaseId id of the selected Lease.
   * @return Observable emitting Documents linked to the Lease.
   */
  public loadLeaseDocuments(leaseId: string): Observable<Document[]> {
    return this.documentsService.loadEntityDocuments(
      leaseId,
      EntityTypeEnum.LEASE,
      DocumentTypeEnum.LEASE_DOCUMENT
    );
  }

  /**
   * Open the sidenav with information about the selected Lease and its related Occupant.
   * @param lease Lease to be displayed in the side panel.
   */
  public openLeaseSidePanel(lease: Lease): void {
    this.sidePanelToggleSubject.next(lease);
  }

  /**
   * Close the side panel.
   */
  public closeLeaseSidePanel(): void {
    this.sidePanelToggleSubject.next(null);
  }
}
