import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './error-unauthorized.component.html'
})
export class ErrorUnauthorizedComponent implements OnInit {
  private title = inject(Title);
  private translate = inject(TranslateService);

  public authService = inject(AuthService);

  /**
   * Set the page's title and sign out the user.
   */
  public ngOnInit(): void {
    this.title.setTitle(this.translate.instant('TITLE.UNAUTHORIZED') + ' - myA');

    // Sign out user
    this.authService.logout({openUrl: false})
      .subscribe();
  }
}
