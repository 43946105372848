import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { environment } from '@env/environment';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  private httpClient = inject(HttpClient);

  /**
   * Generate and download the import template.
   * @param organizationId the organization ID
   * @param entityType the entity type
   * @param locale the locale to use
   * @param importConfigurationKey key of configuration related to import
   * @param isExport whether the template is filled with data
   */
  public downloadImportTemplate(
    organizationId: string,
    entityType: EntityTypeEnum,
    locale: string,
    importConfigurationKey: string = null,
    isExport = false
  ): Observable<HttpResponse<Blob>> {
    let url = environment.backend.baseUrl + '/import/template'
      + `?organizationId=${organizationId}`
      + `&locale=${locale}`
      + `&entityType=${entityType}`
      + `&isExport=${isExport}`
      + `&timeZoneId=${dayjs.tz.guess()}`;

    if (importConfigurationKey != null) {
      url += `&importConfigurationKey=${importConfigurationKey}`;
    }

    return this.httpClient.get(url, {observe: 'response', responseType: 'blob'})
      .pipe(
        tap((httpResponseBlob) => {
          const objectUrl = URL.createObjectURL(httpResponseBlob.body);
          const filename = httpResponseBlob.headers.get('Content-Disposition')
            .split(';')
            .find(n => n.includes('filename='))
            .replace(/"/g, '')
            .replace('filename=', '')
            .trim();
          const anchor = document.createElement('a');
          anchor.download = filename;
          anchor.href = objectUrl;
          anchor.click();
          URL.revokeObjectURL(objectUrl);
        })
      );
  }
}
