import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { UserGlobalBIReport } from '@app/core/model/client/user';
import { AccessManager } from '@services/managers/access.manager';

@Component({
  templateUrl: './organizations-global-bi-reports.component.html'
})
export class OrganizationsGlobalBIReportsComponent implements OnInit {

  protected globalBIReport: UserGlobalBIReport;
  protected EntityType = EntityTypeEnum;
  protected workspaceId: string;

  private route = inject(ActivatedRoute);
  private accessManager = inject(AccessManager);

  /**
   * Set the global Power BI report according to its name (variable passed in url parameter).
   */
  public ngOnInit(): void {
    this.workspaceId = this.accessManager.currentUser.workspaceId;
    this.globalBIReport = this.accessManager.currentUser.globalBIReports?.find(globalReport =>
      globalReport.name === this.route.snapshot.paramMap.get('name')
    );
  }
}
