<div class="mat-select-autocomplete-container"
  #container
  [attr.aria-labelledby]="formField?.getLabelId()"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut()">
  <input [id]="inputId"
    matInput
    appVisibilityObserver
    matAutocompleteOrigin
    #input
    #trigger="matAutocompleteTrigger"
    #origin="matAutocompleteOrigin"
    [formControl]="control"
    [matAutocomplete]="autocomplete"
    [matAutocompleteConnectedTo]="autocompleteOrigin || origin"
    (input)="onInput()"
    (visibilityChange)="onVisibilityChange($event)"
    [placeholder]="placeholder"
    [required]="required"
    [matAutocompleteDisabled]="control.disabled"/>
  <mat-autocomplete #autocomplete="matAutocomplete"
    [classList]="['mat-mdc-select-autocomplete-panel']"
    [displayWith]="displayWith"
    (optionSelected)="optionSelected($event.option.value)">
    @if (!required && hasData) {
      <mat-option [value]="null">
        {{ valueFormatter(null) }}
      </mat-option>
    }
    @for (option of dataSourceInput$ | async; track option.id) {
      <mat-option [value]="option">
        {{ valueFormatter(option) }}
      </mat-option>
    }
  </mat-autocomplete>
</div>
