import { User } from '@app/core/model/client/user';
import { Transform, Type } from 'class-transformer';
import dayjs, { Dayjs } from 'dayjs';

export class Report {
  public id: number;

  public enabled: boolean;

  @Transform(fn => dayjs(fn.value), {toClassOnly: true})
  @Type(() => String)
  public lastSendDate: Dayjs;

  public weekday: number;

  public timeOfDay: number;

  public frequency: number;

  public user: User;

  public assetConfig?: ReportEntityConfig;

  public spaceConfig?: ReportEntityConfig;

  public checkConfig?: ReportEntityExpiredConfig;

  public occupantConfig?: ReportEntityConfig;

  public leaseConfig?: ReportEntityExpiredConfig;

  public contractConfig?: ReportEntityExpiredConfig;

  public workConfig?: ReportEntityConfig;

  public equipmentConfig?: ReportEntityConfig;

  public projectConfig?: ReportEntityConfig;

  public investmentConfig?: ReportEntityConfig;

  public documentConfig?: ReportEntityConfig;
}

interface ReportEntityConfig {
  createdEnabled?: boolean;
  updatedEnabled?: boolean;
  deletedEnabled?: boolean;
}

interface ReportEntityExpiredConfig extends ReportEntityConfig {
  expiredEnabled?: boolean;
  renewal?: {
    interval: number;
    renewalEnabled: boolean;
  };
}
