import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RetoolApp, RetoolAppInput } from '@app/core/model/entities/retool/retool-app';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class RetoolAppService {
  private addRetoolAppSubject = new Subject<RetoolApp>();
  private updateRetoolAppSubject = new Subject<RetoolApp>();
  private deleteRetoolAppSubject = new Subject<RetoolApp>();

  private retoolAppGraphQlFragment = gql`
    fragment RetoolAppInfo on RetoolApp {
      id
      name
      color
      organizationId
      retoolId
      groupId
    }
  `;

  private apiService = inject(ApiService);
  private appManager = inject(AppManager);

  /**
   * Observable that emits the list of all the current Organization's RetoolApp
   * @return RetoolApp[]
   */
  public get retoolApps$(): Observable<RetoolApp[]> {
    const query = gql`
      query RetoolApps($organizationId: String!) {
        retoolApps(organizationId: $organizationId) {
          ...RetoolAppInfo
        }
      }
      ${this.retoolAppGraphQlFragment}
    `;
    const variables = {organizationId: this.appManager.currentOrganization.id};
    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(RetoolApp, data['retoolApps'] as RetoolApp[])));
  }

  /**
   * Observable that emits a RetoolApp after it has been created.
   */
  public get retoolAppAdded$(): Observable<RetoolApp> {
    return this.addRetoolAppSubject.asObservable();
  }

  /**
   * Observable that emits a RetoolApp after it has been updated.
   */
  public get retoolAppUpdated$(): Observable<RetoolApp> {
    return this.updateRetoolAppSubject.asObservable();
  }

  /**
   * Observable that emits a RetoolApp after it has been deleted.
   */
  public get retoolAppDeleted$(): Observable<RetoolApp> {
    return this.deleteRetoolAppSubject.asObservable();
  }

  /**
   * Fetch the url of an app based on ID contained in the activated route's path.
   * @param route Activated route.
   * @return string URL to embed application
   */
  public resolve(route: ActivatedRouteSnapshot): Observable<string> {
    const query = gql`
      query AppUrl($appId: Int!) {
        appUrl(appId: $appId)
      }
    `;
    const variables = {
      appId: Number.parseInt(route.paramMap.get('id'))
    };
    return this.apiService.query<{ appUrl: string }>({query, variables})
      .pipe(map(data => data.appUrl));
  }

  /**
   * Call the API to create a RetoolApp and start processing the related data.
   * @param appInput Data for creating the RetoolApp.
   * @return Observable that emits the created RetoolApp.
   */
  public createApp(appInput: RetoolAppInput): Observable<RetoolApp> {
    const mutation = gql`
      mutation CreateRetoolApp($organizationId: String!, $appInput: RetoolAppInput!) {
        createRetoolApp(organizationId: $organizationId, appInput: $appInput) {
          ...RetoolAppInfo
        }
      }
      ${this.retoolAppGraphQlFragment}
    `;
    const variables = {organizationId: this.appManager.currentOrganization.id, appInput};
    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => data['createRetoolApp'] as RetoolApp),
        tap(app => this.addRetoolAppSubject.next(app))
      );
  }

  /**
   * Call the API to update a RetoolApp.
   * @param appId ID of the app in database.
   * @param appInput Data for updating the RetoolApp.
   * @return Observable that emits the updated RetoolApp.
   */
  public editApp(appId: number, appInput: RetoolAppInput): Observable<RetoolApp> {
    const mutation = gql`
      mutation UpdateRetoolApp($appId: Int!, $appInput: RetoolAppInput!) {
        updateRetoolApp(appId: $appId, appInput: $appInput) {
          ...RetoolAppInfo
        }
      }
      ${this.retoolAppGraphQlFragment}
    `;
    const variables = {appId, appInput};
    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => data['updateRetoolApp'] as RetoolApp),
        tap(app => this.updateRetoolAppSubject.next(app))
      );
  }

  /**
   * Call the API to delete a RetoolApp and stop any ongoing data processing.
   * @param retoolApp RetoolApp to delete.
   * @return Observable that emits a boolean indicating whether the RetoolApp was successfully deleted.
   */
  public deleteRetoolApp(retoolApp: RetoolApp): Observable<boolean> {
    const mutation = gql`
      mutation DeleteRetoolApp($appId: Int!) {
        deleteRetoolApp(appId: $appId)
      }
    `;
    const variables = {appId: retoolApp.id};
    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => data['deleteRetoolApp'] as boolean),
        tap(deleted => deleted && this.deleteRetoolAppSubject.next(retoolApp))
      );
  }
}
