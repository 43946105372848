import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { gql } from '@apollo/client/core';
import { AppConfig } from '@app/core/app.config';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import {
  Dataflow,
  PageBiReport,
  PowerBiReport,
  PowerBiReportConfig,
  RefreshState
} from '@app/core/model/other/power-bi';
import ApiService from '@services/api.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { plainToInstance } from 'class-transformer';
import dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Service to load and interact with the Power BI report.
 */
@Injectable()
export class PowerBiService {

  private readonly powerBiReportConfigInfoFragment = gql`
    fragment PowerBiReportConfigInfo on PowerBiReportConfig {
      id
      name
      embedUrl
      accessToken
    }
  `;

  constructor(private apiService: ApiService,
              private appConfig: AppConfig,
              private appManager: AppManager,
              private accessManager: AccessManager) {
  }

  public resolve(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<PowerBiReportConfig> {
    const reportId = this.appManager.currentOrganization.properties['assetReportId'];
    const workspaceId = this.appManager?.currentOrganization?.properties?.['workspaceId'];
    return reportId && this.accessManager.hasAccess(PermissionEnum.VIEW_BI_REPORT) ?
      this.loadPowerBiReportConfig(workspaceId, reportId) : null;
  }

  /**
   * Retrieve the configuration to display the Power BI report.
   * @param workspaceId The workspace ID.
   * @param reportId The report ID.
   * @return The Power BI embed configuration.
   */
  public loadPowerBiReportConfig(workspaceId: string, reportId: string): Observable<PowerBiReportConfig> {
    const organizationId = this.appManager?.currentOrganization?.id;
    const query = gql`
      query ReportConfig($organizationId: String, $workspaceId: String!, $reportId: String!) {
        powerBiReportConfig(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId) {
          ...PowerBiReportConfigInfo
        }
      }
      ${this.powerBiReportConfigInfoFragment}
    `;
    const variables = {organizationId, workspaceId, reportId};

    return this.apiService.query({query, variables})
      .pipe(
        map(data => {
          const reportConfig = plainToInstance(PowerBiReportConfig, data['powerBiReportConfig']);
          if (!!this.appManager?.currentOrganization) {
            reportConfig.filters.push(
              PowerBiReportConfig.buildFilter('organizations', '_id', this.appManager.currentOrganization.id)
            );
          }
          return reportConfig;
        })
      );
  }

  /**
   * Exports Power BI reports into a single pdf.
   * The resulting report is sent by email to the user when it's ready.
   * @param workspaceId The workspace id.
   * @param reportId The report id.
   * @param filters List of filters, export one page per filter.
   * @return True.
   */
  public exportPowerBiReports(workspaceId: string, reportId: string, filters: Record<string, any>[]): Observable<true> {
    const query = gql`
      query ExportReport($organizationId: String, $workspaceId: String!, $reportId: String!, $filters: [Object]!, $locale: String!) {
        exportPowerBiReports(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId, filters: $filters, locale: $locale)
      }
    `;
    const variables = {
      organizationId: this.appManager.currentOrganization.id,
      workspaceId,
      reportId,
      filters,
      locale: this.appManager.currentOrganization.locale
    };

    return this.apiService.query({query, variables})
      .pipe(map(data => data['exportPowerBiReports'] as true));
  }

  /**
   * Exports Power BI paginated report into a pdf file.
   * The resulting report is sent by email to the user when it's ready.
   * @param workspaceId The workspace id.
   * @param reportId The report id.
   * @param entityType The entity type.
   * @param entityIds Optional. List of selected entities. All entities will be displayed if the list is empty or null, unless an assetId is provided.
   * @param assetId Optional. If no entityIds are provided, only entities belonging to the asset will be displayed.
   * @return True.
   */
  public exportPaginatedPowerBiReport(
    workspaceId: string,
    reportId: string,
    entityType: EntityTypeEnum,
    entityIds: string[] = null,
    assetId: string = null
  ): Observable<true> {
    const organizationId = this.appManager.currentOrganization.id;
    const query = gql`
      query ExportPaginatedReport($organizationId: String!, $workspaceId: String!, $reportId: String!, $entityType: EntityType!, $entityIds: [String!], $assetId: String) {
        exportPaginatedPowerBiReport(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId, entityType: $entityType, entityIds: $entityIds, assetId: $assetId)
      }
    `;
    const variables = {organizationId, workspaceId, reportId, entityType, entityIds, assetId};

    return this.apiService.query({query, variables})
      .pipe(map(data => data['exportPaginatedPowerBiReport'] as true));
  }

  /**
   * Fetch all Power BI reports.
   * @param workspaceId Workspace ID.
   * @return An array of Power BI reports with id, name and type.
   */
  public getAllPowerBiReports(workspaceId: string): Observable<PowerBiReport[]> {
    const query = gql`
      query PowerBiReports($workspaceId: String!) {
        powerBiReports(workspaceId: $workspaceId) {
          id
          name
          type
        }
      }
    `;
    const variables = {workspaceId};

    return this.apiService.query({query, variables})
      .pipe(map(data => data['powerBiReports'] as PowerBiReport[]));
  }

  /**
   * Get all dataflows in the workspace.
   * @param workspaceId Workspace ID.
   * @return An array of dataflows with id and name.
   */
  public getAllDataflows(workspaceId: string): Observable<Dataflow[]> {
    const query = gql`
      query Dataflows($workspaceId: String!) {
        dataflows(workspaceId: $workspaceId) {
          id
          name
        }
      }
    `;
    const variables = {workspaceId};
    return this.apiService.query({query, variables})
      .pipe(map(data => data['dataflows']));
  }

  /**
   * Fetch all Power BI report pages.
   * @return An array of pages with id and name.
   * @param workspaceId Workspace ID
   * @param reportId Report ID
   */
  public getAllPowerBiReportPages(workspaceId: string, reportId: string): Observable<PageBiReport[]> {
    const query = gql`
      query PowerBiReportPages($reportId: String!, $workspaceId: String!) {
        powerBiReportPages(reportId: $reportId, workspaceId: $workspaceId) {
          id
          name
        }
      }
    `;
    const variables = {workspaceId, reportId};

    return this.apiService.query({query, variables})
      .pipe(map(data => data['powerBiReportPages'] as PageBiReport[]));
  }

  /**
   * Get the last refresh time and status for the dataflow and each dataset for the organization.
   * @return Observable of RefreshState
   */
  public getRefreshStates(): Observable<RefreshState[]> {
    const organizationId = this.appManager.currentOrganization.id;
    const workspaceId = this.appManager?.currentOrganization?.properties?.['workspaceId'];
    const dataflowId = this.appManager?.currentOrganization?.properties?.['dataflowId'];
    if (!workspaceId || !dataflowId) return of([]);

    const query = gql`
      query RefreshStates($organizationId: String!, $workspaceId: String!, $dataflowId: String!) {
        refreshStates(organizationId: $organizationId, workspaceId: $workspaceId, dataflowId: $dataflowId) {
          name
          lastRefreshTime
          status
        }
      }
    `;
    const variables = {organizationId, workspaceId, dataflowId};

    return this.apiService.query({query, variables})
      .pipe(
        map(data => {
          const refreshStates = data['refreshStates'] as RefreshState[];
          refreshStates.forEach(state => {
            if (!!state.lastRefreshTime) {
              state.lastRefreshTime = dayjs.utc(
                state.lastRefreshTime,
                this.appConfig.DATETIME_FORMAT
              )
                .local()
                .format(this.appConfig.DISPLAY_DATETIME_FORMAT);
            }
          });
          return refreshStates;
        })
      );
  }

  /**
   * Refresh dataflow and datasets for the organization.
   * The refresh is done asynchronously.
   * @return true
   */
  public refresh(): Observable<true> {
    const organizationId = this.appManager.currentOrganization.id;
    const workspaceId = this.appManager?.currentOrganization?.properties?.['workspaceId'];
    const dataflowId = this.appManager?.currentOrganization?.properties?.['dataflowId'];
    const mutation = gql`
      mutation RefreshAll($organizationId: String!, $workspaceId: String!, $dataflowId: String!) {
        refreshAll(organizationId: $organizationId, workspaceId: $workspaceId, dataflowId: $dataflowId)
      }
    `;
    const variables = {organizationId, workspaceId, dataflowId};

    return this.apiService.mutate({mutation, variables})
      .pipe(map(data => data['refreshAll'] as true));
  }
}
