import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { ActionEnum, EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { Entity } from '@app/core/model/entities/entity';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { IFieldValueState, INITIAL_STATE } from '@app/shared/store/reducers/field-value.reducer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss']
})
export class AbstractInlineEditComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public control: UntypedFormControl = new FormControl<any>('');
  @Input() public initialValue: any;
  @Input() public validators: FieldValidator[];
  @Input() public entity: Entity;

  @Input() public label: string = '';
  @Input() public fieldCode: string = '';

  @Input() public disabled: boolean = false;
  @Input() public permissionsForEdition: string[] = [];
  @Input() public eventsOrigin: EventOriginEnum = EventOriginEnum.SIDEPANEL;

  @Output() public save: EventEmitter<any> = new EventEmitter<any>();
  @Output() public enter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatInput) public input: MatInput;
  @ViewChild('select') public select: MatSelect;

  @ViewChild('gridContainer') public gridContainer: ElementRef;

  public editMode: boolean = false;
  protected inlineEditState: IFieldValueState = INITIAL_STATE;

  protected destroy$ = new Subject<void>();

  constructor(protected validationService: ValidationService,
              protected singleEditService: SingleEditService,
              protected analyticsService: AnalyticsService) {
  }

  public ngOnInit(): void {
    // Cancel editing this field if the current field label being edited does not match
    this.singleEditService.singleEditSubject.pipe(takeUntil(this.destroy$)).subscribe((fieldLabel?: string) => {
      if (fieldLabel !== this.fieldCode) {
        this.cancel();
      }
    });

    // Listen for user input
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeField();
      });
  }

  /**
   * Reacts to changes in the component inputs.
   * Updates the inner form control to a new value and updates the validators
   * @param changes a hashtable of changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    this.initialValue = changes['initialValue']?.currentValue;
    this.validators = changes['validators']?.currentValue || this.validators;

    this.control.setValue(this.initialValue);
    this.control.setValidators(Validators.compose(this.validators.map(v => {
      return this.validationService.getValidator(v, this.entity);
    })));
    this.control.setAsyncValidators(Validators.composeAsync(this.validators.map(v => {
      return this.validationService.getAsyncValidator(v, this.entity, this.fieldCode);
    })));
    this.control.updateValueAndValidity({emitEvent: false});
    this.resetField();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public makeEditable(): void {
    if (this.disabled) {
      return;
    }
    this.editMode = true;
    this.analyticsService.trackInlineActionEvent(this.fieldCode, ActionEnum.SELECT, this.eventsOrigin);
    this.singleEditService.singleEditSubject.next(this.fieldCode);

    setTimeout(() => {
      if (this.gridContainer) {
        this.gridContainer.nativeElement.focus();
        // Firefox do not scroll to the focus element, so we do it manually
        this.gridContainer.nativeElement.scrollIntoView({behavior: 'instant', block: 'center'});
      }
      if (this.input) {
        this.input.focus();
      }
      if (this.select) {
        this.select.focus();
      }

    }, 0);
  }

  public onKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.callSave();
    } else if (event.key === 'Escape') {
      this.cancel();
    }
    event.stopImmediatePropagation();
  }

  public preventSave(): boolean {
    return this.control.invalid || this.control.pending;
  }

  public callSave(): void {
    if (this.preventSave()) {
      return;
    }

    this.analyticsService.trackInlineActionEvent(this.fieldCode, ActionEnum.SAVE, this.eventsOrigin);
    this.resetField();
    this.save.emit({field: this.fieldCode, value: this.inlineEditState.currentValue});
    this.editMode = false;
  }

  public onFocus(): void {
    this.enter.emit();
  }

  public cancel(): void {
    this.editMode = false;
    this.revertField();
    this.control.setValue(this.inlineEditState.currentValue, {emitEvent: false});
  }

  public cancelEditable(): void {
    this.analyticsService.trackInlineActionEvent(this.fieldCode, ActionEnum.CANCEL, this.eventsOrigin);
    this.cancel();
  }

  /**
   * Methods to manage the state
   */
  public resetField(): void {
    this.inlineEditState = {
      currentValue: this.control.value,
      originalValue: this.control.value
    };
  }

  public revertField(): void {
    this.inlineEditState = {
      ...this.inlineEditState,
      currentValue: this.inlineEditState.originalValue
    };
  }

  public changeField(): void {
    this.inlineEditState = {
      ...this.inlineEditState,
      currentValue: this.control.value
    };
  }
}
