import { inject, Injectable } from '@angular/core';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class NotificationManager {
  private snackbarManager = inject(SnackbarManager);
  private newFeatureMessageSubject: ReplaySubject<string> = new ReplaySubject<string>(1);

  /**
   * Display any message sent through {@link showNewFeatureMessage}. If messages were already queued, the last one will
   * be displayed right away.
   */
  public showNewFeatureMessage(): void {
    this.newFeatureMessageSubject.subscribe((message) => {
      this.snackbarManager.showNotificationSnackbar(message);
    });
  }

  /**
   * Queue a new features message to be displayed.
   * Note: {@link showNewFeatureMessage} must be called for the queued messages to be displayed.
   * @param message Message to display.
   */
  public addNewFeatureMessage(message: string): void {
    this.newFeatureMessageSubject.next(message);
  }
}
