import { Component, inject } from '@angular/core';
import { AssetType } from '@app/core/model/entities/asset/asset-type';
import { DatagridVisualisation } from '@app/core/model/entities/visualisation/datagrid-visualisation';
import { AssetTypesService } from '@app/features/main/views/management/organization/asset-types/asset-types.service';
import {
  VisualisationToolPanelComponent
} from '@app/shared/components/visualisation-tool-panel/visualisation-tool-panel.component';
import { Observable } from 'rxjs';
import { map, mergeWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'asset-type-visualisation-tool-panel',
  templateUrl: './asset-type-visualisation-tool-panel.component.html',
  styleUrls: ['./asset-type-visualisation-tool-panel.component.scss'],
  providers: [AssetTypesService]
})
export class AssetTypeVisualisationToolPanelComponent extends VisualisationToolPanelComponent {

  public assetTypes: AssetType[];
  protected readonly AssetType = AssetType;
  protected assetTypeVisualisations$: Observable<DatagridVisualisation[]>;
  private assetTypeService = inject(AssetTypesService);

  /**
   * Initializes the form
   */
  constructor() {
    super();
    this.visualisationForm.addControl('currentAssetType', this.fb.control(AssetType.NullAssetType));
  }

  /**
   * Method used by Ag-grid to initialise the tool panel with grid parameter.
   * Initialises the tool panel with grid parameters.
   * @param params this is used to get the Grid API
   */
  public agInit(params: any): void {
    this.assetTypeService.assetTypes$.subscribe(types => {
      this.assetTypes = types;
      super.agInit(params);
    });

  }

  /**
   * Method is called after Ag-grid to initialise the tool panel with grid parameter.
   * It sets up subscriptions to handle changes in datagrid visualizations.
   */
  protected afterAgInit(): void {
    // Initialize or update the list of visualisations
    this.assetTypeVisualisations$ = this.visualisationForm.controls.currentAssetType.valueChanges
      .pipe(
        mergeWith(this.datagridVisualisationService.applyDatagridVisualisation$
          .pipe(
            takeUntil(this.destroy$),
            map(() => this.visualisationForm.controls.currentAssetType.value)
          )
        ),
        map((selectedAssetType: AssetType) => {
          // If a new asset type was selected or a new visualisation was created, filter the list of all visualisation of that type
          return this.datagridVisualisationService.datagridVisualisations
            .filter(visualisation => {
              return (!visualisation.assetTypeId && selectedAssetType === AssetType.NullAssetType) || visualisation.assetTypeId === selectedAssetType.id;
            });
        }),
        tap(visualisations => {
          const currentVisualisation = this.visualisationForm.controls.currentVisualisation.value;
          const currentAssetType = this.visualisationForm.controls.currentAssetType.value;
          if (currentAssetType.id !== currentVisualisation.assetTypeId) {
            // If the selected asset type is different from the current visualisation's asset type, fetch the default visualisation from this new asset type
            const defaultVisualisationForAssetType = visualisations.find(visualisation => visualisation.isDefault);
            this.visualisationForm.controls.currentVisualisation.setValue(defaultVisualisationForAssetType);
          }
        })
      );

    this.datagridVisualisationService.applyDatagridVisualisation$
      .pipe(takeUntil(this.destroy$))
      .subscribe(visualisation => {
        this.visualisationForm.controls.currentVisualisation.setValue(visualisation);
      });

    // Subscription to handle deletion of datagrid visualizations.
    this.datagridVisualisationService.deleteDatagridVisualisation$
      .pipe(takeUntil(this.destroy$))
      .subscribe(assetTypeId => {
        // Once a visualisation is deleted, default back to the default visualisation still remaining for the asset type
        this.visualisationForm.controls.currentVisualisation.setValue(
          this.datagridVisualisationService.datagridVisualisations.find(visualisation =>
            visualisation.isDefault && assetTypeId === visualisation.assetTypeId
          )
        );
      });
  }

  /**
   * Open a dialog to create a visualisation.
   * Create for a specific asset type if a filter is set
   */
  public createVisualisation(): void {
    const assetTypeId = this.visualisationForm.controls.currentAssetType.value?.id;
    this.datagridVisualisationService.openCreateVisualisationDialog(assetTypeId);
  }

  /**
   * Update the current visualisation.
   * Update for a specific asset type if a filter is set
   */
  public updateVisualisation(): void {
    const currentVisualisation = this.visualisationForm.controls.currentVisualisation.value;
    const assetTypeId = this.visualisationForm.controls.currentAssetType.value?.id;
    this.datagridVisualisationService.updateDatagridVisualisation(
      currentVisualisation,
      this.gridStateService.gridState,
      assetTypeId
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /**
   * Handles when all the visualisation data are loaded.
   * @param currentDatagridVisualisation - The current datagrid visualisation.
   * @param defaultDatagridVisualisation - The default datagrid visualisation.
   */
  public onDataLoaded(currentDatagridVisualisation: DatagridVisualisation,
                      defaultDatagridVisualisation: DatagridVisualisation): void {
    const selectedAssetType = this.assetTypes.singleOrNull(assetType => assetType.id === currentDatagridVisualisation.assetTypeId) || AssetType.NullAssetType;
    this.visualisationForm.controls.currentAssetType.setValue(selectedAssetType);

    /*
    Super call will check that visualisation configs exist
    and set the current visualisation based on user's preferences
     */
    super.onDataLoaded(currentDatagridVisualisation, defaultDatagridVisualisation);
  }

  public refresh(): void {
    // Called when the user clicks the clear all button
    this.visualisationForm.controls.currentAssetType.setValue(AssetType.NullAssetType);
  }
}
