export enum FieldTypeEnum {
  UNKNOWN_FIELD_TYPE = 'UNKNOWN_FIELD_TYPE',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  TEXT_HEADER = 'TEXT_HEADER',
  NUMERIC = 'NUMERIC',
  EMAIL = 'EMAIL',
  YEAR = 'YEAR',
  LIST = 'LIST',
  NUMERIC_LIST = 'NUMERIC_LIST',
  DPE_LIST = 'DPE_LIST',
  LIST_CHECK_RESULT = 'LIST_CHECK_RESULT',
  TEXT_AUTOCOMPLETE = 'TEXT_AUTOCOMPLETE',
  LIST_MULTIVALUE = 'LIST_MULTIVALUE',
  SUGGESTBOX = 'SUGGESTBOX',
  CHIPS_AUTOCOMPLETE = 'CHIPS_AUTOCOMPLETE',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  CHIPS = 'CHIPS',
  RADIOBUTTON = 'RADIOBUTTON',
  OBJECT_ID = 'OBJECT_ID',
  BUDGET = 'BUDGET',
  DOCUMENT_RELATED_ASSETS = 'DOCUMENT_RELATED_ASSETS',
  SPACES = 'SPACES',
  SPACE_PATH = 'SPACE_PATH',
  LINK = 'LINK',
  ASSETS = 'ASSETS',
  PROJECTS = 'PROJECTS',
  EQUIPMENTS = 'EQUIPMENTS',
  PERCENT_TECHNICAL_CATEGORY_LIST = 'PERCENT_TECHNICAL_CATEGORY_LIST',
  TECHNICAL_CATEGORY_CRV_LIST = 'TECHNICAL_CATEGORY_CRV_LIST',
  UNIT_CRV_DETAIL_LIST = 'UNIT_CRV_DETAIL_LIST',
  DOCUMENT_NAMES = 'DOCUMENT_NAMES',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  FILE_NAME_HEADER = 'FILE_NAME_HEADER',
  ASSET = 'ASSET',
  USER_INFO = 'USER_INFO',
  CHECK_RESULT = 'CHECK_RESULT'
}

