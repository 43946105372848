import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { Subject } from 'rxjs';

@Component({
  template: ``
})
export class RedirectComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(private appManager: AppManager,
              private accessManager: AccessManager,
              private router: Router) {
  }

  public async ngOnInit() {
    if (this.appManager.currentOrganization !== void 0) {
      // an organization is selected, navigate to organization dashboard
      await this.router.navigate(['organization', this.appManager.currentOrganization.id], {replaceUrl: true});
    } else if (this.accessManager.currentUser.isTb) {
      // No organization selected and user isTB, navigate to tb-dashboard
      await this.router.navigate(['tb-dashboard'], {replaceUrl: true});
    } else if (this.accessManager.currentUser.clients.filter(client => client.currentSubscription != null).length > 1) {
      // Load a list of organizations for user not Tb and with multiple client
      await this.router.navigate(['organizations'], {replaceUrl: true});
    } else {
      // Load the client organization for user not is_tb
      const organizationId = this.accessManager.currentUser.clients.filter(client => client.currentSubscription != null)[0]?.organizationId;

      if (!!organizationId) {
        // Redirect to organization home
        await this.router.navigate(['organization', organizationId], {replaceUrl: true});
      } else {
        // In case the user has no active subscription, redirect to error page
        await this.router.navigate(['expired']);
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
