import { Component, Injector, OnDestroy, Type, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Event, Router, RoutesRecognized } from '@angular/router';
import { RouteData } from '@app/app.routing';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { FIELD_EXTRA_DATA } from '@app/core/model/other/field-config';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { DefaultHeaderComponent } from '@app/features/main/banner/default-header/default-header.component';
import { HeaderTemplateDirective } from '@app/features/main/banner/header-template.directive';
import { Subject } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';

export interface Breadcrumb {
  id: string,
  urls: string[];
  name: string;
  optionalParams?: Record<string, string>;
}

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnDestroy {

  public data: RouteData = <RouteData>{};
  public breadCrumbs: Breadcrumb[] = [];
  public headerType: Type<any>;
  @ViewChild(HeaderTemplateDirective, {static: true}) public headerTemplate: HeaderTemplateDirective;

  private destroy$ = new Subject<void>();

  constructor(private router: Router,
              private activationEndService: ActivationEndService,
              private analyticsService: AnalyticsService) {

    /*
     Subscribe to all events thrown by the Angular router through the common service
     */
    this.activationEndService.getRouteData()
      .pipe(
        filter((event: Event) => event instanceof RoutesRecognized),
        switchMap((routesRecognized: RoutesRecognized) => {
          // Reinitialise all the data whenever a navigation starts
          this.data = <RouteData>{};
          this.breadCrumbs = [];

          // then switch to the first activationEnd event on the primary router outlet and which corresponds to the navigationStart event
          // as is it the deepest level in the route configuration and the one which contains all pertinent breadcrumb info
          return this.router.events.pipe(
            filter((event: Event) => event instanceof ActivationEnd),
            // filter((event: ActivationEnd) => event.snapshot.outlet === 'primary'),
            filter((activationEnd: ActivationEnd) =>
              activationEnd.snapshot.outlet === 'primary' &&
              routesRecognized.state.url === activationEnd.snapshot.pathFromRoot.reduce((url: string,
                                                                                         snapshot: ActivatedRouteSnapshot) =>
                snapshot.url.length === 0 ? url : [url, ...snapshot.url].join('/'), '')
            ),
            first()
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((activationEnd: ActivationEnd) => {
        // Make sure to overwrite the hideSidenav and hideHeader values with proper booleans
        this.data = Object.assign(
          {},
          activationEnd.snapshot.data,
          {
            hideHeader: !!activationEnd.snapshot.data.hideHeader,
            hideSidenav: !!activationEnd.snapshot.data.hideSidenav,
            hideBreadcrumb: !!activationEnd.snapshot.data.hideBreadcrumb
          }
        );

        // Create breadcrumbs from the url segments in
        // the route config traversing the tree from top to bottom
        let reducedUrl = [];
        activationEnd.snapshot.pathFromRoot
          .filter((activationRoute) => {
            // As long as a url segment exists and a breadcrumb token is available
            return activationRoute.url.length > 0 && activationRoute.data.breadCrumb;
          })
          .forEach((snapshot) => {
            // Concat partial url segments together to form the full breadcrumb url
            const snapshotUrl = snapshot.url.map((url) => url.path);
            reducedUrl = reducedUrl.concat(snapshotUrl);

            this.breadCrumbs.push(<Breadcrumb>{
              id: crypto.randomUUID(),
              urls: reducedUrl,
              code: snapshot.data.breadCrumb,
              name: snapshot.data.breadCrumb
            });
          });

        activationEndService.getBreadCrumbLoadedSubject().next();

        // Unless manually hidden, generate the header component or show the header token
        if (!this.data.hideHeader && (this.data.headerComponent || this.data.title)) {
          if (this.data.headerComponent) {
            if (this.data.headerComponent !== this.headerType) {
              this.headerTemplate.viewContainerRef.clear();
              this.headerTemplate.viewContainerRef.createComponent(this.data.headerComponent);
              this.headerType = this.data.headerComponent;
            }
          } else {
            this.headerTemplate.viewContainerRef.clear();
            this.headerTemplate.viewContainerRef.createComponent(DefaultHeaderComponent, {
              injector: Injector.create({
                providers: [{
                  provide: FIELD_EXTRA_DATA,
                  useValue: {
                    headerText: this.data.title
                  }
                }]
              })
            });
            this.headerType = this.data.headerComponent;
          }
        } else {
          this.headerTemplate.viewContainerRef.clear();
        }
      });

    /**
     * When an optional param is received (an external string that doesn't belong in the route title or data), it is added to the crumb that has the same name.
     */
    this.activationEndService.getBreadCrumbSubject().subscribe(optionalParams => {
      this.breadCrumbs = this.breadCrumbs.map(crumb => {
        crumb.optionalParams = optionalParams[crumb.name];
        return crumb;
      });
    });

    this.activationEndService.getRefreshHeaderSubject().subscribe(() => {
      this.headerType = undefined;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isString(object): boolean {
    return typeof object === 'string';
  }

  public onNavigate(crumb): void {
    this.analyticsService.trackNavigationEvent(EventOriginEnum.BREADCRUMB, crumb.name);
  }
}
