import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RelatedAsset } from '@app/core/model/entities/asset/asset';
import { Equipment } from '@app/core/model/entities/equipments/equipment';
import {
  WorkCreateModalComponent
} from '@app/features/main/views/works/works-inventory/modals/work-create-modal/work-create-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './equipment-work-create-modal.component.html'
})
export class EquipmentWorkCreateModalComponent extends WorkCreateModalComponent {

  private equipment: Equipment;

  protected translate: TranslateService = inject(TranslateService);

  constructor(@Inject(MAT_DIALOG_DATA) data: { equipment: Equipment }) {
    super();
    this.equipment = data.equipment;
  }

  /**
   * Set initial Asset value so Spaces can be loaded.
   */
  public ngOnInit(): void {
    super.ngOnInit();
    this.workForm.patchValue({
      asset: new RelatedAsset(this.equipment.computedProperties?.asset?.first()),
      equipments: [this.equipment],
      spaces: this.equipment.computedProperties?.spaces,
      properties: {
        location: this.equipment.properties?.location,
        costs: (this.equipment.properties?.quantity && this.equipment.properties?.unit) ? [
          {
            quantity: this.equipment.properties.quantity,
            unit: this.equipment.properties.unit,
            unitPrice: 0
          }
        ] : [],
        technicalCategoryLevel1: this.equipment.properties?.technicalCategoryLevel1,
        technicalCategoryLevel2: this.equipment.properties?.technicalCategoryLevel2,
        technicalCategoryLevel3: this.equipment.properties?.technicalCategoryLevel3
      }
    });
  }
}
