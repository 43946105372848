import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Document } from '@app/core/model/entities/document/document';
import { Investment, InvestmentInput } from '@app/core/model/entities/investment/investment';
import { InvestmentsService } from '@app/features/main/views/investments/investment-sheet/features/investments.service';
import {
  InvestmentModalService
} from '@app/features/main/views/investments/investments-inventory/modals/investment-modal.service';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@services/file.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BaseInvestmentSheet implements OnInit {

  protected investment?: Investment;
  protected hasDocuments: boolean = false;
  protected permissionsForEdition = [PermissionEnum.EDIT_INVESTMENT];
  protected Permission = PermissionEnum;

  protected investmentsService = inject(InvestmentsService);
  protected formStateService = inject(FormStateService);
  protected translate: TranslateService = inject(TranslateService);
  protected snackbarManager = inject(SnackbarManager);
  protected documentsService = inject(DocumentsService);
  protected appManager = inject(AppManager);
  protected analyticsService = inject(AnalyticsService);
  protected accessManager = inject(AccessManager);
  protected investmentModalService = inject(InvestmentModalService);
  protected fileService = inject(FileService);

  protected destroyRef = inject(DestroyRef);

  /**
   * Listen for inputs and update Investment entity when necessary.
   */
  public ngOnInit(): void {
    this.formStateService.saved$.pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap(({code, data}) => {
        return this.investmentsService.updateInvestment(this.investment, data as InvestmentInput).pipe(
          tap(updatedInvestment => {
            this.investment = updatedInvestment;
            // Force computed fields to recalculate
            this.formStateService.getPath(['computed']).next();
            // Release the SAVING state to the next state
            this.formStateService.getPath([code, 'state']).next(FieldStateMode.AFTER_SAVE);
          }),
          // Resubscribe to the saved observable if an error occurred.
          catchError(() => {
            this.formStateService.getPath([code, 'state']).next(FieldStateMode.ERROR);
            return this.formStateService.saved$;
          })
        );
      }),
    )
      .subscribe(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EDIT_SAVED')));
  }

  /**
   * Load the Documents linked to the given Investment.
   * @param investment The Investment from which to load the Documents.
   * @returns An Observable emitting the Investment with its Documents loaded.
   */
  protected loadInvestmentDocuments(investment: Investment): Observable<Investment> {
    return this.documentsService.loadEntityDocuments(
      investment.id,
      EntityTypeEnum.INVESTMENT,
      DocumentTypeEnum.INVESTMENT_DOCUMENT
    ).pipe(
      map(documents => {
        this.hasDocuments = documents.length > 0;
        investment.documents = documents;
        return investment;
      })
    );
  }

  /**
   * Open a dialog to upload Documents and link updated Documents to the Investment entity.
   */
  public uploadInvestmentDocuments(): void {
    this.investmentModalService.openUploadInvestmentDocumentsDialog(this.investment.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(documents => {
        this.hasDocuments = documents.length > 0;
        this.investment.documents.push(...documents);
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_UPLOADED'));
      });
  }

  /**
   * Open a dialog asking for confirmation to delete a document linked to the Investment entity.
   * @param document Document to delete.
   */
  public deleteInvestmentDocument(document: Document): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SHEET,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.DOCUMENT,
      document.id
    );
    this.investmentModalService.openDeleteInvestmentDocumentDialog(document)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(result => result)
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_DELETED'));
        this.investment.documents = this.investment.documents.filter(doc => doc.id != document.id);
        this.hasDocuments = this.investment.documents.length > 0;
      });
  }
}
