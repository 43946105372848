
@switch (state) {
  @case ('accepted') {
    <span>{{ 'VALUE.INVITATION_ACCEPTED' | translate }}</span>
  }
  @case ('none') {
    <span>{{ 'VALUE.INVITATION_NONE' | translate }}</span>
  }
  @default {
    <div>
      <span>{{ 'VALUE.INVITATION_SENT' | translate }}</span>
      @if (client !== undefined) {
        <restricted-icon-button (onClick)="onClickResend($event)"
          [class]="'mdi mdi-refresh sm'"
          [matTooltip]="'TOOLTIP.RESEND_INVITATION' | translate"
          [permissions]="[Permission.MANAGE_USER]"
          [disabled]="state === 'sending'"
          [spin]="state === 'sending'"
          [preconditions]="state === 'pending_resend'">
        </restricted-icon-button>
      }
    </div>
  }
}
