<div id="dialog-container" class="fx-layout-col" [cdkTrapFocus]="true">
  <mat-dialog-content class="fx-flex-auto fx-layout-col fx-justify-center fx-align-center">
    <i class="mdi lg m-b-10" [ngClass]="iconName" [style.color]="colour"></i>
    <h3 id="title" class="modal-title">{{ title }}</h3>
    <div id="message" class="modal-message">
      @if (code) {
        <div>
          <span>{{ "LABEL.ERROR_CODE" | translate }}</span>
          <span class="error-code">{{ code }}</span>
        </div>
      }
      <div>{{ message }}</div>
    </div>
    @if (stackTrace) {
      <pre class="error-stack-trace">{{ stackTrace }}</pre>
    }
  </mat-dialog-content>
  <mat-dialog-actions>
    <a mat-stroked-button href="https://tbmaestro.atlassian.net/servicedesk" target="_blank">
      <mat-icon>open_in_new</mat-icon>
      {{ 'BUTTON.CONTACT_US' | translate }}
    </a>
    <button mat-stroked-button (click)="onClickLogout()">
      <mat-icon>logout</mat-icon>
      {{ 'BUTTON.LOGOUT' | translate }}
    </button>
    <button mat-raised-button matDialogClose="yes" color="accent" id="dialog-ok-button">{{ okText }}</button>
  </mat-dialog-actions>
</div>
