<div class="accordion-sheet-container fx-fill">
  <mat-accordion multi displayMode="flat" togglePosition="before">
    <mat-expansion-panel #occupantPanel class="no-shadow"
                         (closed)="makeReadOnly(true)"
                         (afterExpand)="makeReadOnly(false)"
                         (afterCollapse)="makeReadOnly(true)">
      <mat-expansion-panel-header class="fx-layout-row-reverse fx-justify-start fx-align-baseline">
        <mat-panel-title class="fx-layout-row fx-justify-start fx-align-baseline">
          <div class="sheet-title fx-flex-85-percent">
            <span>{{ entity?.occupant?.toString() }}</span>
          </div>
          <div class="fx-flex-48">
            <div class="btn-container">
              <button (click)="closeSidePanel($event)"
                      [disableRipple]="true"
                      (keydown)="preventToggle($event)"
                      id="close-sheet-button"
                      mat-icon-button>
                <i class="mdi mdi-close sm"></i>
              </button>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="fx-layout-col">
        <div class="sidebar-sheet fx-flex-grow" cdk-scrollable>
          <div class="page-sidebar">
            <div class="form-container">
              @if (occupant) {
                <form-builder
                  [formId]="occupantFormId"
                  [displayHeader]="false"
                  [eventsOrigin]="eventsOrigin"
                  [permissionsForEdition]="permissionsForEdition">
                </form-builder>
              }
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel #leasePanel class="no-shadow">
      <mat-expansion-panel-header class="fx-layout-row-reverse fx-justify-start fx-align-baseline">
        <mat-panel-title class="sheet-title">
          <span>{{ 'LABEL.LEASE' | translate }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="fx-layout-col">
        <div class="sheet-header">
          <div class="fx-layout-row">
            <restricted-icon-button (onClick)="uploadLeaseDocuments()"
                                    [class]="'mdi mdi-paperclip sm'"
                                    [matTooltip]="'BUTTON.ATTACH_FILE' | translate"
                                    [permissions]="[Permission.EDIT_OCCUPANT, Permission.CREATE_DOCUMENT]">
            </restricted-icon-button>

            <restricted-icon-button (onClick)="renewLease()"
                                    [id]="'renew-lease-button'"
                                    [class]="'mdi mdi-autorenew sm'"
                                    [matTooltip]="'BUTTON.RENEW' | translate"
                                    [permissions]="[Permission.CREATE_OCCUPANT]">
            </restricted-icon-button>

            <restricted-icon-button (onClick)="deleteLease()"
                                    [id]="'delete-lease-button'"
                                    [class]="'mdi mdi-delete sm'"
                                    [matTooltip]="'BUTTON.DELETE' | translate"
                                    [permissions]="[Permission.DELETE_OCCUPANT]">
            </restricted-icon-button>

            @if (displayAssetSheetLink) {
              <span class="fx-flex"></span>
            }

            @if (displayAssetSheetLink) {
              <div class="asset-sheet-link">
                <span (click)="navigateToAssetSheet()"> {{ 'BUTTON.SHOW_ASSET_SHEET' | translate }} </span>
              </div>
            }
          </div>
        </div>
        <div class="sidebar-sheet fx-flex-grow" cdk-scrollable>
          <div class="page-sidebar">
            <div class="form-container">
              @if (entity) {
                <form-builder
                  [formId]="formId"
                  [displayHeader]="false"
                  [permissionsForEdition]="permissionsForEdition"
                  [fieldsToHide]="fieldsToHide">
                </form-builder>
              }
            </div>
            <div class="document-title fx-layout-row fx-justify-start fx-align-center">
              <span class="fx-flex-30-percent"> {{ 'LABEL.DOCUMENT' | translate }} </span>
              <restricted-text-button
                (onClick)="uploadLeaseDocuments()"
                [id]="'lease-upload-document-button'"
                [matTooltip]="'BUTTON.ATTACH_FILE' | translate"
                [permissions]="[Permission.EDIT_OCCUPANT, Permission.CREATE_DOCUMENT]"
                class="btn-upload-document">
                {{ 'BUTTON.ATTACH_FILE' | translate }}
              </restricted-text-button>
            </div>
            @for (document of entity?.documents; track document) {
              <div class="document-content">
                @if (accessManager.hasAccess(Permission.EXPORT_DOCUMENT)) {
                  <a (click)="downloadEntityDocument(document.id)">
                    <i class="mdi mdi-file-outline xs"></i>
                    <span class="file-name-label">{{ document.name }}</span>
                  </a>
                } @else {
                  <span class="file-name-label">{{ document.name }}</span>
                }
                <span (click)="deleteLeaseDocument(document)"
                      [permission-lock]="[Permission.EDIT_OCCUPANT, Permission.DELETE_DOCUMENT]"
                      class="delete-document-icon">
                  <i class="mdi mdi-delete xs"></i>
                </span>
              </div>
            }
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
