import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { AccessManager } from '@app/core/services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';

/**
 * Check whether a route can be activated based on the required permissions in the route's data. The current User
 * must have all permissions granted in order to activate the route.
 * @param route Activated route.
 * @return True if the route can be activated, or an UrlTree to the forbidden error page otherwise.
 */
export default function authorizationGuard(route: ActivatedRouteSnapshot): boolean | UrlTree {
  const permissions = route.data['permissions'] as PermissionEnum[];
  return permissions?.every(permission =>
      inject(AccessManager).hasAccess(permission, !inject(AppManager).currentOrganization)
  ) || inject(Router).parseUrl('error/forbidden');
}
