import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './error-subscription-expired.component.html'
})
export class ErrorSubscriptionExpiredComponent implements OnInit {
  private title = inject(Title);
  private translate = inject(TranslateService);

  public authService = inject(AuthService);

  /**
   * Set page's title.
   */
  public ngOnInit(): void {
    this.title.setTitle(this.translate.instant('TITLE.SUBSCRIPTION_EXPIRED') + ' - myA');
  }

  /**
   * Sign out user.
   */
  public logout(): void {
    this.authService.logout().subscribe();
  }
}
