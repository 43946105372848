import { DocumentStateEnum } from '@app/core/enums/document/document-state.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { LegacyRelatedAsset, RelatedAsset } from '@app/core/model/entities/asset/asset';
import { Entity } from '@app/core/model/entities/entity';
import { getValue, setValue } from '@app/shared/extra/utils';
import { Expose, Transform, Type } from 'class-transformer';
import { basename, extname } from 'path-browserify';

export class Document extends Entity {
  public objectType = EntityTypeEnum.DOCUMENT;

  @Expose({name: 'assetsList'})
  @Type(() => LegacyRelatedAsset) public assets: LegacyRelatedAsset[];

  public path: string;
  public size: number;
  public hash: string;
  public mimeType: string;

  @Transform(fn => DocumentTypeEnum[fn.value]) public documentType: DocumentTypeEnum;
  @Transform(fn => DocumentStateEnum[fn.value]) public documentState: DocumentStateEnum;

  @Expose()
  public toString(): string {
    return this.name;
  }

  public getNameWithoutExtension(): string {
    return basename(this.name, this.getExtension());
  }

  public getExtension(): string {
    return extname(this.name);
  }

  /**
   * Whether the file's name is valid.
   * @param fileName File's name.
   * @return True if the name is valid, false otherwise.
   */
  public static validFileName(fileName: string): boolean {
    return /^[a-zA-Z0-9À-ÖØ-öø-ÿ\s.\-_()']*$/.test(fileName);
  }

  // Properties to be renamed
  protected static inputMapping = {
    assets: {input: ['assets'], output: 'assetId', transform: (asset: RelatedAsset): string => asset?.id}
  };

  /**
   * Some GraphQL inputs require different keys than those available in the Form State Service
   * since those keys correspond to field codes, so we need to replace them accordingly
   * @param obj the Graphql input object
   */
  public static transformPropertiesForInput(obj: Record<string, unknown>): void {
    // FIXME TTT-3386 document asset property
    for (const inputMappingElement in Document.inputMapping) {
      if (obj[inputMappingElement] !== void 0) {
        const source = getValue(obj, Document.inputMapping[inputMappingElement].input);
        setValue(
          obj,
          [Document.inputMapping[inputMappingElement].output],
          Document.inputMapping[inputMappingElement].transform(source)
        );
        delete obj[inputMappingElement];
      }
    }
  }

}

export class PresignedUrl {
  public fileName: string;
  public documentName: string;
  public url: string;
}

export class DocumentToCreateFromFile {
  constructor(fileName: string, documentName: string) {
    this.fileName = fileName;
    this.document = new Document();
    this.document.name = documentName;
  }

  public fileName: string;
  public document: Document;
}

export interface CreateDocumentInput {
  documentName: string;
  fileName: string;
  documentType: DocumentTypeEnum;
  documentState: DocumentStateEnum;
  properties?: Record<string, any>;
  assetIds?: string[];
}

export interface DocumentInput {
  name?: string;
  documentType?: string;
  documentState?: string;
  assetId: string;
}
