<div class="error-box">
  <div class="error-image"></div>
  <div class="error-body">
    <h2>{{ 'TITLE.PAGE_NOT_FOUND' | translate }}</h2>
    <p>{{ 'MESSAGE.PAGE_NOT_FOUND' | translate }}</p>
    <button mat-raised-button color="accent" routerLink='/' class="pull-left">
      {{ 'BUTTON.HOME' | translate }}
    </button>
  </div>
</div>
