import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { CheckType } from '@app/core/model/entities/asset/check-type';
import { CheckTypeService } from '@app/shared/services/check-type.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'check-result-cell',
  templateUrl: './check-result-cell.component.html',
  styleUrls: ['./check-result-cell.component.scss']
})
export class CheckResultCellComponent implements ICellRendererAngularComp, OnInit, OnDestroy {

  protected params: ICellRendererParams;
  protected checkTypeCode: string;
  protected checkResult: string;
  protected climateLabel: string;
  protected powerLabel: string;
  protected readonly emptyValue = inject(AppConfig).EMPTY_FIELD_VALUE;
  protected checkTypes: { [k: string]: CheckType };
  protected destroy$ = new Subject<void>();

  private checkTypeService = inject(CheckTypeService);

  public ngOnInit(): void {
    this.checkTypeService.getCheckTypesObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(checkTypes => this.checkTypes = checkTypes);
  }

  /**
   * Method used by Ag-grid to initialise the cell with grid parameters
   * There are two cases for the cell : the first one is when we receive an array of assetChecks (master grid) and the second is when we receive a simple check (detail grid),
   * so we need to check if the data received has one or more elements.
   * @param params
   */
  public agInit(params: ICellRendererParams): void {
    this.params = params;
    if (!params.value) return;

    const {type, value} = (params.node.group)
      // When the row is a group, the data is store as JSON string and is an object containing the check type and the check result.
      // Ex: {type: 'dpe', value: 'A,D'} or {type: 'electricity', value: 'Conformed'}
      ? JSON.parse(params.value)
      // In ungroup row, fetch the check type from the row data.
      : {type: params.data.type, value: params.value};
    this.checkTypeCode = type;

    if (this.checkTypeCode === 'dpe') {
      this.climateLabel = value.climateLabel;
      this.powerLabel = value.powerLabel;
    } else {
      this.checkResult = value.checkResult;
    }
  }

  /**
   * Method used by Ag-Grid to tell the grid to refresh this cell
   * This component does not include refresh logic
   * @returns {boolean}
   */
  public refresh(_: ICellRendererParams): boolean {
    return false;
  }

  public ngOnDestroy(): void {
    // Prevent memory leak
    this.destroy$.next();
    this.destroy$.complete();
  }
}
