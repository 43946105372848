import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';

@Component({
  templateUrl: './create-dashboard-modal.component.html'
})
export class CreateDashboardModalComponent implements OnInit {

  public dashboardForm: FormGroup<{
    name: FormControl<string>
  }>;

  constructor(private apiService: ApiService,
              private fb: UntypedFormBuilder,
              private appManager: AppManager,
              public appConfig: AppConfig,
              @Inject(MAT_DIALOG_DATA) public entityType: EntityTypeEnum) {
  }

  /**
   * Initialize form and set validators
   */
  public ngOnInit(): void {
    this.dashboardForm = this.fb.group({
      name: this.fb.control('', Validators.compose([
        Validators.required,
        Validators.maxLength(this.appConfig.NAME_MAX_LENGTH)
      ]))
    });
    this.dashboardForm.get('name').setAsyncValidators(
      ExtraValidators.isValueTaken(
        this.apiService,
        EntityTypeEnum.DASHBOARD,
        'name',
        '',
        {organizationId: this.appManager.currentOrganization.id, entityType: this.entityType}
      )
    );
  }

  /**
   * The object generated by the popup after it is closed
   * @return the response from the popup
   */
  public getGeneratedObject(): any {
    return {
      dashboardName: this.dashboardForm.get('name').value
    };
  }

}
