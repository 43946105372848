<div class="error-box">
  <div class="error-image"></div>
  <div class="error-body">
    <h2>{{ 'TITLE.SUBSCRIPTION_EXPIRED' | translate }}</h2>
    <p>{{ 'MESSAGE.SUBSCRIPTION_EXPIRED' | translate }}</p>

    <button mat-raised-button color="accent" routerLink='/' class="m-r-20 pull-left" (click)="logout()">
      {{ 'BUTTON.LOGOUT' | translate }}
    </button>
    <a mat-stroked-button
       href="https://tbmaestro.atlassian.net/servicedesk"
       target="_blank"
       class="m-r-20 pull-left">
      <mat-icon>open_in_new</mat-icon>
      {{ 'BUTTON.CONTACT_US' | translate }}
    </a>
  </div>
</div>
