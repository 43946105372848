import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivationEnd, Event, NavigationStart, Router } from '@angular/router';
import { RouteData } from '@app/app.routing';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { UserRoleEnum } from '@app/core/enums/user-role-enum';
import { Document } from '@app/core/model/entities/document/document';
import { RetoolApp } from '@app/core/model/entities/retool/retool-app';
import { Notification } from '@app/core/model/other/notification';
import { AccessManager } from '@app/core/services/managers/access.manager';
import { AppManager } from '@app/core/services/managers/app.manager';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { SidenavService } from '@app/features/main/sidenav.service';
import { OrganizationLogoService } from '@app/features/main/views/dashboard/organization-logo.service';
import { PermissionAware } from '@app/shared/decorators/permissions-awareness.decorator';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { ChangeService } from '@services/change.service';
import { FileService } from '@services/file.service';
import { RetoolAppService } from '@services/retool-app.service';
import { merge, Observable, repeat, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
@PermissionAware
export class MenubarComponent implements OnInit, OnDestroy {
  @ViewChild('toolBarOrigin', {static: true}) protected toolBarRef: CdkOverlayOrigin = null;
  @ViewChild('rights') protected rightsTooltip: MatTooltip;
  @ViewChild('org') protected orgTooltip: MatTooltip;

  protected Permission = PermissionEnum;
  protected logo: string;
  protected data: RouteData;
  protected organizationLogo: Document;
  protected retoolApps$: Observable<RetoolApp[]>;
  protected notifications: Notification[];

  protected readonly UserRoleEnum = UserRoleEnum;

  protected homePage$: Observable<string>;
  private destroy$ = new Subject<void>();

  protected authService = inject(AuthService);
  protected appConfig = inject(AppConfig);
  protected appManager = inject(AppManager);
  protected accessManager = inject(AccessManager);
  private router = inject(Router);
  private organizationLogoService = inject(OrganizationLogoService);
  private sidenavService = inject(SidenavService);
  private activationEndService = inject(ActivationEndService);
  private analyticsService = inject(AnalyticsService);
  private fileService = inject(FileService);
  private translate = inject(TranslateService);
  private changeService = inject(ChangeService);
  private retoolAppService = inject(RetoolAppService);

  constructor() {

    /*
     Subscribe to all events thrown by the Angular router through the common service
     */
    this.activationEndService.getRouteData()
      .pipe(
        filter((event: Event) => event instanceof NavigationStart),
        switchMap(() => {
          this.data = <RouteData>{};

          return this.router.events.pipe(
            filter((event: Event) => event instanceof ActivationEnd),
            filter((event: ActivationEnd) => event.snapshot.outlet === 'primary'),
            first()
          );
        }),
        takeUntil(this.destroy$),
        switchMap(
          (activationEnd: ActivationEnd) => {
            // Make sure to overwrite the hideSidenav and hideHeader values with proper booleans
            this.data = Object.assign(
              {},
              activationEnd.snapshot.data,
              {
                hideHeader: !!activationEnd.snapshot.data.hideHeader,
                hideSidenav: !!activationEnd.snapshot.data.hideSidenav
              }
            );

            //Manually add tooltips message if it wasn't set yet
            if (this.rightsTooltip && !this.rightsTooltip.message) {
              this.rightsTooltip.message = this.translate.instant('TOOLTIP.MANAGE_RIGHTS');
            }
            if (this.orgTooltip && !this.orgTooltip.message) {
              this.orgTooltip.message = this.translate.instant('TOOLTIP.CONFIGURATION');
            }

            // Get the organization current logo
            return this.organizationLogoService.loadOrganizationLogo()
              .pipe(
                takeUntil(this.destroy$),
                tap((response) => {
                  this.organizationLogo = response;
                  this.setLogo(this.organizationLogo);
                })
              );
          }
        )
      ).subscribe();
  }

  /**
   * Initialize Observables
   */
  public ngOnInit(): void {
    this.homePage$ = this.appManager.organization$.pipe(
      takeUntil(this.destroy$),
      map(organization => organization ? `/organization/${organization.id}` : '/')
    );

    this.organizationLogoService.organizationLogo$
      .pipe(takeUntil(this.destroy$))
      .subscribe(logo => {
        this.organizationLogo = logo;
        this.setLogo(this.organizationLogo);
      });

    this.notifications = <Notification[]>[];

    this.changeService.entityChangedSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setLogo(this.organizationLogo);
    });

    this.retoolApps$ = this.appManager.organization$.pipe(
      takeUntil(this.destroy$),
      filter(organization => !!organization),
      switchMap(() => this.retoolAppService.retoolApps$.pipe(
        // Refresh app list whenever an app is added, updated or deleted by the user or when the user hover the menu
        repeat({
          delay: () => merge(
            this.retoolAppService.retoolAppAdded$,
            this.retoolAppService.retoolAppUpdated$,
            this.retoolAppService.retoolAppDeleted$
          )
        })
        )
      )
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Log out the current User then redirect back to myA home page.
   */
  public logout(): void {
    this.accessManager.logout().subscribe();
  }

  /**
   * Toggle the sidenav's state.
   */
  public toggleSideNav(): void {
    this.sidenavService.toggleSidenav();
  }

  public onNavigation(navigateTo: string): void {
    this.analyticsService.trackNavigationEvent(EventOriginEnum.MENUBAR, navigateTo);
  }

  public get organizationName(): string {
    if (this.appManager.currentOrganization) {
      return this.appManager.currentOrganization.name;
    }
    return '';
  }

  public setLogo(document: Document): void {
    if (this.organizationLogo) {
      this.fileService.getDocumentFileUrl(document.id).subscribe((documentUrl) => {
        this.logo = documentUrl;
      });
    } else {
      this.logo = null;
    }
  }
}
