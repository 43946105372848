import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { inject, NgModule } from '@angular/core';
import { InMemoryCache, split } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { authHttpInterceptorFn, AuthService, provideAuth0 } from '@auth0/auth0-angular';
import { GenericError } from '@auth0/auth0-spa-js/src/errors';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import packageJson from '@root/package.json';
import { PopupManager } from '@services/managers/popup.manager';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient } from 'graphql-ws';
import { firstValueFrom } from 'rxjs';

@NgModule({
  providers: [
    // Authentication
    provideAuth0({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0.audience
      },
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      errorPath: '/unauthorized',

      // Specify configuration for the interceptor
      httpInterceptor: {
        allowedList: [
          {uri: environment.backend.baseUrl + '/*'}
        ]
      }
    }),
    provideHttpClient(
      withInterceptors([authHttpInterceptorFn])
    ),
    // Apollo (GraphQL API)
    provideApollo(() => {
      const httpLink = inject(HttpLink);
      const authService = inject(AuthService);
      const translate = inject(TranslateService);
      const popupManager = inject(PopupManager);

      const errorLink = onError(({graphQLErrors, networkError}) => {
        if (networkError) {
          if (networkError instanceof GenericError) {
            // Ignore Auth0 errors, as they are handled independently
            return;
          }
          popupManager.showErrorPopup({
            dialogTitle: translate.instant('TITLE.API_ERROR'),
            dialogMessage: networkError.message
          });
        } else if (graphQLErrors && graphQLErrors[0]) {
          popupManager.showErrorPopup({
            dialogTitle: translate.instant('TITLE.API_ERROR'),
            dialogMessage: graphQLErrors[0].message,
            code: graphQLErrors[0].extensions.code,
            stackTrace: graphQLErrors[0].extensions.stackTrace
          });
        } else {
          popupManager.showErrorPopup({
            dialogTitle: translate.instant('TITLE.API_ERROR'),
            dialogMessage: translate.instant('MESSAGE.API_ERROR')
          });
        }
      });

      // Create HTTP link
      // Note: authentication handled by Auth0 HTTP interceptor
      const http = httpLink.create({
        uri: environment.backend.baseUrl + environment.backend.graphql.endpoint
      });

      // Create a WebSocket link
      // Note: HTTP interceptor won't intercept Websocket calls, so token is inserted in connection_init payload
      const ws = new GraphQLWsLink(
        createClient({
          url: environment.backend.websocketUrl + environment.backend.graphql.endpoint,
          connectionParams: async () => {
            const authToken = await firstValueFrom(authService.getAccessTokenSilently());
            return {Authorization: `Bearer ${authToken}`};
          }
        })
      );

      // Merge links and dispatch queries
      const link = split(
        // Split based on operation type
        ({query}) => {
          const definition = getMainDefinition(query);
          return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION;
        },
        ws, // Subscriptions go to the Websocket link
        http // Queries and mutations go to the HTTP link
      );

      return {
        cache: new InMemoryCache({
          typePolicies: {
            'User': {
              keyFields: ['id']
            },
            'UserInfo': {
              keyFields: ['id'],
            },
            'Client': {
              keyFields: ['id']
            },
            'ClientSubscription': {
              keyFields: ['id']
            },
            'ClientSubscriptionModule': {
              keyFields: ['id']
            },
            'Group': {
              keyFields: ['id']
            },
            'UserRole': {
              keyFields: ['id']
            },
            'Section': {
              keyFields: ['code']
            },
            'GridConfig': {
              keyFields: ['code']
            },
            'Field': {
              keyFields: ['code', 'entityType']
            },
            'Asset': {
              keyFields: ['id']
            },
            'Work': {
              keyFields: ['id']
            },
            'Equipment': {
              keyFields: ['id']
            },
            'Project': {
              keyFields: ['id']
            },
            Query: {
              fields: {
                userInfo: {
                  // Use the Apollo Client field policy to read an individual item from the cache
                  read(_, {args, toReference}) {
                    return toReference({
                      __typename: 'UserInfo',
                      id: args.id,
                    });
                  },
                },
              },
            },
          }
        }),
        link: errorLink.concat(link),
        name: packageJson.name,
        version: packageJson.version,
        defaultOptions: {
          query: {
            errorPolicy: 'all', // Gets both data and errors in case of error
            fetchPolicy: 'no-cache'
          },
          mutate: {
            errorPolicy: 'none',
            fetchPolicy: 'network-only'
          }
        },
        devtools: {
          enabled: !environment.production
        }
      };
    })
  ]
})
export class ApiModule {
}
