import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_GROUP_CONFIG_INJECTION,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldGroup
} from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { AbstractFieldGroupBuilder, FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SingleAssetMapComponent } from '@app/shared/components/map/single-asset-map.component';
import { getLocale } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import PostalAddress from 'i18n-postal-address';
import { FormatTypes } from 'i18n-postal-address/dist/types/address-format';
import { LatLng } from 'leaflet';
import { merge, ReplaySubject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'address-field-builder',
  templateUrl: './address-field-builder.component.html',
  styleUrls: ['./address-field-builder.component.scss']
})
export class AddressFieldBuilderComponent extends AbstractFieldGroupBuilder implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;

  public addressOutput: PostalAddress;
  public form: FormGroup<{
    streetNumber: FormControl<string>,
    street: FormControl<string>,
    postalCode: FormControl<string>,
    city: FormControl<string>,
    country: FormControl<string>,
    latitude: FormControl<string>,
    longitude: FormControl<string>
  }>;

  public completeAddress: {
    streetNumber?: string,
    street?: string,
    postalCode?: string,
    city?: string,
    country?: string,
    latitude?: string,
    longitude?: string
  };

  @ViewChild(SingleAssetMapComponent, {static: false}) public map: SingleAssetMapComponent;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_GROUP_CONFIG_INJECTION) fieldGroup: FieldGroup,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              singleEditService: SingleEditService,
              appManager: AppManager,
              accessManager: AccessManager,
              appConfig: AppConfig,
              analyticsService: AnalyticsService,
              media: MediaMatcher,
              protected validationService: ValidationService,
              private translate: TranslateService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldGroup,
      preconditionsForEdition,
      permissionsForEdition,
      accessManager,
      appConfig,
      appManager,
      singleEditService,
      analyticsService,
      media
    );
    this.addressOutput = new PostalAddress();
  }

  public ngOnInit(): void {
    this.setFieldGroupInfo([], {
      state: new ReplaySubject<FieldStateMode>(1),
      isSingleField: true,
      value: {},
      initialValue: {}
    });
    this.initForm();
    this.setAddressFormat();
  }

  public initForm(): void {
    this.completeAddress = {
      streetNumber: this.entity.properties['streetNumber'],
      street: this.entity.properties['street'],
      postalCode: this.entity.properties['postalCode'],
      city: this.entity.properties['city'],
      country: this.entity.properties['country'],
      latitude: this.entity.properties['latitude'],
      longitude: this.entity.properties['longitude']
    };

    this.form = new FormGroup({
      streetNumber: new FormControl(''),
      street: new FormControl(''),
      postalCode: new FormControl(''),
      city: new FormControl(''),
      country: new FormControl(''),
      latitude: new FormControl(''),
      longitude: new FormControl('')
    });

    const fieldsConfig = this.fieldGroup.fieldConfigs;
    this.form.get('streetNumber')
      .setValidators(fieldsConfig[0].field?.validators.map(validator => {
        return this.validationService.getValidator(validator, this.entity);
      }));
    this.form.get('street')
      .setValidators(fieldsConfig[1].field?.validators.map(validator => {
        return this.validationService.getValidator(validator, this.entity);
      }));
    this.form.get('postalCode')
      .setValidators(fieldsConfig[2].field?.validators.map(validator => {
        return this.validationService.getValidator(validator, this.entity);
      }));
    this.form.get('city')
      .setValidators(fieldsConfig[3].field?.validators.map(validator => {
        return this.validationService.getValidator(validator, this.entity);
      }));
    this.form.get('country')
      .setValidators(fieldsConfig[4].field?.validators.map(validator => {
        return this.validationService.getValidator(validator, this.entity);
      }));
    this.form.get('latitude')
      .setValidators(fieldsConfig[5].field?.validators.map(validator => {
        return this.validationService.getValidator(validator, this.entity);
      }));
    this.form.get('longitude')
      .setValidators(fieldsConfig[6].field?.validators.map(validator => {
        return this.validationService.getValidator(validator, this.entity);
      }));

    // Initial values
    this.form.get('streetNumber')
      .setValue(this.entity.properties[fieldsConfig[0].fieldCode] ?? '');
    this.form.get('street')
      .setValue(this.entity.properties[fieldsConfig[1].fieldCode] ?? '');
    this.form.get('postalCode')
      .setValue(this.entity.properties[fieldsConfig[2].fieldCode] ?? '');
    this.form.get('city')
      .setValue(this.entity.properties[fieldsConfig[3].fieldCode] ?? '');
    this.form.get('country')
      .setValue(this.entity.properties[fieldsConfig[4].fieldCode] ?? '');
    this.form.get('latitude')
      .setValue((this.entity.properties[fieldsConfig[5].fieldCode] ?? '').toString().replace('.', ','));
    this.form.get('longitude')
      .setValue((this.entity.properties[fieldsConfig[6].fieldCode] ?? '').toString().replace('.', ','));

    // JSON parse and stringify are used to perform a deep copy of the address
    this.setFieldGroupInitialValue(JSON.parse(JSON.stringify(this.completeAddress)));
    this.setFieldGroupValue(JSON.parse(JSON.stringify(this.completeAddress)));
    this.getNextState();

    merge(
      this.form.get('streetNumber').valueChanges,
      this.form.get('street').valueChanges,
      this.form.get('city').valueChanges,
      this.form.get('postalCode').valueChanges,
      this.form.get('country').valueChanges
    )
      .pipe(debounceTime(50), takeUntil(this.destroy$))
      .subscribe(() => {
        this.completeAddress = this.form.value;
      });
  }

  public ngAfterViewInit(): void {
    this.setupHooks();

    this.getFieldGroupState().pipe(takeUntil(this.destroy$)).subscribe((newMode) => {
      if (newMode === FieldStateMode.AFTER_SAVE) {
        this.refreshEntity();

        this.completeAddress = {
          streetNumber: this.entity.properties['streetNumber'],
          street: this.entity.properties['street'],
          postalCode: this.entity.properties['postalCode'],
          city: this.entity.properties['city'],
          country: this.entity.properties['country'],
          latitude: this.entity.properties['latitude'],
          longitude: this.entity.properties['longitude']
        };

        // JSON parse and stringify are used to perform a deep copy of the address
        this.setFieldGroupInitialValue(JSON.parse(JSON.stringify(this.completeAddress)));
        this.setFieldGroupValue(JSON.parse(JSON.stringify(this.completeAddress)));

        this.getNextState();
      } else {
        this.currentMode = newMode;
      }
    });
  }

  /**
   * Updates the Lat/Lng fields in the form from the event data fired by the map component
   * @param {LatLng} event
   */
  public updateLatLong(event: LatLng): void {
    this.form.get('latitude').setValue(this.formatCoordinateDisplay(event?.lat));
    this.form.get('longitude').setValue(this.formatCoordinateDisplay(event?.lng));
  }

  /**
   * Prevents typing unwanted characters in a numeric field
   * @param {InputEvent} event
   * @returns {boolean}
   */
  public validateKeypressEvent(event: InputEvent): boolean {
    const input = event.data;
    const pattern = /[0-9\-.,]/;
    return event.inputType != 'insertText' || this.validateInput(input, pattern);
  }

  /**
   * Prevents pasting unwanted characters in a numeric field
   * @param {ClipboardEvent} event
   * @returns {boolean}
   */
  public validatePasteEvent(event: ClipboardEvent): boolean {
    const input = event.clipboardData.getData('text');
    const pattern = /^(?:-?\d+|-?\d{1,3}(?:\s?\d{3})+)?(?:[,.]\d+)?$/;
    return this.validateInput(input, pattern);
  }

  public save(): void {
    if (this.form.get('latitude').value == '' || this.form.get('longitude').value == '') {
      //Calculate coordinates automatically
      this.map.updateAddressCoordinates();
      this.map.positionUpdated.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
        this.performSave();
      });
    } else {
      this.performSave();
    }
  }

  public performSave(): void {
    this.setFieldGroupValue({
      streetNumber: this.form.get('streetNumber').value,
      street: this.form.get('street').value,
      postalCode: this.form.get('postalCode').value,
      city: this.form.get('city').value,
      country: this.form.get('country').value,
      latitude: this.formatCoordinateSave(this.form.get('latitude').value),
      longitude: this.formatCoordinateSave(this.form.get('longitude').value)
    });
    this.setAddressFormat();
    super.save();
  }

  public cancel(): void {
    this.initForm();
  }

  // TODO TTT-4135 Remove, iterate over errors instead of validators
  /**
   * List of validators for a certain address field for which an error has been raised by the corresponding ValidatorFn.
   * @param field Field's code.
   * @return List of FieldValidators.
   */
  public getErroredValidators(field: string): FieldValidator[] {
    const fieldConfig = this.fieldGroup.fieldConfigs.find(fieldConfig => fieldConfig.code === field);
    return fieldConfig?.field?.validators?.filter(validator => !!this.form.get(field).errors?.[validator.code])
      ?? [];
  }

  /**
   * Updates the address format according to the country.
   * @private
   */
  private setAddressFormat(): void {
    const locale = getLocale().substring(0, 2);
    this.addressOutput.setFormat({
      country: this.form.get('country').value || this.appConfig.DEFAULT_ADDRESS_FORMAT,
      type: <FormatTypes>{'en': 'english', 'fr': 'french'}[locale] || 'default',
      useTransforms: false
    });
  }

  /**
   * Turns the address object into a humanized format to display
   * @returns {string}
   */
  public formatAddress(): string {
    if (this.form.get('streetNumber').value) {
      this.addressOutput.setAddress(this.form.get('streetNumber').value + ', ' + this.form.get('street').value);
    } else {
      this.addressOutput.setAddress(this.form.get('street').value);
    }
    this.addressOutput.setCity(this.form.get('city').value);
    this.addressOutput.setPostalCode(this.form.get('postalCode').value);

    if (this.form.get('country').value) {
      this.addressOutput.setCountry(this.translate.instant(`VALUE.COUNTRY_${this.form.get('country').value}`));
    }

    return this.addressOutput.output('array').map(part => part.join(' ')).join(', ');
  }

  private validateInput(input: string, pattern: RegExp): boolean {
    return pattern.test(input);
  }

  private formatCoordinateDisplay(coordinate: any): string {
    return ((coordinate) ? coordinate.toString().replace('.', ',') : '');
  }

  private formatCoordinateSave(coordinate: string): string {
    return ((coordinate) ? coordinate.replace(',', '.') : '');
  }
}
