import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { AbstractAssetMapComponent } from '@app/shared/components/map/abstract-asset-map.component';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import * as L from 'leaflet';
import { GoogleProvider } from 'leaflet-geosearch';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'single-asset-map',
  templateUrl: './single-asset-map.component.html',
  styleUrls: ['./map.component.scss']
})
export class SingleAssetMapComponent extends AbstractAssetMapComponent implements OnChanges {

  @Input() public address: {
    streetNumber?: string,
    street?: string,
    postalCode?: string,
    city?: string,
    country?: string,
    latitude?: string,
    longitude?: string
  };
  @Input() public allowMarkerDragging: boolean;

  @Output() public positionUpdated = new EventEmitter<L.LatLng>();

  private marker: L.Marker;

  private provider = new GoogleProvider({
    params: {
      key: environment.apiKey.googleMaps
    }
  });

  constructor(protected accessManager: AccessManager,
              protected translate: TranslateService,
              protected deviceDetector: DeviceDetectorService,
              public appConfig: AppConfig) {
    super(translate, deviceDetector, accessManager);
    this.defaultMarkerGroup = L.featureGroup();
  }

  /**
   * Reset and reload the asset map when the input changes.
   */
  public ngOnChanges(): void {
    this.defaultMarkerGroup.clearLayers();
    this.setMapMarkers();
  }

  /**
   * When the user clicks on the map
   * @param event the event object
   */
  public mapClick(event: L.LeafletMouseEvent): void {
    if (this.allowClicking && this.permissionsForEdition && this.accessManager.hasAllNeededPermissions(this.permissionsForEdition)) {
      this.updateMarker(event.latlng);
    }
  }

  /**
   * Initialises the map background layer
   */
  protected setBaseLayers(): void {
    // Add the default OSM to the map so something is selected by default
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18, minZoom: 2}).addTo(this.map);
  }

  /**
   * Draws a map marker at the given asset coordinates. Bounds the map to the marker and centers it.
   */
  protected setMapMarkers(): void {
    if (this.address?.latitude && this.address?.longitude) {
      // Format values so the leaflet library can understand them
      const formattedLatitude = this.formatCoordinateToNumber(this.address.latitude);
      const formattedLongitude = this.formatCoordinateToNumber(this.address.longitude);

      // Create a marker
      this.marker = L.marker(
        L.latLng(formattedLatitude, formattedLongitude),
        {
          draggable: this.allowMarkerDragging,
          icon: L.icon({iconUrl: this.defaultIconMarkerUrl, iconSize: [29, 46], iconAnchor: [14.5, 41]})
        })
        .on('dragend', (event: L.DragEndEvent) => this.updateMarker(event.target.getLatLng()), this);

      this.defaultMarkerGroup.addLayer(this.marker);

      this.hasAddress = true;
      if (this.map) {
        this.map.fitBounds(this.defaultMarkerGroup.getBounds().pad(0.5), {maxZoom: 18});
      }
    }
  }

  /**
   * Move map and marker to the LatLng parameter and emit new position to parent component
   * @param latlng the new position of the marker
   */
  private updateMarker(latlng: L.LatLng): void {
    this.marker.setLatLng(latlng);
    this.map.panTo(latlng);
    this.positionUpdated.emit(latlng);
  }

  /**
   * Geolocates a point given address information and emits the new position to parent component
   */
  public updateAddressCoordinates(): void {
    // Remove all previous markers if present
    this.defaultMarkerGroup.clearLayers();

    const fullAddress = [this.address?.streetNumber, this.address?.street, this.address?.city,
      this.address?.postalCode, this.address?.country].join(' ').trim();

    this.provider.search({query: fullAddress})
      .then((result) => {
        if (result.length) {
          this.address.longitude = result[0].x.toString();
          this.address.latitude = result[0].y.toString();

          this.setMapMarkers();

          const latlng = L.latLng([this.formatCoordinateToNumber(result[0].y), this.formatCoordinateToNumber(result[0].x)]);
          this.positionUpdated.emit(latlng);
        } else {
          this.positionUpdated.emit();
          // Destroy the map reference and hide it
          this.hasAddress = false;
          this.map = null;
        }
      });
  }
}
