import { Injectable } from '@angular/core';
import { CheckType } from '@app/core/model/entities/asset/check-type';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CheckTypeService {

  private checkTypes$ = new ReplaySubject<{ [k: string]: CheckType }>(1);
  public hasCheckTypes: boolean = false;

  private readonly checkInfoGraphqlFragment: any;

  constructor(private apiService: ApiService,
              private appManager: AppManager) {
    this.checkInfoGraphqlFragment = gql`
      fragment CheckTypeInfo on CheckType {
        code
        values
        observation
      }
    `;
  }

  public getCheckTypesObservable(): Observable<{ [k: string]: CheckType }> {
    return this.checkTypes$.asObservable();
  }


  public loadChecksTypeData(): Observable<{ [k: string]: CheckType }> {
    const query = gql`
      query OrganizationCheckTypes($organizationId: String!) {
        checkTypesByOrganizationId(organizationId: $organizationId) {
          ...CheckTypeInfo
        }
      }${this.checkInfoGraphqlFragment}
    `;
    const variables = {
      organizationId: this.appManager.currentOrganization.id
    };

    return this.apiService.query({query, variables})
      .pipe(
        map(data => {
          const checkTypesList = plainToInstance(CheckType, data['checkTypesByOrganizationId'] as CheckType[]);
          const checkTypes: { [k: string]: CheckType } = {};
          checkTypesList.forEach((type) => checkTypes[type.code] = type);
          this.hasCheckTypes = checkTypesList.length > 0;
          this.checkTypes$.next(checkTypes);
          return checkTypes;
        })
      );
  }

  public getCheckTypeByCode(code: string): Observable<CheckType> {
    const query = gql`
      query CheckTypeByCodeAndOrganizationId($code: String!, $organizationId: String!) {
        checkTypeByCodeAndOrganizationId(checkTypeCode: $code, organizationId: $organizationId) {
          ...CheckTypeInfo
        }
      }${this.checkInfoGraphqlFragment}
    `;
    const variables = {
      code,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(CheckType, data['checkTypeByCode'])));
  }
}
