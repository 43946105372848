import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Entity } from '@app/core/model/entities/entity';
import { FormatType } from '@app/core/model/other/field-config';
import { ChartRef, CreateRangeChartParams } from 'ag-grid-community';
import { Expose } from 'class-transformer';

export class Dashboard extends Entity {
  public organizationId: string;
  public isDefault: boolean;
  public entityType: EntityTypeEnum;

  @Expose({name: 'chartsList'})
  public charts: Chart[];

  public isModified: boolean;

  @Expose()
  public toString(): string {
    return this.name;
  }
}

export interface Chart {
  config: CreateRangeChartParams;
  options?: ChartOptions;
  //Only use when the chart is displayed
  chartId?: string;
  chartRef?: ChartRef;
  chartContainer?: HTMLElement;
}

export interface ChartOptions {
  formatTypes?: {
    x?: FormatType,
    y?: FormatType
  };
  suffix?: {
    x?: string,
    y?: string
  };
}
