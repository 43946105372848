import { NgModule } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { ApiModule } from '@app/core/api.module';
import { AppConfig } from '@app/core/app.config';
import { ChangeService } from '@services/change.service';
import { FileService } from '@services/file.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { ErrorManager } from '@services/managers/error.manager';
import { NotificationManager } from '@services/managers/notification.manager';
import { PopupManager } from '@services/managers/popup.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { PowerBiService } from '@services/power-bi.service';
import { RetoolAppService } from '@services/retool-app.service';
import { ScriptService } from '@services/script.service';
import { SectionService } from '@services/section.service';

@NgModule({
  declarations: [],
  imports: [
    ApiModule
  ],
  providers: [
    // Managers
    AppManager,
    AccessManager,
    ErrorManager,
    PopupManager,
    SnackbarManager,
    NotificationManager,
    // Services
    ChangeService,
    AnalyticsService,
    FileService,
    SectionService,
    ScriptService,
    PowerBiService,
    RetoolAppService,
    {
      provide: AppConfig,
      useValue: new AppConfig()
    }
  ]
})
export class CoreModule {
}
