import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { ObserversModule } from '@angular/cdk/observers';
import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { HttpLoaderFactory } from '@app/app.module';
import { ScriptService } from '@app/core/services/script.service';
import {
  AssetWorksSidebarComponent
} from '@app/features/main/views/assets/asset-sheet/works/asset-works-sidebar/asset-works-sidebar.component';
import {
  EquipmentWorksSidebarComponent
} from '@app/features/main/views/equipments/equipment-sheet/works/equipment-works-sidebar/equipment-works-sidebar.component';
import {
  InvestmentCreateModalComponent
} from '@app/features/main/views/investments/investments-inventory/modals/investment-create-modal/investment-create-modal.component';
import {
  InvestmentsSidebarComponent
} from '@app/features/main/views/investments/investments-sidebar/investments-sidebar.component';
import {
  CheckSidebarComponent
} from '@app/features/main/views/organization-checks/checks-sidebar/check-sidebar.component';
import {
  ContractSidebarComponent
} from '@app/features/main/views/organization-contracts/contract-sidebar/contract-sidebar.component';
import {
  DocumentSidebarComponent
} from '@app/features/main/views/organization-documents/document-sidebar/document-sidebar.component';
import {
  LeaseSidebarComponent
} from '@app/features/main/views/organization-occupants/lease-sidebar/lease-sidebar.component';
import {
  SpaceSidebarComponent
} from '@app/features/main/views/organization-spaces/space-sidebar/space-sidebar.component';
import {
  ProjectWorksSidebarComponent
} from '@app/features/main/views/projects/project-sheet/works/project-works-sidebar/project-works-sidebar.component';
import {
  ProjectsSidebarComponent
} from '@app/features/main/views/projects/projects-inventory/projects-sidebar/projects-sidebar.component';
import { AbstractMatTabNavComponent } from '@app/shared/components/abstract-mat-tab-nav/abstract-mat-tab-nav.component';
import { AddFieldLinkComponent } from '@app/shared/components/add-field-link/add-field-link.component';
import { AggregationGridComponent } from '@app/shared/components/aggregation-grid/aggregation-grid.component';
import {
  AvatarCellRendererComponent
} from '@app/shared/components/avatar-cell-renderer/avatar-cell-renderer.component';
import { DateCellEditComponent } from '@app/shared/components/cell-edit/date-cell-edit/date-cell-edit.component';
import {
  DateTimeCellEditComponent
} from '@app/shared/components/cell-edit/date-time-cell-edit/date-time-cell-edit.component';
import {
  MultipleAutocompleteCellEditComponent
} from '@app/shared/components/cell-edit/multiple-autocomplete-cell-edit/multiple-autocomplete-cell-edit.component';
import {
  MultipleEntityAutocompleteCellEditComponent
} from '@app/shared/components/cell-edit/multiple-autocomplete-cell-edit/multiple-entity-autocomplete-cell-edit.component';
import { NumberCellEditComponent } from '@app/shared/components/cell-edit/number-cell-edit/number-cell-edit.component';
import {
  PercentCellEditComponent
} from '@app/shared/components/cell-edit/percent-cell-edit/percent-cell-edit.component';
import { SelectCellEditComponent } from '@app/shared/components/cell-edit/select-cell-edit/select-cell-edit.component';
import {
  TextAreaCellEditComponent
} from '@app/shared/components/cell-edit/text-area-cell-edit/text-area-cell-edit.component';
import { TextCellEditComponent } from '@app/shared/components/cell-edit/text-cell-edit/text-cell-edit.component';
import {
  TextSuffixCellEditComponent
} from '@app/shared/components/cell-edit/text-suffix-cell-edit/text-suffix-cell-edit.component';
import {
  ComputedCellRendererComponent
} from '@app/shared/components/cell-renderer/computed-cell-renderer/computed-cell-renderer.component';
import {
  CustomCellRendererComponent
} from '@app/shared/components/cell-renderer/custom-cell-renderer/custom-cell-renderer.component';
import {
  EditableCellRendererComponent
} from '@app/shared/components/cell-renderer/editable-cell-renderer/editable-cell-renderer.component';
import {
  GroupsCellRenderer
} from '@app/shared/components/cell-renderer/group-cell-renderer/group-cell-renderer.component';
import {
  LinkCellRendererComponent
} from '@app/shared/components/cell-renderer/link-cell-renderer/link-cell-renderer.component';
import {
  PictogramCellRendererComponent
} from '@app/shared/components/cell-renderer/pictogram-cell-renderer/pictogram-cell-renderer.component';
import {
  PinnedCellRendererComponent
} from '@app/shared/components/cell-renderer/pinned-cell-renderer/pinned-cell-renderer.component';
import { CheckResultCellComponent } from '@app/shared/components/cell-renderer/check-result-cell/check-result-cell.component';
import {
  CreateDashboardModalComponent
} from '@app/shared/components/dashboard/create-dashboard-modal/create-dashboard-modal.component';
import {
  DashboardToolPanelComponent
} from '@app/shared/components/dashboard/dashboard-tool-panel/dashboard-tool-panel.component';
import { DashboardComponent } from '@app/shared/components/dashboard/dashboard.component';
import { EquipmentsSidebarComponent } from '@app/shared/components/equipments-sidebar/equipments-sidebar.component';
import {
  AddressFieldBuilderComponent
} from '@app/shared/components/field-groups/address-field-builder/address-field-builder.component';
import {
  ClassificationFieldBuilderComponent
} from '@app/shared/components/field-groups/classification-field-builder/classification-field-builder.component';
import { FieldGroupAnchorDirective } from '@app/shared/components/field-groups/field-group-anchor.directive';
import {
  GroupFieldBuilderComponent
} from '@app/shared/components/field-groups/group-field-builder/group-field-builder.component';
import {
  MarketValueFieldBuilderComponent
} from '@app/shared/components/field-groups/marketValue-field-builder/market-value-field-builder.component';
import {
  SimplifiedReplacementValueFieldBuilderComponent
} from '@app/shared/components/field-groups/simplified-replacementValue-field-builder/simplified-replacement-value-field-builder.component';
import {
  SingleFieldBuilderComponent
} from '@app/shared/components/field-groups/single-field-builder/single-field-builder.component';
import {
  AssetFieldBuilderComponent
} from '@app/shared/components/fields/asset-field-builder/asset-field-builder.component';
import {
  AutocompleteFieldBuilderComponent
} from '@app/shared/components/fields/autocomplete-field-builder/autocomplete-field-builder.component';
import {
  BudgetFieldBuilderComponent
} from '@app/shared/components/fields/budget-field-builder/budget-field-builder.component';
import {
  ChipsFieldBuilderComponent
} from '@app/shared/components/fields/chips-field-builder/chips-field-builder.component';
import {
  AssetsChipsFieldBuilderComponent
} from '@app/shared/components/fields/chips-field-builder/entity-chips-field-builder/assets-chips-field-builder.component';
import {
  EquipmentsChipsFieldBuilderComponent
} from '@app/shared/components/fields/chips-field-builder/entity-chips-field-builder/equipments-chips-field-builder.component';
import {
  ProjectsChipsFieldBuilderComponent
} from '@app/shared/components/fields/chips-field-builder/entity-chips-field-builder/projects-chips-field-builder.component';
import {
  ComputedFieldBuilderComponent
} from '@app/shared/components/fields/computed-field-builder/computed-field-builder.component';
import {
  DateFieldBuilderComponent
} from '@app/shared/components/fields/date-field-builder/date-field-builder.component';
import { DpeFieldBuilderComponent } from '@app/shared/components/fields/dpe-field-builder/dpe-field-builder.component';
import {
  EmailFieldBuilderComponent
} from '@app/shared/components/fields/email-field-builder/email-field-builder.component';
import { FieldAnchorDirective } from '@app/shared/components/fields/field-anchor.directive';
import {
  LinkFieldBuilderComponent
} from '@app/shared/components/fields/link-field-builder/link-field-builder.component';
import {
  ListCheckResultFieldBuilderComponent
} from '@app/shared/components/fields/list-check-result-field-builder/list-check-result-field-builder.component';
import {
  DocumentTypeFieldBuilderComponent
} from '@app/shared/components/fields/list-field-builder/document-type-field-builder.component';
import {
  ListFieldBuilderComponent
} from '@app/shared/components/fields/list-field-builder/list-field-builder.component';
import {
  MultivalueFieldBuilderComponent
} from '@app/shared/components/fields/multivalue-field-builder/multivalue-field-builder.component';
import {
  NumberFieldBuilderComponent
} from '@app/shared/components/fields/number-field-builder/number-field-builder.component';
import {
  RadiobuttonFieldBuilderComponent
} from '@app/shared/components/fields/radiobutton-field-builder/radiobutton-field-builder.component';
import {
  TextFieldBuilderComponent
} from '@app/shared/components/fields/text-field-builder/text-field-builder.component';
import {
  TextAreaFieldBuilderComponent
} from '@app/shared/components/fields/textarea-field-builder/textarea-field-builder.component';
import {
  SpacePathFieldBuilderComponent
} from '@app/shared/components/fields/tree-field-builder/path/space-path-field-builder.component';
import {
  SpacesFieldBuilderComponent
} from '@app/shared/components/fields/tree-field-builder/spaces-field-builder.component';
import { FileUploaderComponent } from '@app/shared/components/file-uploader/file-uploader.component';
import { FormBuilderComponent } from '@app/shared/components/form-builder/form-builder.component';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SectionAnchorDirective } from '@app/shared/components/form-builder/sections/section-anchor.directive';
import { SectionBuilderComponent } from '@app/shared/components/form-builder/sections/section-builder.component';
import { GroupGridCellComponent } from '@app/shared/components/group-grid/group-grid-cell.component';
import {
  ImageUploaderPreviewComponent
} from '@app/shared/components/image-uploader-preview/image-uploader-preview.component';
import { InlineEditClientComponent } from '@app/shared/components/inline-edit/inline-edit-client.component';
import { InlineEditUnitCrvComponent } from '@app/shared/components/inline-edit/inline-edit-unit-crv.component';
import { AbstractInlineEditComponent } from '@app/shared/components/inline-edit/inline-edit.component';
import { JmapComponent } from '@app/shared/components/jmap/jmap.component';
import { SingleAssetMapComponent } from '@app/shared/components/map/single-asset-map.component';
import {
  MatSelectAssetAutocompleteComponent
} from '@app/shared/components/mat-select-asset-autocomplete/mat-select-asset-autocomplete.component';
import { ModuleIconListComponent } from '@app/shared/components/module-chip-material/module-icon-list.component';
import { ModuleIconComponent } from '@app/shared/components/module-chip-material/module-icon.component';
import { ModuleGridCheckboxRendererComponent } from '@app/shared/components/module-grid/module-grid-checkbox.component';
import { ModuleGridFooterComponent } from '@app/shared/components/module-grid/module-grid-footer.component';
import { ModuleGridFooterService } from '@app/shared/components/module-grid/module-grid-footer.service';
import { NotificationPopupComponent } from '@app/shared/components/notification-popup/notification-popup.component';
import { PowerBiReportComponent } from '@app/shared/components/power-bi-report/power-bi-report.component';
import {
  RestrictedIconButtonComponent
} from '@app/shared/components/restricted-icon-button/restricted-icon-button.component';
import {
  RestrictedMenuItemComponent
} from '@app/shared/components/restricted-menu-item/restricted-menu-item.component';
import {
  RestrictedTextButtonComponent
} from '@app/shared/components/restricted-text-button/restricted-text-button.component';
import { StatusMessageComponent } from '@app/shared/components/status-message/status-message.component';
import { UserGridFooterComponent } from '@app/shared/components/user-grid/user-grid-footer.component';
import { UserGridFooterService } from '@app/shared/components/user-grid/user-grid-footer.service';
import {
  VirtualTourAppsheetComponent
} from '@app/shared/components/virtual-tour/appsheet/virtual-tour-appsheet.component';
import { VirtualTourMyaComponent } from '@app/shared/components/virtual-tour/mya/virtual-tour-mya.component';
import {
  AssetTypeVisualisationToolPanelComponent
} from '@app/shared/components/visualisation-tool-panel/asset-type-visualisation-tool-panel/asset-type-visualisation-tool-panel.component';
import {
  CreateDatagridVisualisationModalComponent
} from '@app/shared/components/visualisation-tool-panel/create-datagrid-visualisation-modal/create-datagrid-visualisation-modal.component';
import {
  VisualisationToolPanelComponent
} from '@app/shared/components/visualisation-tool-panel/visualisation-tool-panel.component';
import { PictureWidgetComponent } from '@app/shared/components/widgets/picture-widget/picture-widget.component';
import { AccordionLinkDirective } from '@app/shared/directives/accordion-link.directive';
import { AccordionToggleDirective } from '@app/shared/directives/accordion-menu.directive';
import { AccordionDirective } from '@app/shared/directives/accordion.directive';
import { InputAutoFocusDirective } from '@app/shared/directives/autofocus.directive';
import { PermissionLockDirective } from '@app/shared/directives/permission-lock.directive';
import { VisibilityObserverDirective } from '@app/shared/directives/visibility-observer.directive';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { ExportUtils } from '@app/shared/grid/export-utils';
import { GridOptionsService } from '@app/shared/grid/grid-options.service';
import { BytesPipe } from '@app/shared/pipes/bytes.pipe';
import { CheckResultColourPipe } from '@app/shared/pipes/check-result-colour.pipe';
import { EmptyFieldPipe } from '@app/shared/pipes/empty-field.pipe';
import { FieldFormatTypePipe } from '@app/shared/pipes/field-format-type.pipe';
import { ErrorModalDialogComponent } from '@app/shared/popup/error/error-modal-dialog.component';
import { OkCancelModalDialogComponent } from '@app/shared/popup/ok-cancel/ok-cancel.modal-dialog';
import { OkModalDialogComponent } from '@app/shared/popup/ok/ok.modal-dialog';
import { SpinnerService } from '@app/shared/popup/spinner.service';
import {
  DeleteArchiveDocumentModalComponent
} from '@app/shared/popup/suppress-document-modal/delete-archive-document-modal.component';
import { UploadFileModalComponent } from '@app/shared/popup/upload-file-modal/upload-file-modal.component';
import { YesNoCancelModalDialogComponent } from '@app/shared/popup/yes-no-cancel/yes-no-cancel.modal-dialog';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { UndoRedoService } from '@app/shared/services/undo-redo.service';
import { UserPreferencesService } from '@app/shared/services/user-preferences.service';
import { ValidationService } from '@app/shared/services/validation.service';
import { ExtraValidatorsModule } from '@app/shared/validators/extra-validators.module';
import { SpinnerModule } from '@app/spinner.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GridConfigService } from '@services/grid-config.service';
import { ImportTemplateService } from '@services/import-template.service';
import { ImportService } from '@services/import.service';
import { NgxPictureCarouselModule } from '@tbmaestro/ngx-picture-carousel';
import { AgGridModule } from 'ag-grid-angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AvatarModule } from 'ngx-avatars';
import { NgxColorsModule } from 'ngx-colors';
import { NgxUploaderModule } from 'ngx-uploader';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import {
  FullRowEditCellRendererComponent
} from './components/cell-renderer/full-row-edit-cell-renderer/full-row-edit-cell-renderer.component';
import {
  InvitationCellRendererComponent
} from './components/cell-renderer/invitation-cell-renderer/invitation-cell-renderer.component';
import {
  DocumentAssetsFieldBuilderComponent
} from './components/fields/document-assets-builder/document-assets-field-builder/document-assets-field-builder.component';
import { IndicatorStatusPanelComponent } from './components/indicator-status-panel/indicator-status-panel.component';
import { IndicatorWidgetComponent } from './components/indicator-widget/indicator-widget.component';
import { MatSelectAutocompleteComponent } from './components/mat-select-autocomplete/mat-select-autocomplete.component';
import { MaterialTooltipComponent } from './components/material-tooltip/material-tooltip.component';
import {
  RestrictedMiniFabButtonComponent
} from './components/restricted-mini-fab-button/restricted-mini-fab-button.component';

const components = [
  // Components
  NotificationPopupComponent,
  ModuleIconComponent,
  ModuleIconListComponent,
  ModuleGridCheckboxRendererComponent,
  ModuleGridFooterComponent,
  UserGridFooterComponent,
  CheckResultCellComponent,
  EquipmentsSidebarComponent,
  ProjectsSidebarComponent,
  AssetTypeVisualisationToolPanelComponent,
  VisualisationToolPanelComponent,
  IndicatorStatusPanelComponent,
  IndicatorWidgetComponent,
  AggregationGridComponent,
  IndicatorWidgetComponent,
  MaterialTooltipComponent,
  PictogramCellRendererComponent,
  CustomCellRendererComponent,
  StatusMessageComponent,
  AbstractInlineEditComponent,
  InlineEditClientComponent,
  InlineEditUnitCrvComponent,
  DateCellEditComponent,
  TextCellEditComponent,
  TextAreaCellEditComponent,
  PercentCellEditComponent,
  NumberCellEditComponent,
  SelectCellEditComponent,
  TextSuffixCellEditComponent,
  MultipleAutocompleteCellEditComponent,
  RestrictedTextButtonComponent,
  RestrictedIconButtonComponent,
  RestrictedMiniFabButtonComponent,
  RestrictedMenuItemComponent,
  AvatarCellRendererComponent,
  SingleAssetMapComponent,
  FileUploaderComponent,
  UploadFileModalComponent,
  MatSelectAssetAutocompleteComponent,
  MatSelectAutocompleteComponent,
  MultipleEntityAutocompleteCellEditComponent,
  LinkCellRendererComponent,
  PinnedCellRendererComponent,
  ComputedCellRendererComponent,
  EditableCellRendererComponent,
  FullRowEditCellRendererComponent,
  PictureWidgetComponent,
  VirtualTourAppsheetComponent,
  VirtualTourMyaComponent,
  AssetWorksSidebarComponent,
  ProjectWorksSidebarComponent,
  EquipmentWorksSidebarComponent,
  AbstractMatTabNavComponent,
  GroupGridCellComponent,
  GroupsCellRenderer,
  DashboardComponent,
  DashboardToolPanelComponent,
  CreateDashboardModalComponent,
  PowerBiReportComponent,
  JmapComponent,
  ImageUploaderPreviewComponent,
  ContractSidebarComponent,
  SpaceSidebarComponent,
  CheckSidebarComponent,
  DocumentSidebarComponent,
  LeaseSidebarComponent,
  InvitationCellRendererComponent,
  InvestmentsSidebarComponent,
  InvestmentCreateModalComponent,

  // Modals
  OkModalDialogComponent,
  OkCancelModalDialogComponent,
  YesNoCancelModalDialogComponent,
  CreateDatagridVisualisationModalComponent,
  DeleteArchiveDocumentModalComponent,
  ErrorModalDialogComponent,

  // Fields meta
  AddFieldLinkComponent,
  FormBuilderComponent,
  SectionBuilderComponent,

  // Group field containers
  AddressFieldBuilderComponent,
  MarketValueFieldBuilderComponent,
  SimplifiedReplacementValueFieldBuilderComponent,
  GroupFieldBuilderComponent,
  SingleFieldBuilderComponent,

  // Single fields
  ComputedFieldBuilderComponent,
  TextFieldBuilderComponent,
  EmailFieldBuilderComponent,
  ListFieldBuilderComponent,
  DocumentTypeFieldBuilderComponent,
  ChipsFieldBuilderComponent,
  TextAreaFieldBuilderComponent,
  RadiobuttonFieldBuilderComponent,
  DateFieldBuilderComponent,
  DateTimeCellEditComponent,
  DpeFieldBuilderComponent,
  AutocompleteFieldBuilderComponent,
  MultivalueFieldBuilderComponent,
  ListCheckResultFieldBuilderComponent,
  NumberFieldBuilderComponent,
  BudgetFieldBuilderComponent,
  ClassificationFieldBuilderComponent,
  SpacesFieldBuilderComponent,
  SpacePathFieldBuilderComponent,
  LinkFieldBuilderComponent,
  AssetFieldBuilderComponent,
  EquipmentsChipsFieldBuilderComponent,
  AssetsChipsFieldBuilderComponent,
  ProjectsChipsFieldBuilderComponent,
  DocumentAssetsFieldBuilderComponent,
  DashboardComponent,
  DashboardToolPanelComponent,
  CreateDashboardModalComponent,
  PowerBiReportComponent
];

@NgModule({
  imports: [
    CommonModule,
    SpinnerModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    MatListModule,
    MatRadioModule,
    MatTabsModule,
    MatSidenavModule,
    MatAutocompleteModule,
    AvatarModule,
    RouterModule,
    LeafletModule,
    NgxUploaderModule,
    StoreDevtoolsModule.instrument({
      maxAge: 10
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      extend: true
    }),
    A11yModule,
    AgGridModule,
    NgxPictureCarouselModule,
    PowerBIEmbedModule,
    NgOptimizedImage,
    LazyLoadImageModule
  ],
  exports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // i18n
    TranslateModule,
    // CDK
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
    // Material
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    // Spinner
    SpinnerModule,
    // Ag-Grid
    AgGridModule,
    // Custom validators
    ExtraValidatorsModule,
    // Avatar module
    AvatarModule,
    // Upload
    NgxUploaderModule,
    // Color picker
    NgxColorsModule,
    // Custom components
    ...components,
    // Directives
    InputAutoFocusDirective,
    PermissionLockDirective,
    AccordionToggleDirective,
    AccordionDirective,
    AccordionLinkDirective,
    SectionAnchorDirective,
    FieldAnchorDirective,
    FieldGroupAnchorDirective,
    VisibilityObserverDirective,
    FieldFormatTypePipe,
    EmptyFieldPipe,
    CheckResultColourPipe,
    BytesPipe
  ],
  declarations: [
    // Components
    ...components,
    // Directives
    PermissionLockDirective,
    AccordionToggleDirective,
    AccordionDirective,
    AccordionLinkDirective,
    SectionAnchorDirective,
    FieldAnchorDirective,
    FieldGroupAnchorDirective,
    VisibilityObserverDirective,
    InputAutoFocusDirective,
    // Pipes
    FieldFormatTypePipe,
    EmptyFieldPipe,
    CheckResultColourPipe,
    BytesPipe
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        EmptyFieldPipe,
        FieldFormatTypePipe,
        ScriptService,
        ColumnBuilder,
        GridConfigService,
        ImportTemplateService,
        GridOptionsService,
        ExportUtils,
        SpinnerService,
        ModuleGridFooterService,
        UserGridFooterService,
        SingleEditService,
        FormStateService,
        ValidationService,
        ImportService,
        UndoRedoService,
        UserPreferencesService,
        {provide: OverlayContainer, useClass: FullscreenOverlayContainer} // Make overlay visible in fullscreen mode
      ]
    };
  }

}
