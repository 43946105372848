import { AbstractBean } from '@app/core/model/abstract-bean';
import { Client } from '@app/core/model/client/client';
import { Permission } from '@app/core/model/client/permission';
import { User } from '@app/core/model/client/user';
import { Expose, Type } from 'class-transformer';

export class Group extends AbstractBean {
  public assetIds: string[];
  public allAssetAccess: boolean;
  public assetTypeIds: string[];
  public allAssetTypeAccess: boolean;

  @Type(() => Client)
  public client: Client;
  @Type(() => User)
  public users: User[];
  @Type(() => Permission)
  public activePermissions: Permission[];

  @Expose()
  public toString(): string {
    return this.name;
  }
}

export interface GroupInput {
  name: string;
  userIds: number[];
  assetIds?: string[];
  allAssetAccess: boolean;
  permissionIds: number[];
  assetTypeIds?: string[];
  allAssetTypeAccess: boolean;
}
