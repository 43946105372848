<div class="btn-bi">
  <restricted-icon-button
    (onClick)="toggleFullScreen()"
    [id]="'fullscreen-button'"
    [class]="'mdi mdi-fullscreen md'"
    [matTooltip]="('LABEL.FULLSCREEN' | translate)"
    matTooltipPosition="right">
  </restricted-icon-button>
  <restricted-icon-button
    [id]="'bi-dashboard-print-button'"
    [class]="'mdi mdi-download md'"
    [matTooltip]="('LABEL.EXPORT_REPORT' | translate)"
    [permissions]="[Permission.EXPORT_BI_REPORT]"
    [disabled]="exportInProgress"
    (onClick)="exportBiReport()"
    matTooltipPosition="right">
  </restricted-icon-button>
  <restricted-icon-button
    (onClick)="print()"
    [id]="'print-button'"
    [class]="'mdi mdi-printer md'"
    [matTooltip]="('LABEL.PRINT' | translate)"
    matTooltipPosition="right">
  </restricted-icon-button>
</div>
@if (powerBiReportConfig) {
  <div class="fx-layout-col fx-justify-stretch fx-fill" #powerBi>
    <powerbi-report class="fx-fill"
                    [embedConfig]="powerBiReportConfig"
                    cssClassName="report-container">
    </powerbi-report>
  </div>
} @else {
  <spinner></spinner>
}

