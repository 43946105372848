<div class="header-container fx-layout-row fx-justify-start fx-align-baseline fx-gap-10">
  @if (investment) {
    <div class="investment-name-block page-title">
      <div>{{ investment?.toString() }}</div>
    </div>
  }

  <div class="fx-flex"></div>
  <div id="investment-menu" class="fx-flex-48">
    <restricted-icon-button
      [class]="'mdi mdi-dots-vertical sm'" [id]="'investment-sheet-menu-button'" [matMenuTriggerFor]="menu"
      [preconditions]="accessManager.hasAccess(Permission.DELETE_INVESTMENT)">
    </restricted-icon-button>
    <mat-menu #menu="matMenu">
      <restricted-menu-item (onClick)="deleteInvestment()" [id]="'delete-investment-menu'"
                            [permissions]="[Permission.DELETE_INVESTMENT]"
                            [preconditions]="true">
        {{ 'BUTTON.DELETE_INVESTMENT' | translate }}
      </restricted-menu-item>
    </mat-menu>
  </div>
</div>
