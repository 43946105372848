import { Injectable } from '@angular/core';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Asset } from '@app/core/model/entities/asset/asset';
import { CreateDocumentInput, Document, DocumentInput } from '@app/core/model/entities/document/document';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DocumentsService {
  private sidePanelToggleSubject = new Subject<Document | null>();

  private addDocumentsSubject = new Subject<Document[]>();
  private updateDocumentSubject = new Subject<Document>();
  private deleteDocumentsSubject = new Subject<Document[]>();

  private readonly documentInfoGraphqlFragment = gql`
    fragment DocumentInfo on Document {
      id
      name
      creationDate
      documentType
      documentState
      hash
      properties
      mimeType
      assetsList {
        assetId
        assetIdentifier
        assetName
      }
    }
  `;

  constructor(private apiService: ApiService,
              private appManager: AppManager,
              private assetsService: AssetsService) {
  }

  /**
   * Emits all the current Organization's Documents.
   */
  public get documents$(): Observable<Document[]> {
    const query = gql`
      query OrganizationDocuments($organizationId: String!) {
        organizationDocuments(organizationId: $organizationId) {
          ...DocumentInfo
        }
      }
      ${this.documentInfoGraphqlFragment}
    `;
    const variables = {
      organizationId: this.appManager.currentOrganization.id
    };

    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(Document, data['organizationDocuments'] as Document[])));
  }

  /**
   * Emits all the current Asset's Documents.
   */
  public get assetDocuments$(): Observable<Document[]> {
    const query = gql`
      query AssetDocuments($assetId: String!) {
        assetDocuments(assetId: $assetId) {
          ...DocumentInfo
        }
      }
      ${this.documentInfoGraphqlFragment}
    `;
    const variables = {
      assetId: this.appManager.currentAsset.id
    };

    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(Document, data['assetDocuments'] as Document[])));
  }

  /**
   * Emits the categories that can be associated with new Documents for the current Organization.
   */
  public get availableDocumentCategories$(): Observable<string[]> {
    const query = gql`
      query OrganizationDocumentCategories($organizationId: String!) {
        organizationDocumentCategories(organizationId: $organizationId)
      }`;
    const variables = {organizationId: this.appManager.currentOrganization.id};
    return this.apiService.query({query, variables})
      .pipe(map(data => data['organizationDocumentCategories'] as string[]));
  }

  /**
   * Emits Documents after they have been added.
   */
  public get documentsAdded$(): Observable<Document[]> {
    return this.addDocumentsSubject.asObservable();
  }

  /**
   * Emit a Document after it has been updated.
   */
  public get documentUpdated$(): Observable<Document> {
    return this.updateDocumentSubject.asObservable();
  }

  /**
   * Emits Documents after they have been deleted.
   */
  public get documentsDeleted$(): Observable<Document[]> {
    return this.deleteDocumentsSubject.asObservable();
  }

  /**
   * Emits a Document whenever the side panel opens, or null whenever it closes.
   */
  public get sidePanelToggle$(): Observable<Document | null> {
    return this.sidePanelToggleSubject.asObservable();
  }

  /**
   * Fetch one document.
   * @param documentId ID of the Document.
   * @return Observable emitting the Document.
   */
  public getDocument(documentId: string): Observable<Document> {
    const query = gql`
      query OrganizationLogo($docId: String!) {
        document(id: $docId) {
          ...DocumentInfo
        }
      }
      ${this.documentInfoGraphqlFragment}
    `;
    const variables = {
      docId: documentId
    };
    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(Document, data['document'])));
  }

  /**
   * Fetch Documents related to an entity.
   * @param entityId ID of the entity which is related to the Documents to fetch.
   * @param entityType Type of the entity.
   * @param documentType Type of Documents to return.
   */
  public loadEntityDocuments(
    entityId: string,
    entityType: EntityTypeEnum,
    documentType: DocumentTypeEnum
  ): Observable<Document[]> {
    const query = gql`
      query EntityDocuments($entityId: String!, $entityType : EntityType!, $documentType: DocumentTypeEnum!) {
        entityDocuments(entityId: $entityId, entityType: $entityType, documentType: $documentType) {
          ...DocumentInfo
        }
      }
      ${this.documentInfoGraphqlFragment}
    `;
    const variables = {entityId, entityType, documentType};

    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(Document, data['entityDocuments'] as Document[])));
  }

  /**
   * Return the document by its ID.
   * @param id Document ID
   * @return Document
   */
  public document(id: string): Observable<Document> {
    const query = gql`
      query OrganizationDocument($id: String!) {
        document(id: $id) {
          ...DocumentInfo
        }
      }
      ${this.documentInfoGraphqlFragment}
    `;
    const variables = {id};

    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(Document, data['document'])));
  }

  /**
   * Call the API to create Documents with the provided data.
   * @param documentInputs Data for creating new Documents.
   * @param entityId ID of the entity the new Documents are related to.
   * @param entityType Type of the entity the new Documents are related to.
   * @return Created Documents.
   */
  public createDocuments(
    documentInputs: CreateDocumentInput[],
    entityId: string,
    entityType: EntityTypeEnum
  ): Observable<Document[]> {
    const mutation = gql`
      mutation CreateDocuments (
        $createDocumentsInputs: [CreateDocumentInput!]!,
        $entityId: String!,
        $entityType: EntityType!
      ) {
        createDocuments (
          createDocumentsInputs: $createDocumentsInputs,
          entityId: $entityId,
          entityType: $entityType
        ) {
          ...DocumentInfo
        }
      }
      ${this.documentInfoGraphqlFragment}
    `;
    const variables = {
      entityId,
      entityType,
      createDocumentsInputs: documentInputs
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(Document, data['createDocuments'] as Document[])),
        tap(newDocuments => this.addDocumentsSubject.next(newDocuments))
      );
  }

  /**
   * Call the API to update a Document.
   * @param document Document to update
   * @param documentInput Data for updating the Document.
   * @return Observable emitting the updated Document once the update is completed.
   */
  public updateDocument(document: Document, documentInput: DocumentInput): Observable<Document> {
    const mutation = gql`
      mutation UpdateOrganizationDocument($documentId: String!, $documentInput: DocumentInput!) {
        updateDocument(documentId: $documentId, documentInput: $documentInput) {
          ...DocumentInfo
        }
      }
      ${this.documentInfoGraphqlFragment}
    `;

    let documentType = document.documentType;
    if (document.documentType == DocumentTypeEnum.ORGANIZATION_DOCUMENT && documentInput.assetId) {
      // If an Asset is set to an Organization's document, the document becomes an Asset's document.
      documentType = DocumentTypeEnum.ASSET_DOCUMENT;
    } else if ([DocumentTypeEnum.ASSET_DOCUMENT, DocumentTypeEnum.ASSET_PICTURE].includes(document.documentType)
      && Object.hasOwn(documentInput, 'assetId') && !documentInput.assetId) {
      // If the Asset is removed in an Asset's document or picture document, the document become an Organization's document.
      documentType = DocumentTypeEnum.ORGANIZATION_DOCUMENT;
    }

    const variables = {
      documentId: document.id,
      documentInput: {
        name: document.name,
        documentType: documentType,
        documentState: document.documentState,
        ...documentInput
      }
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(Document, data['updateDocument'])),
        tap(updatedDocument => this.updateDocumentSubject.next(updatedDocument))
      );
  }

  /**
   * Call the API to link a Document to an entity.
   * @param entityId ID of entity to link the document to.
   * @param documentType Type of the document.
   * @param documentId ID of the document to link.
   * @return Observable emitting the created Document once the document link is completed.
   */
  public linkDocumentToEntity(
    entityId: string,
    documentType: DocumentTypeEnum,
    documentId: string
  ): Observable<Document> {
    const mutation = gql`
      mutation LinkDocumentToEntity($entityId: String!, $documentType: DocumentTypeEnum!, $documentId: String!) {
        linkDocument(entityId: $entityId, documentType: $documentType, documentId: $documentId) {
          ...DocumentInfo
        }
      }
      ${this.documentInfoGraphqlFragment}
    `;
    const variables = {entityId, documentType, documentId};
    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(Document, data['linkDocument'])),
        tap(createdDocument => this.addDocumentsSubject.next([createdDocument]))
      );
  }

  /**
   * Call the API to delete a Document.
   * @param document Document to delete.
   * @return Observable that will emit a boolean value indicating whether the Document was deleted successfully.
   */
  public deleteEntityDocument(document: Document): Observable<boolean> {
    const mutation = gql`
      mutation DeleteDocument($documentId: String!) {
        deleteDocument(documentId: $documentId)
      }
    `;
    const variables = {documentId: document.id};

    return this.apiService.mutate({mutation, variables})
      .pipe(map(data => data['deleteDocument'] as boolean));
  }

  /**
   * Call the API to delete multiple Documents.
   * @param documents Documents to be deleted.
   * @return Observable that will emit a boolean value indicating whether the Documents were deleted successfully.
   */
  public deleteOrganizationDocuments(documents: Document[]): Observable<boolean> {
    const mutation = gql`
      mutation DeleteOrganizationDocuments($documentIds: [String!]!) {
        deleteDocuments(documentIds: $documentIds)
      }
    `;
    const variables = {
      documentIds: documents.map(selectedDocument => selectedDocument.id)
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => data['deleteDocuments'] as boolean),
        tap(success => success && this.deleteDocumentsSubject.next(documents))
      );
  }

  /**
   * Call the API to update Documents' states to archived.
   * @param documents Documents to archive.
   * @return Updated Documents.
   */
  public archiveOrganizationDocuments(documents: Document[]): Observable<Document[]> {
    const mutation = gql`
      mutation ArchiveOrganizationDocuments($documentIds: [String!]!) {
        archiveDocuments(documentIds: $documentIds) {
          ...DocumentInfo
        }
      }
      ${this.documentInfoGraphqlFragment}
    `;
    const variables = {
      documentIds: documents.map(document => document.id)
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(Document, data['archiveDocuments'] as Document[])),
        tap(updatedDocuments => updatedDocuments.forEach(document => this.updateDocumentSubject.next(document)))
      );
  }

  /**
   * Same as getAvailableCategories() but also return the list of Assets accessible to the User alongside the available categories.
   * @return Available Document categories and Assets for the Organization.
   */
  public getUploadDocumentsConfig(): Observable<{ categories: string[], assets: Asset[] }> {
    const query = gql`
      query OrganizationDocumentCategoriesAndAccessibleAsset($organizationId: String!) {
        organizationDocumentCategories(organizationId: $organizationId)
        accessibleAssets(organizationId: $organizationId) {
          id
          name
          identifier
        }
      }`;
    const variables = {organizationId: this.appManager.currentOrganization.id};
    return this.apiService.query({query, variables})
      .pipe(
        map(data => {
          return {
            categories: data['organizationDocumentCategories'] as string[],
            assets: plainToInstance(Asset, data['accessibleAssets'] as Asset[])
          };
        })
      );
  }

  /**
   * Open the side panel displaying the Document.
   * @param document Document to be displayed in the side panel.
   */
  public openDocumentSidePanel(document: Document): void {
    this.sidePanelToggleSubject.next(document);
  }

  /**
   * Close the side panel.
   */
  public closeDocumentSidePanel(): void {
    this.sidePanelToggleSubject.next(null);
  }

  /**
   * Navigate to the Asset's sheet and open the tab corresponding to the provided Document type.
   * ASSET_PICTURE will open the pictures tab and any other DocumentType will open the documents tab.
   * @param documentType Type of Document on which the tab to open depends.
   * @param assetId ID of the Asset whose sheet to open.
   */
  public async navigateToAssetSheet(documentType: DocumentTypeEnum, assetId: string): Promise<void> {
    const tabName = documentType === DocumentTypeEnum.ASSET_PICTURE ? 'pictures' : 'documents';
    await this.assetsService.navigateToAssetSheet(assetId, tabName);
  }
}
