import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { UserPreferences } from '@app/core/model/entities/preferences/user-preferences';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToClassFromExist } from 'class-transformer';

@Injectable()
export class UserPreferencesService {

  public currentUserPreferences: UserPreferences;

  constructor(private appManager: AppManager,
              private apiService: ApiService,
              private analyticsService: AnalyticsService) {
  }

  public updateUserPreferences(): void {
    const mutation = gql`
      mutation UpdateUserPreferences($id: Int!, $preferences: UserPreferencesInput!) {
        updateUserPreferences(id: $id, preferences: $preferences) {
          id
          preferences
        }
      }
    `;
    const variables = {
      id: this.currentUserPreferences.id,
      preferences: this.currentUserPreferences.preferences
    };
    this.apiService.mutate({mutation, variables})
      .subscribe(data => {
        this.analyticsService.trackEvent(
          new AnalyticsEvent(ActionEnum.UPDATE, EntityTypeEnum.USER_PREFERENCES)
            .addProperties({[AnalyticsKeyEnum.ENTITY_ID]: this.currentUserPreferences.id})
        );
        this.currentUserPreferences = plainToClassFromExist(this.currentUserPreferences, data['updateUserPreferences']);
      });
  }

}
