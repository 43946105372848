import { inject, Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import ApiService from '@services/api.service';
import { gql } from 'apollo-angular';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenRefreshService implements OnDestroy {
  private apiService = inject(ApiService);

  // Used to start/stop the refresh token interval
  private refreshSubscription: Subscription;
  // Used to emit the token refreshed
  private tokenRefreshSubject = new Subject<string>;

  private authService = inject(AuthService);

  /**
   * Execute a schema retrieval query to simulate activity on the site,
   * and keep the authentication token up to date.
   */
  public startTokenRefresh(): void {
    this.refreshSubscription = timer(180000, 180000).pipe(
      switchMap(() => this.refreshTokenRequest()),
      switchMap(() => this.authService.getAccessTokenSilently()),
      tap((token) => this.tokenRefreshSubject.next(token))
    )
      .subscribe({
        next: () => console.debug('Token refreshed'),
        error: (err) => console.error('Error refreshing token:', err)
      });
  }

  /**
   * Emit a string value once token changed.
   */
  public get tokenRefreshed$(): Observable<string> {
    return this.tokenRefreshSubject.asObservable()
      .pipe(distinctUntilChanged());
  }

  /**
   * Unsubscribe from refreshSubcription
   */
  public stopTokenRefresh(): void {
    // Clean up the subscription
    this.refreshSubscription && this.refreshSubscription.unsubscribe();
  }

  /**
   * Execute a graphql schema request.
   * @return Query response.
   * @private
   */
  private refreshTokenRequest(): Observable<unknown> {
    const query = gql`
      query Schema {
        __schema {
          __typename
        }
      }
    `;
    const variables = {};
    return this.apiService.query({query, variables});
  }

  /**
   * Prevent memory leak
   */
  public ngOnDestroy(): void {
    this.stopTokenRefresh();
  }
}
