import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AppManager } from '@services/managers/app.manager';
import { Observable } from 'rxjs';

@Injectable()
export class JmapService {

  constructor(private httpClient: HttpClient,
              private appManager: AppManager) {
  }

  /**
   * get jMap session ID
   * @return jMap sessionId
   */
  public getSessionId(): Observable<Object> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const uri = environment.backend.baseUrl + environment.backend.jmap.endpoint;
    const body = {
      'organizationId': this.appManager.currentOrganization.id
    };

    return this.httpClient.post(uri, body, {headers, responseType: 'json'});
  }

  /**
   * Open jMap in fullscreen mode
   * @param iframe The iframe wrapping the jMap iframe
   */
  public toggleFullScreen(iframe: HTMLIFrameElement): void {
    iframe.requestFullscreen().catch(console.error);
  }

}
