import dayjs, { Dayjs } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { HumanizeDuration, HumanizeDurationLanguage } from 'humanize-duration-ts';

export interface DurationResult {
  asMillisecondsValue: number;
  asNumberValue: number;
  asString: string;
}

export default class CustomDuration {
  private static langService: HumanizeDurationLanguage = new HumanizeDurationLanguage();
  private static humanizer: HumanizeDuration = new HumanizeDuration(CustomDuration.langService);

  public static calculate(startDate: Date | Dayjs, endDate: Date | Dayjs, unitOfTime: any = 'millisecond'): DurationResult {
    const diffMilliseconds = dayjs(endDate).diff(dayjs(startDate), unitOfTime);
    const diffMonths = dayjs(endDate).diff(dayjs(startDate), 'month');
    const navigatorLanguage = navigator.language.split('-')[0];
    const language = CustomDuration.humanizer.getSupportedLanguages().includes(navigatorLanguage) ? navigatorLanguage : 'fr';
    return {
      asMillisecondsValue: diffMilliseconds,
      asNumberValue: diffMonths,
      asString: CustomDuration.humanizer.humanize(diffMilliseconds, { language: language, round: true, units: ['mo'] })
    };
  }

  public static humanize(duration: Duration): string {
    const navigatorLanguage = navigator.language.split('-')[0];
    const language = CustomDuration.humanizer.getSupportedLanguages().includes(navigatorLanguage) ? navigatorLanguage : 'fr';
    return CustomDuration.humanizer.humanize(duration.asMilliseconds(), { language: language, round: true, units: ['y', 'mo', 'd'] });
  }
}

