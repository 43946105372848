import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Entity } from '@app/core/model/entities/entity';
import { Expose } from 'class-transformer';

export class AssetType extends Entity {
  public objectType = EntityTypeEnum.ASSET_TYPE;

  public organizationId: string;
  @Expose({name: 'modulesList'})
  public modules: string[];
  public static NullAssetType: AssetType;

  /**
   * Gets the name of the asset type.
   * @return The name of the asset type.
   */
  @Expose()
  public toString(): string {
    return this.name;
  }

  static {
    /**
     * Initialising a null object for AssetType to allow resetting the mat-select to ALL
     */
    this.NullAssetType = new AssetType();
    this.NullAssetType.id = null;
    this.NullAssetType.name = 'ALL';
  }

}

/**
 * AssetTypeInput for creating or updating Asset Types.
 */
export interface AssetTypeInput {
  name: string;
  modules: string[];
}
