import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { gql } from '@apollo/client/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Permission } from '@app/core/model/client/permission';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { JMapAuthDetails } from '@app/core/model/entities/jmap/jmap-auth-details';
import {
  Organization,
  OrganizationInput,
  OrganizationWithLogo
} from '@app/core/model/entities/organization/organization';
import { UserPreferences } from '@app/core/model/entities/preferences/user-preferences';
import { JmapCredentialsInput } from '@app/core/model/inputs/jmap-credentials-input';
import {
  DuplicateOrganizationModalComponent
} from '@app/features/main/views/_tb/tb-organizations/tb-organizations-datagrid/duplicate-organization-modal/duplicate-organization-modal.component';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { then } from '@app/shared/extra/utils';
import { UserPreferencesService } from '@app/shared/services/user-preferences.service';
import { TranslateService } from '@ngx-translate/core';
import ApiService from '@services/api.service';
import { FileService } from '@services/file.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { plainToInstance } from 'class-transformer';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class OrganizationsService {

  // Store multiple organizations.
  public organizations: Organization[] = [];

  private addOrganizationSubject = new Subject<Organization>();
  private deleteOrganizationSubject = new Subject<Organization>();

  /**
   * GraphQL fragment for fetching organization information.
   * - id: Unique identifier, used to navigate to its dashboard page.
   * - name: Name of the organization, used for display.
   * - properties: Any additional properties, used to get the ID of the associated logo image
   * @private
   */
  private readonly organizationsInfoGraphqlFragment = gql`
    fragment OrganizationInfo on Organization {
      id
      name
      locale
      currency
      organizationType
      properties
      computedProperties
      isDemo
      isTb
      createDate
      storageSpace
    }
  `;

  /**
   * Constructor for the Organizations Service.
   * Import services.
   */
  constructor(private appManager: AppManager,
              private accessManager: AccessManager,
              private userPreferencesService: UserPreferencesService,
              private analyticsService: AnalyticsService,
              private appConfig: AppConfig,
              private documentsService: DocumentsService,
              private fileService: FileService,
              private apiService: ApiService,
              private popupManager: PopupManager,
              private sanitizer: DomSanitizer,
              private snackbarManager: SnackbarManager,
              private translate: TranslateService) {
  }

  /**
   * Emits an Organization after it has been created.
   */
  public get organizationCreated$(): Observable<Organization> {
    return this.addOrganizationSubject.asObservable();
  }

  /**
   * Emits an Organization after it has been deleted.
   */
  public get organizationDeleted$(): Observable<Organization> {
    return this.deleteOrganizationSubject.asObservable();
  }

  /**
   * Load an Organization, as well as related settings such as the current User's preferences, permissions and modules.
   * @param organizationId ID of the Organization.
   * @return Observable that emits the loaded Organization.
   */
  public loadOrganization(organizationId: string): Observable<Organization> {
    const query = gql`
      query Organization($orgId: String!) {
        organization(id: $orgId) {
          ...OrganizationInfo
        }
        permissionsForUserOrg(organizationId: $orgId) {
          id
          code
        }
        userPreferences(organizationId: $orgId) {
          id
          preferences
        }
        organizationUsersInfo(organizationId: $orgId) {
          id
          name
        }
      }
      ${this.organizationsInfoGraphqlFragment}
    `;
    const variables = {
      orgId: organizationId
    };

    return this.appManager.useLang(organizationId)
      .pipe(
        catchError(() => this.appManager.resetLang()),
        then(() => {
          return this.apiService.query({query, variables, fetchPolicy: 'network-only'})
            .pipe(
              map(data => {
                return {
                  organization: plainToInstance(Organization, data['organization'] as Organization),
                  permissions: plainToInstance(Permission, data['permissionsForUserOrg'] as Permission[]),
                  preferences: plainToInstance(UserPreferences, data['userPreferences'] as UserPreferences)
                };
              }),
              map(({organization, permissions, preferences}) => {
                // Set the only organization as current
                this.appManager.emptyCurrentEntityStack();
                this.appManager.currentOrganization = organization;
                this.appManager.entityObservable$.next(true);

                // Set the user permissions once the organization is selected
                this.accessManager.currentUser.organizationPermissions = permissions;

                // Set the user preferences once the organization is selected
                this.userPreferencesService.currentUserPreferences = preferences;

                // Try to load the language file for the org
                // Ignore the error if the file does not exist
                this.translate.addLangs([this.appManager.currentOrganization.id]);

                // notify other guards that organization is loaded
                return organization;
              })
            );
        })
      );
  }

  /**
   * Fetch all accessible by the User Organizations.
   * @return Observable emitting a list of Organizations.
   */
  public loadData(): Observable<Organization[]> {
    this.organizations = [];
    const query = gql`
      query AccessibleOrganizations {
        accessibleOrganizations {
          ...OrganizationInfo
        }
      }
      ${this.organizationsInfoGraphqlFragment}
    `;

    return this.apiService.query({query})
      .pipe(
        map(data => {
          this.organizations = plainToInstance(Organization, data['accessibleOrganizations'] as Organization[]);
          return this.organizations;
        })
      );
  }


  /**
   * Fetch all accessible by the User Organizations with their associated logos.
   * @return Observable emitting a list of Organizations with their associated logos.
   */
  public loadOrganizationsWithLogo(): Observable<OrganizationWithLogo[]> {
    const query = gql`
      query AccessibleOrganizations {
        accessibleOrganizations {
          ...OrganizationInfo
        }
      }
      ${this.organizationsInfoGraphqlFragment}
    `;
    // Get all accessible Organizations by the user.
    return this.apiService.query({query})
      .pipe(
        switchMap(data => {
          return from(plainToInstance(Organization, data['accessibleOrganizations'] as Organization[]))
            .pipe(
              mergeMap(organization => {
                if (!organization.logoDocumentId) {
                  // If the organization doesn't have a logo, only return the organization with a placeholder logo.
                  return of({organization, logoUrl: 'url(' + this.appConfig.PLACEHOLDER_ORGANIZATION + ')'});
                } else {
                  // If the organization has a logo, Load the logo related to the organization.
                  return this.documentsService.getDocument(organization.logoDocumentId)
                    .pipe(
                      // Map the logo document to a style url.
                      switchMap(document => {
                        return this.fileService.getDocumentFileUrl(document.id).pipe(
                          map(documentUrl => {
                            const logoUrl = this.sanitizer.bypassSecurityTrustStyle(`url(${documentUrl})`);
                            return {organization, logoUrl};
                          })
                        );
                      })
                    );
                }
              })
            );
        }),
        toArray(),
        map(organizations => {
          // sort the organizations by name.
          return organizations.sort(({organization: organizationA}, {organization: organizationB}) => {
            return organizationA.name.compareTo(organizationB.name);
          });
        })
      );
  }

  /**
   * Fetch the storage information of an Organization.
   * @param organizationId ID of the Organization.
   * @return An Observable that emits the storage config of the Organization.
   */
  public getOrganizationStorage(organizationId: string): Observable<{ occupiedStorageSpace: number }> {
    const query = gql`
      query OrganizationQuery($organizationId: String!) {
        currentlyUsedStorageSpace(organizationId: $organizationId)
      }
    `;
    const variables = {organizationId};
    return this.apiService.query({query, variables})
      .pipe(
        map(data => {
          return {
            occupiedStorageSpace: data['currentlyUsedStorageSpace'] as number
          };
        })
      );
  }

  /**
   * Get an Organization's JMap credentials.
   * @param organizationId Organization's ID.
   * @return Observable that emits the Organization's JMap credentials.
   */
  public getOrganizationJMapCredentials(organizationId: string): Observable<JMapAuthDetails> {
    const query = gql`
      query OrganizationQuery($organizationId: String!) {
        organizationJmapCredentials(organizationId: $organizationId) {
          url
          projectId
          username
        }
      }
    `;
    const variables = {organizationId};
    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(JMapAuthDetails, data['organizationJmapCredentials'])));
  }


  /**
   * Call the API to create a new organization with the input data.
   * @param organizationInput Organization input data.
   * @return Observable that will emit the new organization.
   */
  public createOrganization(organizationInput: OrganizationInput): Observable<Organization> {
    const mutation = gql`
      mutation CreateOrganization($input: CreateOrganizationInput!) {
        createOrganization(organizationInput: $input) {
          ...OrganizationInfo
        }
      }
      ${this.organizationsInfoGraphqlFragment}
    `;
    const variables = {input: organizationInput};
    return this.apiService.mutate({mutation, variables})
      .pipe(map(data => plainToInstance(Organization, data['createOrganization'])));
  }

  /**
   * API call to update an organization.
   * @param organizationId the Organization ID
   * @param organizationInput the data to update
   * @return the updated Organization
   */
  public updateOrganization(organizationId: string, organizationInput: Record<string, any>): Observable<Organization> {
    const mutation = gql`
      mutation UpdateOrganizationMutation($organizationId: String!, $organizationInput: UpdateOrganizationInput!) {
        updateOrganization(organizationId: $organizationId, organizationInput: $organizationInput) {
          ...OrganizationInfo
        }
      }
      ${this.organizationsInfoGraphqlFragment}
    `;

    const variables = {
      organizationId: organizationId,
      organizationInput: organizationInput
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(map(data => plainToInstance(Organization, data['updateOrganization'])));
  }

  /**
   * API call to update the Organization's JMap credentials.
   * @param organizationId the Organization ID
   * @param jmapCredentialsInput the new jmap credentials
   * @return the updated Organization
   */
  public upsertOrganizationJmapCredentials(
    organizationId: string,
    jmapCredentialsInput: JmapCredentialsInput
  ): Observable<JMapAuthDetails> {
    const mutation = gql`
      mutation UpsertOrganizationJmapCredentials($organizationId: String!, $jmapCredentialsInput: JmapCredentialsInput!) {
        upsertOrganizationJmapCredentials(organizationId: $organizationId, jmapCredentialsInput: $jmapCredentialsInput) {
          id
          url
          projectId
          username
        }
      }
    `;

    const variables = {
      organizationId: organizationId,
      jmapCredentialsInput: jmapCredentialsInput
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(map(data => plainToInstance(JMapAuthDetails, data['upsertOrganizationJmapCredentials'])));
  }

  /**
   * Open a dialog to delete a Organization.
   * @param organization Entity to delete.
   */
  public openDeleteOrganizationDialog(organization: Organization): void {
    const dialogRef = this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant('TITLE.DELETE_ORGANIZATION'),
      dialogMessage: this.translate.instant('MESSAGE.DELETE_ORGANIZATION'),
      okText: this.translate.instant('BUTTON.DELETE_ORGANIZATION'),
      type: 'warning'
    });
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.ORGANIZATION);
      if (dialogResponse === 'yes') {
        analyticsEvent.addProperties({
          [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE,
          [AnalyticsKeyEnum.ENTITY_ID]: organization.id
        });
        this.deleteOrganization(organization);
      } else {
        analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
      }
      this.analyticsService.trackEvent(analyticsEvent);
    });
  }

  /**
   * Delete an existing organization.
   * @param organization Entity to delete.
   */
  public deleteOrganization(organization: Organization): void {
    this.snackbarManager.showInfiniteSnackbar(this.translate.instant('LABEL.DELETE_IN_PROGRESS'));
    this.deleteOrganizationSubject.next(organization);

    const query = gql`
      subscription DeleteOrganizationMutation($id: String!) {
        deleteOrganization(organizationId: $id)
      }
    `;
    const variables = {
      id: organization.id
    };
    this.apiService.subscribe({query, variables})
      .subscribe({
        next: data => {
          if (data['deleteOrganization']) {
            this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DELETE_ORGANIZATION'));
          } else this.snackbarManager.closeSnackbar();
        },
        error: () => {
          this.snackbarManager.closeSnackbar();
        }
      });
  }

  /**
   * Open a dialog to duplicate an existing organization.
   * @param organization to duplicate.
   */
  public openDuplicateOrganizationDialog(organization: Organization): void {
    const dialogRef = this.popupManager.showGenericPopup(DuplicateOrganizationModalComponent, PopupSize.SMALL, {});
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      const analyticsEvent = new AnalyticsEvent(ActionEnum.DUPLICATE, EntityTypeEnum.ORGANIZATION);
      if (dialogResponse === 'yes') {
        analyticsEvent.addProperties({
          [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE,
          [AnalyticsKeyEnum.ENTITY_ID]: organization.id
        });
        const dataObject = dialogRef.componentInstance.getGeneratedObject();
        this.duplicateOrganization(organization.id, dataObject.organizationName);
      } else {
        analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
      }
      this.analyticsService.trackEvent(analyticsEvent);
    });
  }

  /**
   * Duplicate an existing organization.
   * @param organizationId The ID of the organization to duplicate.
   * @param name of the new organization.
   */
  private duplicateOrganization(organizationId: string, name: string): void {
    const query = gql`
      subscription DuplicateOrganization($organizationId: String!, $name: String!) {
        duplicateOrganization(name: $name, organizationId: $organizationId) {
          ...OrganizationInfo
        }
      }
      ${this.organizationsInfoGraphqlFragment}
    `;
    const variables = {
      organizationId: organizationId,
      name: name
    };

    this.snackbarManager.showInfiniteSnackbar(this.translate.instant('LABEL.DUPLICATE_IN_PROGRESS'));

    this.apiService.subscribe({query, variables})
      .pipe(map(data => plainToInstance(Organization, data['duplicateOrganization'])))
      .subscribe({
        next: organization => this.addOrganizationSubject.next(organization),
        complete: () =>
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DUPLICATE_ORGANIZATION')),
        error: () => this.snackbarManager.closeSnackbar()
      });
  }
}
