import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VirtualTour, VirtualTourInput } from '@app/core/model/entities/asset/virtual-tour';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { from, Observable, Subject, tap } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class VirtualToursService {
  private addVirtualTourSubject = new Subject<VirtualTour>();
  private updateVirtualTourSubject = new Subject<VirtualTour>();
  private deleteVirtualToursSubject = new Subject<VirtualTour[]>();

  private readonly virtualTourInfoGraphqlFragment = gql`
    fragment VirtualTourInfo on VirtualTour {
      id
      assetId
      name
      url
      entity
      properties
      computedProperties
      dataDate
    }
  `;

  constructor(private apiService: ApiService,
              private httpClient: HttpClient,
              private appManager: AppManager,
              private assetsService: AssetsService) {
  }

  /**
   * Fetches all Virtual tours belonging to the current Organization from the API.
   */
  public get virtualTours$(): Observable<VirtualTour[]> {
    const query = gql`
      query VirtualToursByOrganizationId($organizationId: String!) {
        virtualToursByOrganizationId(organizationId: $organizationId) {
          ...VirtualTourInfo
        }
      }
      ${this.virtualTourInfoGraphqlFragment}
    `;
    const variables = {organizationId: this.appManager.currentOrganization.id};
    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(VirtualTour, data['virtualToursByOrganizationId'] as VirtualTour[])));
  }

  /**
   * Emits VirtualTour of the current Asset.
   */
  // TODO TTT-2814 merge virtualTours$ and assetVirtualTours$ in a new method
  public get assetVirtualTours$(): Observable<VirtualTour[]> {
    const query = gql`
      query VirtualToursByAssetIds($assetIds: [String!]!) {
        virtualToursByAssetIds(assetIds: $assetIds) {
          ...VirtualTourInfo
        }
      }
      ${this.virtualTourInfoGraphqlFragment}
    `;
    const variables = {assetIds: [this.appManager.currentAsset.id]};

    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(VirtualTour, data['virtualToursByAssetIds'] as VirtualTour[])));
  }

  /**
   * Emits a VirtualTour once after it has been created.
   */
  public get virtualTourAdded$(): Observable<VirtualTour> {
    return this.addVirtualTourSubject.asObservable();
  }

  /**
   * Emits a VirtualTour whenever it has been updated.
   */
  public get virtualTourUpdated$(): Observable<VirtualTour> {
    return this.updateVirtualTourSubject.asObservable();
  }

  /**
   * Emits a list of VirtualTours after they have been deleted.
   */
  public get virtualToursDeleted$(): Observable<VirtualTour[]> {
    return this.deleteVirtualToursSubject.asObservable();
  }

  /**
   * Send a query to the appSheet API
   * @param tableName the table name in the appSheet
   * @param query the query body
   * @param appSheetAPI the appSheet API url
   * @param appSheetKey the appSheet application key
   * @return An observable with the response data
   * @private
   */
  private queryAppSheetAPI(
    tableName: string,
    query: any,
    appSheetAPI: string,
    appSheetKey: string
  ): Observable<Object> {
    const uri = appSheetAPI + tableName + '/Action';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ApplicationAccessKey': appSheetKey
    });
    return this.httpClient.post(uri, query, {headers, responseType: 'json'});
  }

  /**
   * Retrieve all entities from a table in the appSheet.
   * Example: use table name 'BMA' to retrieve all Works
   * @param tableName the table name in the appSheet
   * @param appSheetAPI the appSheet API url
   * @param appSheetKey the appSheet API url
   * @param buildingId id that reference the asset in the appSheet, optional
   * @return An observable with the list of entities
   */
  public getEntities(
    tableName: string,
    appSheetAPI: string,
    appSheetKey: string,
    buildingId?: string
  ): Observable<any> {
    const selector = buildingId ? `Filter(${tableName}, [actifs_id]=${buildingId})` : '';

    const query = {
      Action: 'Find',
      Properties: {
        Selector: selector
      }
    };
    return this.queryAppSheetAPI(tableName, query, appSheetAPI, appSheetKey);
  }

  /**
   * Call the API to create a new Virtual Tour.
   * @param virtualTourInput Data for creating a new VirtualTour.
   * @param assetId ID of the Asset in which to add the VirtualTour.
   * @return Observable emitting the new VirtualTour once the creation is completed.
   */
  public createVirtualTour(virtualTourInput: VirtualTourInput, assetId: string): Observable<VirtualTour> {
    const mutation = gql`
      mutation CreateVirtualTourMutation($assetId: String!, $virtualTourInput: VirtualTourInput) {
        createVirtualTour(assetId: $assetId, virtualTourInput: $virtualTourInput) {
          ...VirtualTourInfo
        }
      }
      ${this.virtualTourInfoGraphqlFragment}
    `;
    const variables = {assetId, virtualTourInput};

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(VirtualTour, data['createVirtualTour'])),
        tap(newVirtualTour => this.addVirtualTourSubject.next(newVirtualTour))
      );
  }

  /**
   * Call the API to update an existing VirtualTour' properties.
   * @param virtualTour VirtualTour to update.
   * @param properties Updated properties.
   * @return Updated VirtualTour.
   */
  public updateVirtualTour(virtualTour: VirtualTour, properties: Record<string, any>): Observable<VirtualTour> {
    const mutation = gql`
      mutation UpdateVirtualTour($virtualTourId: String!, $virtualTourInput: VirtualTourInput!) {
        updateVirtualTour(virtualTourId: $virtualTourId, virtualTourInput: $virtualTourInput) {
          ...VirtualTourInfo
        }
      }${this.virtualTourInfoGraphqlFragment}
    `;
    const variables = {
      virtualTourId: virtualTour.id,
      virtualTourInput: {
        name: virtualTour.name,
        url: virtualTour.url,
        entity: virtualTour.entity,
        ...properties
      }
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(VirtualTour, data['updateVirtualTour'])),
        tap(updatedVirtualTour => this.updateVirtualTourSubject.next(updatedVirtualTour))
      );
  }

  /**
   * Call the API to update the Asset main Virtual Tour.
   * @param assetId Asset's ID
   * @param virtualTourId Virtual Tour's ID
   * @return Updated Virtual Tours
   */
  public setAssetMainVirtualTour(assetId: string, virtualTourId: string): Observable<VirtualTour> {
    const mutation = gql`
      mutation SetAssetMainVirtualTour($assetId: String!, $virtualTourId: String!) {
        setAssetMainVirtualTour(assetId: $assetId, virtualTourId: $virtualTourId) {
          ...VirtualTourInfo
        }
      }${this.virtualTourInfoGraphqlFragment}
    `;
    const variables = {
      assetId: assetId,
      virtualTourId: virtualTourId
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        switchMap(data => from(data['setAssetMainVirtualTour'] as VirtualTour[])),
        map(virtualTour => plainToInstance(VirtualTour, virtualTour)),
        tap(virtualTour => this.updateVirtualTourSubject.next(virtualTour))
      );
  }


  /**
   * Make an API request to delete Virtual Tours.
   * @param virtualTours Virtual Tours to delete.
   * @return True if all VirtualTours have been deleted successfully, false otherwise.
   */
  public deleteVirtualTours(virtualTours: VirtualTour[]): Observable<boolean> {
    const mutation = gql`
      mutation DeleteVirtualTours($virtualTourIds: [String!]!) {
        deleteVirtualTours(virtualTourIds: $virtualTourIds)
      }
    `;
    const variables = {virtualTourIds: virtualTours.map(virtualTour => virtualTour.id)};

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => data['deleteVirtualTours'] as boolean),
        tap(success => {
          if (success) {
            this.deleteVirtualToursSubject.next(virtualTours);
          }
        })
      );
  }

  /**
   * Navigate to the virtual tours tab of the Asset's sheet.
   * @param assetId Id of the Asset to navigate to.
   * @return Promise emitting true if the navigation is a success, otherwise false.
   */
  public async navigateToAssetSheet(assetId: string): Promise<void> {
    await this.assetsService.navigateToAssetSheet(assetId, 'virtual-tour');
  }
}
