import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { Condition, isValidatorFieldDependant } from '@app/core/model/other/field-config';

// Validator type and error codes mapping
const validatorErrorCodes = {
  [ValidatorType.MAX_LENGTH]: 'maxlength',
  [ValidatorType.MIN_VALUE]: 'gte',
  [ValidatorType.MAX_VALUE]: 'lte',
  [ValidatorType.INTEGER]: 'integer',
  [ValidatorType.MAX_NOW]: 'maxNow',
  [ValidatorType.REQUIRED]: 'required',
  [ValidatorType.REALISATION_DATE_IS_BEFORE_TODAY]: 'realisationDateIsBeforeToday',
  [ValidatorType.REALISATION_DATE_IS_AFTER_TODAY]: 'realisationDateIsAfterToday',
  [ValidatorType.RENEW_DATE_IS_AFTER_TODAY]: 'renewDateIsAfterToday',
  [ValidatorType.RENEW_DATE_IS_UNDEFINED]: 'renewDateIsUndefined',
  [ValidatorType.UNIQUE]: 'isValueTaken',
  [ValidatorType.END_DATE_IS_BEFORE_TODAY]: 'endDateIsBeforeToday',
  [ValidatorType.END_DATE_IS_AFTER_TODAY]: 'endDateIsAfterToday',
  [ValidatorType.END_DATE_IS_UNDEFINED]: 'endDateIsUndefined',
  [ValidatorType.REGEX]: 'regex',
  [ValidatorType.DISTINCT]: 'distinct',
  [ValidatorType.GTE_SPACE_CHILDREN_PROPERTY]: 'gteSpaceChildrenProperty',
  [ValidatorType.LTE_SPACE_PARENT_PROPERTY]: 'lteSpaceParentProperty',
  [ValidatorType.ASSET_VALUE_GTE_SPACE_CHILDREN_PROPERTY]: 'assetValueGteSpaceChildrenProperty',
  [ValidatorType.GTE_TOTAL_EXPENSES]: 'gteTotalExpenses',
  [ValidatorType.LTE_TOTAL_COSTS]: 'lteTotalCosts',
  [ValidatorType.IS_SPACE_MOVE_ALLOWED]: 'isSpaceMoveAllowed',
  [ValidatorType.MAX_LENGTH_FILE_NAME]: 'maxLengthFileName',
  [ValidatorType.INCOHERENT_SPACES]: 'incoherentSpaces',
  [ValidatorType.YEAR]: 'year',
  [ValidatorType.DATE]: 'dateISO',
  [ValidatorType.DECIMAL]: 'decimal',
  [ValidatorType.NOT_IN_ARRAY]: 'notInArray',
  [ValidatorType.IN_ARRAY]: 'inArray',
  [ValidatorType.BEFORE_DATE]: 'beforeDate',
  [ValidatorType.AFTER_DATE]: 'afterDate',
  [ValidatorType.EMAIL]: 'email'
};

export class FieldValidator {
  public type: ValidatorType;
  public definition: any;
  public conditionsList?: Condition[];
  public ref?: string;

  /**
   * Error code associated with this validator.
   * This is the field that would be set in the validation errors object returned by a ValidatorFn.
   */
  public get code(): string {
    return validatorErrorCodes[this.type];
  }

  constructor(type: ValidatorType, definition?: any) {
    this.type = type;
    this.definition = definition;
  }

  /**
   * return the definition path as an Array of string
   */
  public get definitionPath(): string[] {
    if (!isValidatorFieldDependant(this.type)) return null;
    return this.definition.split('.');
  }
}
