import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HasStatusType } from '@app/shared/popup/has-status-type';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './error-modal-dialog.component.html',
  styleUrl: './error-modal-dialog.component.scss'
})
export class ErrorModalDialogComponent extends HasStatusType {
  private data = inject(MAT_DIALOG_DATA);
  private translate = inject(TranslateService);
  private authService = inject(AuthService);

  public title = this.data.dialogTitle;
  public code = this.data['code'];
  public message = this.data.dialogMessage;
  public stackTrace? = this.data['stackTrace'];
  public okText = this.data['okText'] ?? this.translate.instant('BUTTON.OK');

  constructor() {
    super('error');
  }

  /**
   * Log out.
   */
  onClickLogout() {
    this.authService.logout().subscribe();
  }
}
