import { inject, Injectable } from '@angular/core';
import { FieldConfig, FieldConfigInput, FormNode } from '@app/core/model/other/field-config';
import { ColumnGroup, ColumnGroupInput, GridConfig } from '@app/core/model/other/grid-config';
import {
  DropLocation
} from '@app/features/main/views/management/organization/field-configuration/form-config-datagrid/form-config-datagrid.component';
import { IIndicatorWidget } from '@app/shared/components/indicator-status-panel/indicator-status-panel.component';
import { getValue } from '@app/shared/extra/utils';
import { ColumnBuilder, ExtendedColDef } from '@app/shared/grid/column-builder';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { ColGroupDef, ColumnState, IRowNode, ValueGetterFunc } from 'ag-grid-community';
import { ColDef, ValueGetterParams } from 'ag-grid-enterprise';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { DocumentNode } from 'graphql';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface DatagridBuilder {
  columnDefs: ExtendedColDef[],
  toolPanelColDefs: ColGroupDef[],
  defaultColumnStates: ColumnState[],
  indicators: IIndicatorWidget[]
}

@Injectable()
export class GridConfigService {

  private readonly gridConfigGraphQlFragment: DocumentNode;

  private appManager: AppManager = inject(AppManager);
  private apiService = inject(ApiService);
  private columnBuilder: ColumnBuilder = inject(ColumnBuilder);
  private deviceDetectorService: DeviceDetectorService = inject(DeviceDetectorService);

  constructor() {
    this.gridConfigGraphQlFragment = gql`
      fragment GridConfigInfo on GridConfig {
        id
        code
        columnGroupsList {
          code
          order
          label
          entityType
          customOptions
          columnsList {
            fieldCode
            order
            customOptions
            field {
              code
              label
              entityType
              fieldType
              checkType
              computed
              parentPathList
              fieldValuesList
              validatorsList {
                conditionsList {
                  field
                  operator
                  value
                }
                definition
                type
              }
            }
            conditionsToViewList {
              field
              operator
              value
            }
            conditionsToEditList {
              field
              operator
              value
            }
          }
        }
        indicatorsList
      }
    `;
  }

  /**
   * Fetch and build a GridConfig to be used for displaying a datagrid.
   * The configuration features the column definitions for the datagrid as well as the tool panel and default column
   * states to be applied.
   * @param code GridConfig's code.
   * @param filter Optional filter to discard specific columns.
   * @return An Observable that will emit the GridConfig.
   */
  public getGridConfig(code: string, filter = (_: FieldConfig): boolean => true): Observable<DatagridBuilder> {
    const query = gql`
      query GridConfig($code: String!, $orgId: String!) {
        gridConfig(code: $code, organizationId: $orgId) {
          ...GridConfigInfo
        }
      }${this.gridConfigGraphQlFragment}
    `;
    const variables = {code, orgId: this.appManager.currentOrganization.id};

    return this.apiService.query({query, variables})
      .pipe(
        map(data => {
          return {
            columnGroups: plainToInstance(
              ColumnGroup,
              data['gridConfig']['columnGroupsList'] as ColumnGroup[]
            ),
            indicators: data['gridConfig']['indicatorsList'] as Record<string, any>[]
          };
        }),
        map(gridData => {
          // Extract indicators from each section
          const indicators = this.buildIndicators(gridData.indicators);

          // Ungroup columns, keep column groups' codes and propertiesPaths
          const columnConfigs = gridData.columnGroups.flatMap(columnGroup => {
            return columnGroup.columns.map(column => {
              return {
                columnGroupCode: columnGroup.code,
                columnGroupLabel: columnGroup.label,
                propertiesPath: columnGroup.customOptions?.propertiesPath,
                column
              };
            });
          })
            // Filter unwanted columns
            .filter(({column}) => filter(column))
            // Reduce columns into gridConfig
            .reduce((gridConfig, {column, columnGroupCode, columnGroupLabel, propertiesPath}) => {
              // Create column definition
              this.buildColumnDef(column, gridConfig.columnDefs, propertiesPath);

              // Create tool panel column definition
              this.buildToolPanelColDef(columnGroupCode, columnGroupLabel, column.code, gridConfig.toolPanelColDefs);

              // Create default column state
              this.buildDefaultColumnState(column, gridConfig.defaultColumnStates);

              return gridConfig;
            }, {columnDefs: [], toolPanelColDefs: [], defaultColumnStates: []} as DatagridBuilder);

          return {...columnConfigs, indicators};
        })
      );
  }

  /**
   * Creates a column group given an input containing information about the column group itself
   * such as the gridConfig it belongs to, its label and the order in which it needs to appear in the grid
   * @param gridConfigId Id of the gridConfig to add the column group to
   * @param columnGroupInput Input containing information about the column group
   * @return Observable containing the newly updated gridConfig with its new column group
   */
  public createColumnGroup(gridConfigId: string, columnGroupInput: ColumnGroupInput): Observable<FormNode[]> {
    const mutation = gql`
      mutation CreateColumnGroup($createColumnGroupInput: ColumnGroupInput!, $gridConfigId: String!, $organizationId: String!) {
        createGridConfigColumnGroup(gridConfigId: $gridConfigId, createColumnGroupInput: $createColumnGroupInput, organizationId: $organizationId){
          ...GridConfigInfo
        }
      }${this.gridConfigGraphQlFragment}
    `;
    const variables = {
      gridConfigId: gridConfigId,
      createColumnGroupInput: columnGroupInput,
      organizationId: this.appManager.currentOrganization.id
    };
    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(GridConfig, data['createGridConfigColumnGroup'])),
        map(forms => this.flattenChildrenRecursively([forms]))
      );
  }

  /**
   * Updates a gridConfig given an input of a few properties
   * @param gridConfigId Id of the gridConfig to update
   * @param columnGroupInput Input containing a new label or new order
   * @return Observable containing the newly updated gridConfig with its updated column group
   */
  public updateColumnGroup(gridConfigId: string, columnGroupInput: ColumnGroupInput): Observable<FormNode[]> {
    const mutation = gql`
      mutation UpdateColumnGroup($gridConfigId: String!, $updateColumnGroupInput: ColumnGroupInput!, $organizationId: String!) {
        updateGridConfigColumnGroup(gridConfigId: $gridConfigId, updateColumnGroupInput: $updateColumnGroupInput, organizationId: $organizationId) {
          ...GridConfigInfo
        }
      }${this.gridConfigGraphQlFragment}
    `;
    const variables = {
      gridConfigId: gridConfigId,
      updateColumnGroupInput: columnGroupInput,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(GridConfig, data['updateGridConfigColumnGroup'])),
        map(forms => this.flattenChildrenRecursively([forms]))
      );
  }

  /**
   * Adds an existing field of the organization to the given grid config
   * @param gridConfigId The GridConfig in which to add a field
   * @param columnGroupCode The column group in which to specifically add the field
   * @param fieldCode The code of the field to add
   * @param order The order in which the field should appear in the GridConfig
   * @param columnInput The input containing the field code and the order in which it needs to appear in the column group
   * @return Observable containing the updated gridConfig
   */
  public addFieldToGridConfig(gridConfigId: string,
                              columnGroupCode: string,
                              fieldCode: string,
                              order: number,
                              columnInput: FieldConfigInput): Observable<FormNode[]> {
    const mutation = gql`
      mutation AddExistingFieldToGridConfig($organizationId: String!, $gridConfigId: String!, $columnGroupCode: String!, $fieldCode: String!, $order: Int!, $columnInput: FieldConfigInput!) {
        addExistingFieldToGridConfig(gridConfigId: $gridConfigId, organizationId: $organizationId, columnGroupCode: $columnGroupCode, fieldCode: $fieldCode, order: $order, columnInput: $columnInput) {
          ...GridConfigInfo
        }
      }${this.gridConfigGraphQlFragment}
    `;
    const variables = {
      organizationId: this.appManager.currentOrganization.id,
      gridConfigId,
      columnGroupCode,
      fieldCode,
      order,
      columnInput
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(GridConfig, data['addExistingFieldToGridConfig'])),
        map(forms => this.flattenChildrenRecursively([forms]))
      );
  }

  /**
   * Get all GridConfigs of an organization.
   * The gridConfigs are then grouped by their form codes and flattened to calculate the data path for Ag-Grid tree
   * @return Observable that emits a list of FormNodes.
   */
  public getAllFlattenedFormNodes(): Observable<FormNode[]> {
    const query = gql`
      query AllGridConfigs($orgId: String!) {
        gridConfigs(organizationId: $orgId) {
          ...GridConfigInfo
        }
      }${this.gridConfigGraphQlFragment}
    `;
    const variables = {
      orgId: this.appManager.currentOrganization.id
    };
    return this.apiService.query({query, variables})
      .pipe(
        map(data => plainToInstance(GridConfig, data['gridConfigs'] as GridConfig[])),
        map(gridConfigs => this.flattenChildrenRecursively(gridConfigs))
      );
  }

  /**
   * Deletes a form node from the grid config datagrid
   * @param nodeToDelete the Ag-Grid row node to remove along with its children
   * @return Observable containing response if the operation was successful
   */
  public deleteGridFormNodes(nodeToDelete: IRowNode): Observable<FormNode[]> {

    const mutation = gql`
      mutation DeleteFormNodes($nodePath: [String]!, $organizationId: String!) {
        deleteGridConfigFormNodes(organizationId: $organizationId, nodePath: $nodePath) {
          ...GridConfigInfo
        }
      }${this.gridConfigGraphQlFragment}
    `;

    const variables = {
      nodePath: nodeToDelete.data.hierarchy,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(GridConfig, data['deleteGridConfigFormNodes'])),
        map(forms => this.flattenChildrenRecursively([forms]))
      );
  }

  /**
   * Moves a certain tree node from a source path to a target path
   * @param source the source node to be moved
   * @param target the target node to which the source node is moved
   * @param placement indication of the placement of the node after it is reordered (defaults to after)
   * @return Observable containing the GridConfig after its columns were reordered
   */
  public reorderGridFormNodes(source: IRowNode,
                              target: IRowNode,
                              placement: DropLocation = 'after'): Observable<FormNode[]> {
    const query = gql`
      mutation ReorderFormNodes($orgId: String!, $source: [String]!, $target: [String]!, $placement: Int!) {
        reorderGridConfigFormNodes(organizationId: $orgId, sourceNodePath: $source, targetNodePath: $target, placement: $placement) {
          ...GridConfigInfo
        }
      }${this.gridConfigGraphQlFragment}
    `;
    const variables = {
      orgId: this.appManager.currentOrganization.id,
      placement: placement == 'before' ? -1 : +1,
      source: source.data.hierarchy,
      target: target.data.hierarchy
    };
    return this.apiService.query({query, variables})
      .pipe(
        map(data => plainToInstance(GridConfig, data['reorderGridConfigFormNodes'] as GridConfig)),
        map(forms => this.flattenChildrenRecursively([forms]))
      );
  }

  /**
   * Calculate if a column should be hidden based on the field config and the type of device used.
   * @param column FieldConfig corresponding to the column.
   * @return A boolean value indicating whether the column should be hidden.
   * @private
   */
  private shouldHideColumn(column: FieldConfig): boolean {
    return this.deviceDetectorService.isMobile() && !column.customOptions?.displayMobile;
  }

  /**
   * Build a ColDef from a column's FieldConfig then pushes it to the provided list of column configurations.
   * @param column FieldConfig corresponding to the column.
   * @param columnDefs ColDef array to push the new column into.
   * @param propertiesPath Properties' path within the FormStateService, if it's not at the root.
   * @private
   */
  private buildColumnDef(column: FieldConfig, columnDefs: ColDef[], propertiesPath?: string): void {
    columnDefs.push(
      this.columnBuilder.createColumn(
        column.field.fieldType,
        column.field.label,
        GridConfigService.createValueGetter(propertiesPath),
        {
          formatType: column.customOptions?.formatType,
          suffix: column.customOptions?.suffixType,
          colId: column.fieldCode,
          hide: this.shouldHideColumn(column),
          pinned: column.customOptions?.pinned ?? false,
          allowedAggFuncs: column.customOptions?.aggFuncs ?? [],
          aggFunc: column.customOptions?.defaultAggFunc ?? null,
          enableValue: column.customOptions?.aggFuncs?.length > 0 ?? false,
          translate: column.customOptions?.translate ?? false,
          fieldConfig: column,
          propertiesPath: column.customOptions?.propertiesPath
        }
      )
    );
  }

  /**
   * Build a ColDef from a FieldConfig to be used for the tool panel, then pushes it to the corresponding group's
   * children within the provided list of column group configurations. If a field group does not exist, it is created
   * before adding the column to its children list.
   * @param groupId ID of the group the field belongs to.
   * @param headerName Label for the column group.
   * @param colId ID of the column to create a ColDef for.
   * @param toolPanelColGroupDefs The ColGroupDef array to push the new column into.
   * @private
   */
  private buildToolPanelColDef(
    groupId: string,
    headerName: string,
    colId: string,
    toolPanelColGroupDefs: ColGroupDef[]
  ): void {
    let colGroup = toolPanelColGroupDefs.find(group => group.groupId === groupId);

    // Create group if it does not already exist in the tool panel
    if (!colGroup) {
      colGroup = {groupId, headerName, children: []};
      toolPanelColGroupDefs.push(colGroup);
    }

    // Add field to group
    colGroup.children.push({colId});
  }

  /**
   * Retrieve the default column state from a FieldConfig, if any, and pushes it to the provided list of column states.
   * @param column FieldConfig corresponding to the column.
   * @param defaultColumnStates List of column states to push the new states into.
   * @private
   */
  private buildDefaultColumnState(column: FieldConfig, defaultColumnStates: ColumnState[]): void {
    if (column.customOptions?.defaultColumnState) {
      defaultColumnStates.push({
        colId: column.fieldCode,
        ...column.customOptions.defaultColumnState
      });
    }
  }

  /**
   * Builds an indicator widget for each of the indicator configurations provided.
   * @param indicatorsData List of indicator configurations.
   * @return List of indicator widgets.
   */
  private buildIndicators(indicatorsData: Record<string, any>[] = []): IIndicatorWidget[] {
    return indicatorsData?.map(config => {
      return {
        label: config['label'],
        icon: config['indicatorIcon'],
        formatType: config['formatType'],
        suffix: config['suffix'],

        computation: config['computation'],
        property: config['property'],
        filters: config['filters'],

        totalValue: 0,
        filteredValue: 0
      };
    }) ?? [];
  }

  /**
   * Common value getter to retrieve the value in an entity.
   * @param propertiesPath Properties' path if it's not root.
   * @private
   */
  private static createValueGetter(propertiesPath?: string): ValueGetterFunc {
    const root = propertiesPath ? [propertiesPath] : [];
    return (params: ValueGetterParams): any => {
      const colDef = params.colDef as ExtendedColDef;
      return !params.node.group
        ? getValue(params.data, root.concat(colDef.fieldConfig.fieldPath))
        : undefined;
    };
  }

  /**
   * Flattens the children of a form node recursively and creates a list of FormNodes
   * containing the data path from the root FormNode to all children
   * @param data the form nodes to flatten
   * @param parent the parent node, if present
   * @param childHierarchy the hierarchy data structure to build
   * @return a list of FormNodes (gridConfigs, columnGroups, columns) containing the hierarchy path from the root FormNode to all children
   */
  private flattenChildrenRecursively(data?: FormNode[], parent = null, childHierarchy = null): FormNode[] {
    let newData = [];
    if (!data) return newData; // Abort early if data is undefined

    data.forEach((initialRow) => {
      let parentHierarchy = [];
      initialRow.hierarchy = parentHierarchy;

      if (parent) {
        initialRow['parent'] = parent;
        parentHierarchy = [...childHierarchy];
        initialRow.hierarchy = parentHierarchy;
      }

      parentHierarchy.push(initialRow['code']);
      newData.push(initialRow);
      switch (initialRow['graphqlTypename']) {
        case 'GridConfig':
          newData = [
            ...newData,
            ...this.flattenChildrenRecursively(
              initialRow['columnGroups'],
              initialRow,
              parentHierarchy
            ),
          ];
          break;
        case 'ColumnGroup':
          newData = [
            ...newData,
            ...this.flattenChildrenRecursively(
              initialRow['columns'],
              initialRow,
              parentHierarchy
            ),
          ];
          break;
        default:
          break;
      }
    });
    return newData;
  }

}
