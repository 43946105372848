import { NgModule } from '@angular/core';
import { AssetHeaderComponent } from '@app/features/main/banner/asset-header/asset-header.component';
import { BannerComponent } from '@app/features/main/banner/banner.component';
import { ClientHeaderComponent } from '@app/features/main/banner/client-header/client-header.component';
import { DefaultHeaderComponent } from '@app/features/main/banner/default-header/default-header.component';
import { EquipmentHeaderComponent } from '@app/features/main/banner/equipment-header/equipment-header.component';
import {
  GlobalBiReportsHeaderComponent
} from '@app/features/main/banner/global-bi-reports-header/global-bi-reports-header.component';
import { HeaderTemplateDirective } from '@app/features/main/banner/header-template.directive';
import { InvestmentHeaderComponent } from '@app/features/main/banner/investment-header/investment-header.component';
import { ProjectHeaderComponent } from '@app/features/main/banner/project-header/project-header.component';
import { ClientsListService } from '@app/features/main/views/management/client/clients-inventory/clients-list.service';
import { SharedModule } from '@app/shared/shared.module';
import { WorkHeaderComponent } from './work-header/work-header.component';

const components = [
  ClientHeaderComponent,
  AssetHeaderComponent,
  EquipmentHeaderComponent,
  WorkHeaderComponent,
  ProjectHeaderComponent,
  InvestmentHeaderComponent,
  GlobalBiReportsHeaderComponent,
  DefaultHeaderComponent,
  BannerComponent
];

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ...components,
    HeaderTemplateDirective
  ],
  providers: [
    ClientsListService
  ],
  exports: [
    ...components
  ]
})
export class BannerRoutingModule {
}
