import { Component } from '@angular/core';
import { CustomCellRendererParams } from '@app/shared/components/cell-renderer/cell-renderer-params';
import { ICellRendererAngularComp } from 'ag-grid-angular';

/**
 * Renderer used to add custom elements to cell.
 */
@Component({
  selector: 'custom-cell-renderer',
  template: `<span [ngStyle]="styles">{{ cellValue }}</span>`
})
export class CustomCellRendererComponent implements ICellRendererAngularComp {

  /**
   * Value to render
   * @type {string}
   */
  public cellValue: string;

  /**
   * Styles to add to cell value
   * @type {{[p: string]: string}}
   */
  public styles: {[p: string]: string};

  /**
   * Called by AG-Grid once for initialization.
   * @param {CustomCellRendererParams} params Params from AG-Grid.
   */
  public agInit(params: CustomCellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    this.styles = params?.styles ?? {};
  }

  /**
   * Retrieves the value to render.
   * @param {CustomCellRendererParams} params Params from ag-grid.
   * @returns {string} The value to render.
   */
  public getValueToDisplay(params: CustomCellRendererParams): string {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }

  /**
   * Used to refresh the value rendered.
   * @param {CustomCellRendererParams} params Params from ag-grid.
   * @returns {boolean} The refreshed value to render.
   */
  public refresh(params: CustomCellRendererParams): boolean {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }
}
