import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Document } from '@app/core/model/entities/document/document';
import { Entity, IRelatedEntity } from '@app/core/model/entities/entity';
import { Expose, Type } from 'class-transformer';

export class Asset extends Entity {
  public objectType = EntityTypeEnum.ASSET;

  public identifier: number;
  public order: number;
  public organizationId: string;
  public typeId: string;

  @Type(() => Document) public pictures: Document[];
  public assetState: string;
  public dataDate: string;

  @Expose()
  public toString(): string {
    return this.computedProperties?.['displayName'] ?? `${this?.identifier} - ${this?.name}`;
  }

  public toRelatedAsset(): RelatedAsset {
    return new RelatedAsset({
      id: this.id,
      typeId: this.typeId,
      name: this.name,
      identifier: this.identifier,
      displayName: this.computedProperties?.['displayName']
    });
  }
}

/**
 * AssetInput for creating or updating Assets.
 */
export interface AssetInput {
  name?: string;
  assetState?: string;
  typeId?: string;
  properties?: Record<string, any>;
  dataDate?: string;
}

export type IRelatedAsset = IRelatedEntity & { typeId: string };

/**
 * Class used by entities that have asset's data in their model data
 */
export class RelatedAsset implements IRelatedEntity {
  public id: string;
  public typeId: string;
  public displayName: string;

  constructor(relatedAsset: IRelatedAsset) {
    this.id = relatedAsset.id;
    this.typeId = relatedAsset.typeId;
    this.displayName = relatedAsset.displayName
      ?? `${relatedAsset.identifier ?? '?'} - ${relatedAsset.name ?? '?'}`;
  }

  @Expose()
  public toString(): string {
    return this.displayName;
  }
}

/**
 * @deprecated Legacy RelatedAsset use in Document.
 * TODO Migrate Document collection in APB to use computed fields ?
 */
export class LegacyRelatedAsset {
  public assetId: string;
  public assetIdentifier: number;
  public assetName: string;
  public assetTypeId: string;

  constructor(assetId: string, assetIdentifier: number, assetName: string, assetTypeId: string) {
    this.assetId = assetId;
    this.assetIdentifier = assetIdentifier;
    this.assetTypeId = assetTypeId;
    this.assetName = assetName;
  }

  /**
   * Transform to RelatedAsset
   * @return RelatedAsset
   */
  public toRelatedAsset(): RelatedAsset {
    return new RelatedAsset({id: this.assetId, typeId: this.assetTypeId, name: this.assetName, identifier: this.assetIdentifier});
  }

  @Expose()
  public toString(): string {
    return `${this.assetIdentifier ?? '?'} - ${this.assetName ?? '?'}`;
  }

  /**
   * Related asset ID. Avoid error when working with entity.
   */
  public get id(): string {
    return this.assetId;
  }
}
