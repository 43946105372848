import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { ClientSubscription, ClientSubscriptionInput } from '@app/core/model/client/client-subscription';
import { Group } from '@app/core/model/client/group';
import { User } from '@app/core/model/client/user';
import { Entity } from '@app/core/model/entities/entity';
import { Expose, Type } from 'class-transformer';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export class Client extends Entity {

  public objectType = EntityTypeEnum.CLIENT;
  public isTb: boolean;
  public organizationId: string;

  @Type(() => Group)
  public groups: Group[];
  @Type(() => User)
  public users: User[];
  @Type(() => ClientSubscription)
  public subscriptions: ClientSubscription[] = [];
  @Type(() => ClientSubscription)
  public subscription: ClientSubscription;

  /**
   * Current ongoing subscription, if any. It is the single subscription that has already started
   * and has not expired yet.
   */
  @Expose()
  public get currentSubscription(): ClientSubscription | null {
    return this.subscriptions.singleOrNull(subscription => {
      return dayjs().isBetween(subscription.startDate, subscription.endDate);
    });
  }

  /**
   * Future subscription, if any. It is the single subscription that has not started yet.
   */
  @Expose()
  public get futureSubscription(): ClientSubscription | null {
    return this.subscriptions.singleOrNull(subscription => subscription.startDate.isAfter(dayjs()));
  }

  /**
   * Most recent subscription, if any. It is the subscription that has the most recently started,
   * and may already have expired.
   */
  @Expose()
  public get mostRecentSubscription(): ClientSubscription | null {
    return this.subscriptions.lastOrNull(subscription => subscription.startDate.isBefore(dayjs()));
  }

  /**
   * Configurable subscription, if any. It is the closest subscription that has not yet expired,
   * and may not have started yet.
   */
  @Expose()
  public get configurableSubscription(): ClientSubscription | null {
    return this.subscriptions.firstOrNull(subscription => subscription.endDate.isAfter(dayjs()));
  }

  @Expose()
  public get displayableSubscription(): ClientSubscription {
    if (this.configurableSubscription) {
      return this.configurableSubscription;
    } else {
      return this.mostRecentSubscription;
    }
  }

  @Expose()
  public get pastSubscriptions(): ClientSubscription[] {
    return this.subscriptions.filter((subscription) => dayjs().isAfter(subscription.endDate));
  }

  @Expose()
  public toString() {
    return this.name;
  }

  /**
   * Update a user from this client users list.
   */
  public updateUser(updatedUser: User) {
    const index = this.users.findIndex(user => user.id === updatedUser.id);
    if (index !== -1) {
      this.users[index] = updatedUser;
    }
  }
}

export type AddUserInput = { id: number, modules?: string[], groupId?: number };

export interface ClientInput {
  name: string,
  subscription: ClientSubscriptionInput
}
