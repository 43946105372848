import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Document } from '@app/core/model/entities/document/document';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationLogoService {

  private organizationLogoSubject: BehaviorSubject<Document> = new BehaviorSubject<Document>(void 0);

  constructor(private apiService: ApiService,
              private appManager: AppManager) {
  }

  /**
   * Retrieves the organization logo.
   *
   * @returns {Document} The organization logo document.
   */
  private get organizationLogo(): Document {
    return this.organizationLogoSubject.value;
  }

  /**
   * Retrieves the organization logo as an Observable.
   *
   * @return {Observable<Document>} An Observable that emits the organization logo document.
   */
  public get organizationLogo$(): Observable<Document> {
    return this.organizationLogoSubject.asObservable();
  }

  public resolve(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<any> {
    return this.loadOrganizationLogo();
  }

  /**
   * Loads the organization logo document.
   *
   * @returns {Observable<Document | null>} Observable that resolves with the loaded document or null if an error occurred.
   */
  public loadOrganizationLogo(): Observable<Document | null> {
    if (!this.appManager.currentOrganization?.logoDocumentId) {
      // No logo
      this.organizationLogoSubject.next(void 0);
      return this.organizationLogo$;
    }

    if (this.organizationLogo?.id === this.appManager.currentOrganization.logoDocumentId) {
      this.organizationLogoSubject.next(this.organizationLogo);
      return this.organizationLogo$;
    }

    const query = gql`
      query OrganizationLogo($docId: String!) {
        document(id: $docId) {
          id
          name
        }
      }
    `;
    const variables = {
      docId: this.appManager.currentOrganization.logoDocumentId
    };
    return this.apiService.query({query, variables})
      .pipe(
        map(data => plainToInstance(Document, data['document'])),
        tap(logo => this.organizationLogoSubject.next(logo)),
        catchError(() => {
          return of(null);
        })
      );
  }

  /**
   * Updates the logo of the organization.
   *
   * @param {Document} logo - The logo document to be updated.
   */
  public updateOrganizationLogo(logo: Document): void {
    return this.organizationLogoSubject.next(logo);
  }
}
