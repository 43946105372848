import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { AbstractFieldBuilder } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import {
  CUSTOM_FORMATS,
  CustomDateAdapter,
  MAT_DAYJS_DATE_ADAPTER_OPTIONS
} from '@app/shared/extra/custom-date-adapter';
import { getValue } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import dayjs, { Dayjs } from 'dayjs';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'date-field-builder',
  templateUrl: './date-field-builder.component.html',
  styleUrls: ['./date-field-builder.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_FORMATS
    }
  ]
})
export class DateFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;
  protected readonly FieldTypeEnum = FieldTypeEnum;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );
  }

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      field: new UntypedFormControl(
        // Ever since Angular 12 and its material update, the value in the formcontrol/input must be of the custom date adapter type
        // Input must be a dayjs compatible value
        this.inputToFormattedString(this.fieldInitValue),
        this.computeValidators()
      )
    });
    // Initialise the field in the registry
    this.setFieldValue(this.inputToFormattedString(this.fieldInitValue));
    this.setFieldInitialValue(this.inputToFormattedString(this.fieldInitValue));
    this.getNextState();
  }

  public ngAfterViewInit(): void {
    this.setupHooks();

    this.form.get('field').valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.setFieldValue(this.inputToFormattedString(value));
      if (this.formStateService.getPath(['AFTER_OTHER_DATE', this.fieldConfig.fieldCode])) {
        this.formStateService.getPath(['AFTER_OTHER_DATE', this.fieldConfig.fieldCode]).next(value);
      }
    });
  }

  public cancel(): void {
    this.form.get('field').setValue(this.inputToFormattedString(this.getFieldInitialValue()));
    super.cancel();
  }

  /**
   * Generate a ValidatorFn with the field's config's validators, except 'AFTER_OTHER_DATE' since it is replaced by an
   * actual afterDate validator when calling setupHooks().
   * @protected
   */
  protected computeValidators(): ValidatorFn {
    return Validators.compose(this.fieldConfig.field?.validators
      .map(validator => this.validationService.getValidator(validator, this.entity))
    );
  }

  /**
   * Setup field hooks. If the field has a validator whose definition depends on another field's value, listen for
   * changes to this field's value and update the validator accordingly.
   * @protected
   */
  protected setupHooks(): void {
    super.setupHooks();

    // Retrieve AFTER_OTHER_DATE validators to be converted to afterDate
    const afterDateFieldValidators = this.fieldConfig.field?.validators
      .filter(validator => validator.type === ValidatorType.AFTER_OTHER_DATE);

    // Setup hook to afterDate validators if they exist on this field
    afterDateFieldValidators.forEach(afterDateFieldValidator => {
      // Create a hook on the value change
      if (!this.formStateService.getPath([afterDateFieldValidator.type, afterDateFieldValidator.definitionPath.last()])) {
        this.formStateService.setPath([
          afterDateFieldValidator.type,
          afterDateFieldValidator.definitionPath.last()
        ], new Subject<Dayjs>());
      }

      // Update afterDate validation when reference field is updated upstream
      const dateValue = getValue(this.entity, afterDateFieldValidator.definitionPath);
      const initialDate = dayjs(dateValue);
      this.formStateService.getPath([afterDateFieldValidator.type, afterDateFieldValidator.definitionPath.last()])
        .pipe(
          startWith(dateValue ? initialDate : ''),
          takeUntil(this.destroy$)
        )
        .subscribe((updatedValue: string) => {
          // Keep everything but current referenced afterDate and afterDateField validators
          this.fieldConfig.field.validators = this.fieldConfig.field.validators
            .filter(validator =>
              validator.ref !== afterDateFieldValidator.definitionPath.last()
            );

          // Push a new afterDate validator with the updated value and its reference
          this.fieldConfig.field?.validators.push(
            this.validationService.convertFieldDependantValidator(
              afterDateFieldValidator,
              this.inputToFormattedString(updatedValue)
            )
          );

          // Skip re-adding the fieldtype-specific validators
          this.form.get('field').setValidators(this.computeValidators());
          this.form.get('field').updateValueAndValidity({onlySelf: false, emitEvent: false});
          this.form.get('field').markAsTouched({onlySelf: false});
        });
    });
  }

  /**
   * Reformat a string-represented date
   * @param value
   * @private
   */
  private inputToFormattedString(value: string): string {
    if (!value || !dayjs(value).isValid()) return '';

    // For DATE fields, use app date format, otherwise use standard ISO-8601 format
    const format = this.fieldConfig.field.fieldType === FieldTypeEnum.DATE ? this.appConfig.DATE_FORMAT : undefined;
    return dayjs(value).format(format);
  }
}
