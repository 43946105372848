import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentStateEnum } from '@app/core/enums/document/document-state.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Document } from '@app/core/model/entities/document/document';
import { Work, WorkInput } from '@app/core/model/entities/works/work';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { WorkModalService } from '@app/features/main/views/works/works-inventory/modals/work-modal.service';
import { WorksService } from '@app/features/main/views/works/works.service';
import { FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ImageDocument } from '@app/shared/extra/image-document';
import { getPictureComparator } from '@app/shared/extra/utils';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@services/file.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BaseWorkSheet implements OnInit, OnDestroy {

  public work?: Work;
  public hasDocuments: boolean = false;
  public images: ImageDocument[] = [];

  public Permission = PermissionEnum;
  public permissionsForEdition = [PermissionEnum.EDIT_WORK];

  protected destroy$ = new Subject<void>();
  protected photosLoaded$ = new Subject<void>();

  protected accessManager = inject(AccessManager);
  protected appConfig = inject(AppConfig);
  protected fileService = inject(FileService);
  protected worksService = inject(WorksService);
  protected appManager = inject(AppManager);
  protected analyticsService = inject(AnalyticsService);
  protected formStateService = inject(FormStateService);
  protected translate = inject(TranslateService);
  protected snackbarManager = inject(SnackbarManager);
  protected documentsService = inject(DocumentsService);
  protected documentModalService = inject(DocumentModalService);
  protected workModalService = inject(WorkModalService);


  /**
   * Listen for inputs and update Work entity when necessary.
   */
  public ngOnInit(): void {
    // Listen for properties updates
    this.formStateService.saved$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(({code, data}) => {
          Work.transformPropertiesForInput(data);
          return this.worksService.updateWork(this.work, data as WorkInput)
            .pipe(
              tap(updatedWork => {
                this.work = updatedWork;

                // force computed fields to recalculate
                this.formStateService.getPath(['computed']).next();
                // release the SAVING state to the next state
                this.formStateService.getPath([code, 'state']).next(FieldStateMode.AFTER_SAVE);
              }),
              // Resubscribe to the saved observable if an error occurred.
              catchError(() => {
                this.formStateService.getPath([code, 'state']).next(FieldStateMode.ERROR);
                return this.formStateService.saved$;
              })
            );
        })
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EDIT_SAVED'));
      });
  }

  /**
   * Load the current Work entity's pictures and documents.
   * @param fetchOnePicture define if only one picture is fetch
   * @protected
   */
  protected loadWorkData(fetchOnePicture: boolean = false): void {
    if (this.work) {
      // Load Work's documents
      this.documentsService.loadEntityDocuments(this.work.id, EntityTypeEnum.WORK, DocumentTypeEnum.WORK_DOCUMENT)
        .pipe(
          takeUntil(this.destroy$),
          tap(documents => {
            this.work.documents = documents;
            this.hasDocuments = documents.length > 0;
          })
        )
        .subscribe();

      // Load Work's pictures
      this.documentsService.loadEntityDocuments(this.work.id, EntityTypeEnum.WORK, DocumentTypeEnum.WORK_PICTURE)
        .pipe(
          takeUntil(this.destroy$),
          tap(pictures => {
            this.work.pictures = pictures;
            this.fetchPictureFiles(this.work.pictures, fetchOnePicture);
          })
        )
        .subscribe();
    }
  }

  /**
   * Stop listening to Observables.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Open a dialog to upload Documents and link updated Documents to the Work entity.
   */
  public uploadWorkDocuments(): void {
    this.workModalService.openUploadWorkDocumentsDialog(this.work.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(documents => {
        this.work.documents.push(...documents);
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_UPLOADED'));
      });
  }

  /**
   * Download a document.
   * @param documentId ID of the document.
   */
  public downloadWorkDocument(documentId: string): void {
    this.fileService.downloadFile(documentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /**
   * Open a dialog to upload pictures and link updated pictures to the Work entity.
   */
  public uploadWorkPictures(): void {
    this.workModalService.openUploadWorkPicturesDialog(this.work.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(pictures => {
        if (pictures.length > 0) {
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.PICTURE_UPLOADED'));

          // If Work has no pictures, set the first one to be uploaded to be the main one
          if (!this.work.pictures || this.work.pictures.length === 0) {
            this.work.properties['mainPictureDocumentId'] = pictures[0].id;
          }

          // Set Work's pictures and fetch image files' URLs
          this.work.pictures.push(...pictures);
          this.fetchPictureFiles(pictures);
        }
      });
  }

  /**
   * Open a dialog asking for confirmation to delete a document linked to the Work entity.
   * @param document Document to delete.
   */
  public deleteWorkDocument(document: Document): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SHEET,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.DOCUMENT,
      document.id
    );
    this.workModalService.openDeleteWorkDocumentDialog(document)
      .pipe(
        takeUntil(this.destroy$),
        filter(result => result)
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_DELETED'));
        this.work.documents = this.work.documents.filter(doc => doc.id != document.id);
      });
  }

  /**
   * Retrieve image URLs corresponding to the picture documents to be displayed in the gallery.
   * @param documents Documents whose files' URLs should be fetched.
   * @param fetchOnePicture define if only one picture is fetch
   * @private
   */
  protected fetchPictureFiles(documents: Document[], fetchOnePicture: boolean = false): void {
    // Filter archived documents out and place main picture at first position
    const activePictures = documents.filter(picture => picture.documentState === DocumentStateEnum.ACTIVE)
      .sort(getPictureComparator(this.work.properties['mainPictureDocumentId']));

    if (activePictures.length > 0) {
      const documentIds = fetchOnePicture ? [activePictures.first().id] : activePictures.map(picture => picture.id);
      // Fetch images' URLs, update images list then notify loading is completed
      this.fileService.getDocumentFileUrls(documentIds)
        .pipe(
          map(urls => {
            this.images.push(...activePictures.map(picture => {
              return {
                id: picture.id,
                url: urls[picture.id],
                document: picture
              };
            }));
            this.photosLoaded$.next();
          })
        )
        .subscribe();
    } else {
      this.photosLoaded$.next();
    }
  }
}
