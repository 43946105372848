import { AsyncPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { ErrorPageNotFoundComponent } from '@app/features/errors/error-page-not-found/error-page-not-found.component';
import {
  ErrorSubscriptionExpiredComponent
} from '@app/features/errors/error-subscription-expired/error-subscription-expired.component';
import { ErrorUnauthorizedComponent } from '@app/features/errors/error-unauthorized/error-unauthorized.component';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const components = [
  ErrorPageNotFoundComponent,
  ErrorSubscriptionExpiredComponent,
  ErrorUnauthorizedComponent
];

@NgModule({
  imports: [
    TranslateModule,
    MatButtonModule,
    RouterLink,
    AsyncPipe,
    MatIcon
  ],
  providers: [
    TranslateService,
    AuthService,
    Title
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components
  ]
})
export class ErrorRoutingModule {
}
