import { Component, ElementRef, inject, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { PowerBiReportConfig } from '@app/core/model/other/power-bi';
import { TokenRefreshService } from '@app/shared/services/token-refresh.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { PowerBiService } from '@services/power-bi.service';
import { IReportEmbedConfiguration } from 'embed';
import { Subject } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'power-bi-report',
  templateUrl: './power-bi-report.component.html',
  styleUrls: ['./power-bi-report.component.scss']
})
export class PowerBiReportComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public workspaceId: string;
  @Input() public reportId: string;
  @Input() public reportDefaultPage: string;
  @Input() public assetId?: string;
  @ViewChild('powerBi') public powerBi: ElementRef;
  @Input() public relatedEntityType: EntityTypeEnum;
  public powerBiReportConfig: PowerBiReportConfig & IReportEmbedConfiguration;
  public exportInProgress = false;
  private destroy$ = new Subject<void>();
  public Permission = PermissionEnum;

  private powerBiService = inject(PowerBiService);
  private snackbar = inject(SnackbarManager);
  private translate: TranslateService = inject(TranslateService);
  private analyticsService = inject(AnalyticsService);
  private accessManager = inject(AccessManager);
  private tokenRefreshService = inject(TokenRefreshService);

  /**
   * Load the BI report on init.
   */
  public ngOnInit(): void {
    this.tokenRefreshService.startTokenRefresh();
  }

  /**
   * Reload the BI report when the input changes.
   */
  public ngOnChanges(): void {
    this.powerBiReportConfig = null;
    this.loadPowerBIReportConfig();
  }

  /**
   * Load the configuration of the BI report.
   */
  private loadPowerBIReportConfig() {
    this.powerBiService.loadPowerBiReportConfig(this.workspaceId, this.reportId).pipe(first()).subscribe(
      powerBiReportConfig => {
        // Filter by Users
        powerBiReportConfig.filters.push(PowerBiReportConfig.buildFilter(
          'user',
          'id',
          this.accessManager.currentUser.id
        ));

        // Filter by Asset
        if (!!this.assetId) {
          powerBiReportConfig.filters.push(PowerBiReportConfig.buildFilter('assets', '_id', this.assetId));
        }

        this.powerBiReportConfig = powerBiReportConfig;
        this.powerBiReportConfig.pageName = this.reportDefaultPage;
        this.analyticsService.trackEvent(new AnalyticsEvent(ActionEnum.READ, this.relatedEntityType)
          .addProperties({
            [AnalyticsKeyEnum.REPORT_TYPE]: EntityTypeEnum.REPORT,
            [AnalyticsKeyEnum.REPORT_ID]: this.reportId
          }));
      });
  }

  /**
   * Call the service to print the Power BI report in pdf.
   */
  public exportBiReport(): void {
    this.powerBiService.exportPowerBiReports(
      this.workspaceId,
      this.reportId,
      this.powerBiReportConfig.filters
    )
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.analyticsService.trackEvent(
            new AnalyticsEvent(ActionEnum.EXPORT, this.relatedEntityType)
              .addProperties({
                [AnalyticsKeyEnum.REPORT_TYPE]: EntityTypeEnum.REPORT,
                [AnalyticsKeyEnum.REPORT_ID]: this.reportId
              })
          );
        })
      )
      .subscribe(() =>
        this.snackbar.showActionSnackbar(this.translate.instant('SUCCESS.SEND_EMAIL_EXPORT_REPORTS'))
      );
  }

  /**
   * Toggle full screen mode for the Power BI report
   */
  public toggleFullScreen(): void {
    !document.fullscreenElement ? this.powerBi.nativeElement.requestFullscreen() : document.exitFullscreen();
  }

  /**
   * Print the PowerBI report
   */
  public print(): void {
    window.print();
  }

  /**
   * Destroy all charts and end subscriptions
   */
  public ngOnDestroy(): void {
    this.tokenRefreshService.stopTokenRefresh();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
