import { Component, HostListener, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Work } from '@app/core/model/entities/works/work';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { BaseWorkSheet } from '@app/features/main/views/works/work-sheet/base-work-sheet';
import { WorkModalService } from '@app/features/main/views/works/works-inventory/modals/work-modal.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { FieldService } from '@services/field.service';
import { of } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'works-sidebar',
  templateUrl: './works-sidebar.component.html',
  providers: [WorkModalService, DocumentModalService]
})
export class WorksSidebarComponent extends BaseWorkSheet implements OnInit {

  protected eventsOrigin = EventOriginEnum.SIDEPANEL;
  protected validators: FieldValidator[] = [];

  private fieldService = inject(FieldService);
  private singleEditService = inject(SingleEditService);
  private router = inject(Router);

  /**
   * Listen for sidebar toggle actions and updates from the FormStateService. Update sidebar data and call Work service
   * when necessary.
   */
  public ngOnInit(): void {
    super.ngOnInit();

    // Update the side panel's content whenever a new Work is selected
    this.worksService.sidePanelToggle$
      .pipe(
        takeUntil(this.destroy$),
        switchMap( workData => {
          return this.fieldService.getField('action', EntityTypeEnum.WORK)
            .pipe(
              map(field => {
                this.validators = field.validators;
                return workData;
              })
            );
        }),
        switchMap(workData => {
          if (workData) {
            // There is a Work to display, listen to external changes to this Work to update the side panel data
            return this.worksService.workUpdated$
              .pipe(
                takeUntil(this.destroy$),
                filter(updatedWork => updatedWork.id === workData.id),
                startWith(workData)
              );
          } else {
            // There is no Work to display, the side panel is closed, just propagate the null value
            return of(workData);
          }
        }),
        tap(() => {
          this.singleEditService.singleEditSubject.next(null);

          // Unload current Work
          if (this.appManager.currentEntity instanceof Work) {
            this.appManager.unloadCurrentEntity();
          }
        }),
        filter(work => !!work)
      )
      .subscribe(work => {
        this.work = work;
        this.images = [];
        this.appManager.currentEntity = work;
        this.loadWorkData(true);
      });
  }

  /**
   * Open the delete Work dialog then delete current Work entity if the user confirms.
   */
  public deleteWork(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.WORK,
      this.work.id
    );
    this.workModalService.openDeleteWorksDialog([this.work])
      .pipe(
        takeUntil(this.destroy$),
        filter(result => !!result)
      )
      .subscribe(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.WORK_DELETED')));
  }

  /**
   * Open the duplicate Work dialog then copy current Work.
   */
  public duplicateWork(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DUPLICATE_DIALOG,
      EntityTypeEnum.WORK,
      this.work.id
    );
    this.workModalService.openDuplicateWorksDialog([this.work])
      .pipe(
        takeUntil(this.destroy$),
        filter(result => !!result)
      )
      .subscribe((works) => {
        this.router.navigate([
          'organization',
          this.appManager.currentOrganization.id,
          'works',
          'work-sheet',
          works[0].id
        ])
          .catch(console.error);
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.WORK_DUPLICATED'));
      });
  }

  /**
   * Close the sidebar.
   */
  public closeWorkSidebar(): void {
    this.worksService.closeWorkSidePanel();
  }

  /**
   * Bypass Material escape handler to call closeWorkSidebar,
   * which manage the current entity stacks.
   * @param event
   */
  @HostListener('keyup', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeWorkSidebar();
    }
  }

  /**
   * Navigate to the Work's sheet.
   */
  public async navigateToWorkSheet(): Promise<void> {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.SHEET,
      EntityTypeEnum.WORK,
      this.work.id
    );
    await this.worksService.navigateToWorkSheet(this.work.id);
  }

  /**
   * Open a dialog to upload Documents and link updated Documents to the Work entity.
   */
  public uploadWorkDocuments(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.UPLOAD_DIALOG,
      EntityTypeEnum.WORK,
      this.work.id
    );
    super.uploadWorkDocuments();
  }

  /**
   * Open a dialog for the user to upload pictures of the Work.
   */
  public uploadWorkPictures(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.UPLOAD_DIALOG,
      EntityTypeEnum.WORK,
      this.work.id
    );
    super.uploadWorkPictures();
  }
}
