import {Component, inject, Input} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {AppConfig} from '@app/core/app.config';
import {EntityTypeEnum} from '@app/core/enums/entity-type.enum';
import {InvestmentInput} from '@app/core/model/entities/investment/investment';
import {GeneratesObject} from '@app/shared/interfaces/generates-object';
import {Entity} from "@app/core/model/entities/entity";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'investment-create-modal',
    templateUrl: './investment-create-modal.component.html'
})
export class InvestmentCreateModalComponent implements GeneratesObject {

    @Input() private data: { entity: Entity, entityType: EntityTypeEnum } = inject(MAT_DIALOG_DATA);

    protected nameForm: FormControl<string>;

    protected appConfig = inject(AppConfig);
    private fb = inject(FormBuilder);

    constructor() {
        this.nameForm = this.fb.control(null, Validators.compose([
            Validators.required,
            Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)
        ]));
    }

    public getGeneratedObject(): InvestmentInput {
        return {
            name: this.nameForm.value,
            type: this.data.entityType,
            entityId: this.data.entity.id,
        };
    }
}
