import { inject, Injectable } from '@angular/core';
import { AssetType, AssetTypeInput } from '@app/core/model/entities/asset/asset-type';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { Observable, Subject, tap } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Service to manage Asset Types.
 */
@Injectable()
export class AssetTypesService {

  // GraphQL fragment for asset type information
  private readonly assetTypeInfoGraphqlFragment = gql`
    fragment AssetTypeInfo on AssetType {
      id
      name
      modulesList
    }
  `;

  private addAssetTypeSubject = new Subject<AssetType>();

  private appManager = inject(AppManager);
  private apiService = inject(ApiService);

  /**
   * Make an API request to fetch Asset Types linked to the current Organization.
   * @return Observable emitting a list of all Asset Types.
   */
  public get assetTypes$(): Observable<AssetType[]> {
    const query = gql`
      query AssetTypes($organizationId: String!) {
        assetTypes(organizationId: $organizationId) {
          ...AssetTypeInfo
        }
      }
      ${this.assetTypeInfoGraphqlFragment}
    `;
    const variables = {
      organizationId: this.appManager.currentOrganization.id
    };
    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(AssetType, data['assetTypes'] as AssetType[])));
  }

  /**
   * Emits a Asset Type once after it has been created.
   */
  public get assetTypeAdded$(): Observable<AssetType> {
    return this.addAssetTypeSubject.asObservable();
  }

  /**
   * Make an API request to create new Asset Type with the provided data.
   * @param assetTypeInput Data for creating a new the Asset Type.
   * @return Observable emitting the new Asset Type that was created.
   */
  public createAssetType(assetTypeInput: AssetTypeInput): Observable<AssetType> {
    const mutation = gql`
      mutation CreateAssetType($organizationId: String!, $assetTypeInput: AssetTypeInput!) {
        createAssetType(organizationId: $organizationId, assetTypeInput: $assetTypeInput) {
          ...AssetTypeInfo
        }
      }
      ${this.assetTypeInfoGraphqlFragment}
    `;
    const variables = {
      organizationId: this.appManager.currentOrganization.id,
      assetTypeInput
    };
    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(AssetType, data['createAssetType'])),
        tap(newAssetType => this.addAssetTypeSubject.next(newAssetType))
      );
  }

  /**
   * Make an API request to update an existed Asset Type with the provided data.
   * @param assetTypeId ID of the Asset Type to update.
   * @param assetTypeInput Data for updating the Asset Type.
   * @return Observable emitting the updated Asset Type.
   */
  public updateAssetType(assetTypeId: string, assetTypeInput: AssetTypeInput): Observable<AssetType> {
    const mutation = gql`
      mutation UpdateAssetType($assetTypeId: String!, $assetTypeInput: AssetTypeInput!) {
        updateAssetType(assetTypeId: $assetTypeId, assetTypeInput: $assetTypeInput) {
          ...AssetTypeInfo
        }
      }
      ${this.assetTypeInfoGraphqlFragment}
    `;
    const variables = {
      assetTypeId,
      assetTypeInput
    };
    return this.apiService.mutate({mutation, variables})
      .pipe(map(data => plainToInstance(AssetType, data['updateAssetType'])));
  }

  /**
   * Make an API request to delete Asset Type.
   * @param assetTypeId ID of the Asset Type to delete.
   * @return True if the Asset Type have been deleted successfully, false otherwise.
   */
  public deleteAssetType(assetTypeId: string): Observable<boolean> {
    const mutation = gql`
      mutation DeleteAssetType($assetTypeId: String!) {
        deleteAssetType(assetTypeId: $assetTypeId)
      }
    `;
    const variables = {
      assetTypeId
    };
    return this.apiService.mutate({mutation, variables})
      .pipe(map(data => data['deleteAssetType']));
  }
}
