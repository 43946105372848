export const environment = {
  name: 'dev',
  production: true,
  auth0: {
    domain: 'auth.dev.tbmaestro.net',
    clientId: '3Q3j4t6KA1fi8bBD3oYp0TOeGdLQaVZe',
    audience: 'https://backend.dev.tbmaestro.net'
  },
  backend: {
    baseUrl: 'https://backend.dev.tbmaestro.net',
    websocketUrl: 'wss://backend.dev.tbmaestro.net',
    graphql: {
      endpoint: '/graphql',
    },
    jmap: {
      endpoint: '/jmapauth'
    }
  },
  profile: {
    accountsPortal: 'https://accounts.dev.tbmaestro.net'
  },
  apiKey: {
    googleMaps: 'AIzaSyCYPvVQxK48seq3RMQGKC760B3g9a4vXvM'
  },
  scripts: {
    jmapUrl: 'https://jmap.dev.tbmaestro.net/jmap',
    metarealSDK: 'https://stage.metareal.com/playersdk/playersdk.min.1.0.js'
  },
  logRocket: {
    enabled: true,
    appId: 'avsvs0/mya-dev'
  }
};
