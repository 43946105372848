import { LeaseStatusEnum } from '@app/core/enums/asset-occupant/lease-status-enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Occupant } from '@app/core/model/entities/asset/occupant';
import { Document } from '@app/core/model/entities/document/document';
import { Entity } from '@app/core/model/entities/entity';
import { getValue, hasProperty, setValue } from '@app/shared/extra/utils';
import { Expose, Transform, Type } from 'class-transformer';

export class Lease extends Entity {
  public objectType = EntityTypeEnum.LEASE;

  public assetId: string;
  public occupantId: string;

  public dataDate: string;

  @Expose({name: 'spaceIdsList'})
  public spaceIds: string[];
  @Type(() => Occupant) public occupant?: Occupant;
  @Type(() => Document) public documents: Document[] = [];
  @Transform(fn => LeaseStatusEnum[fn.value]) public status: LeaseStatusEnum;

  @Expose()
  public toString(): string {
    return this.status.toString();
  }

  // Properties to be renamed
  protected static inputMapping = {
    spaces: {input: ['computedProperties', 'spaces'], output: 'spaceIds'}
  };

  /**
   * Some GraphQL inputs require different keys than those available in the Form State Service
   * since those keys correspond to field codes, so we need to replace them accordingly
   * @param obj the Graphql input object
   */
  public static transformPropertiesForInput(obj: Record<string, unknown>): void {
    // Transform input, mapping keys as defined in the inputMapping above
    for (const inputMappingElement in Lease.inputMapping) {
      if (hasProperty(obj, Lease.inputMapping[inputMappingElement].input)) {
        const source = getValue(obj, Lease.inputMapping[inputMappingElement].input);
        setValue(obj, [Lease.inputMapping[inputMappingElement].output], source);
      }
    }

    // Clean up by removing older keys
    for (const inputMappingElement in Lease.inputMapping) {
      delete obj[Lease.inputMapping[inputMappingElement].input.first()];
    }
  }
}

export interface LeaseInput {
  spaceIds?: string[];
  dataDate?: string;
}
