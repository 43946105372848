import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { ClientInput } from '@app/core/model/client/client';
import { Module } from '@app/core/model/client/module';
import { Organization } from '@app/core/model/entities/organization/organization';
import {
  CUSTOM_FORMATS,
  CustomDateAdapter,
  MAT_DAYJS_DATE_ADAPTER_OPTIONS
} from '@app/shared/extra/custom-date-adapter';
import { AbstractModalDialog } from '@app/shared/interfaces/abstract-modal-dialog';
import { GeneratesObject } from '@app/shared/interfaces/generates-object';
import { OrganizationsService } from '@app/shared/services/organizations.service';
import { ClientNameReset } from '@app/shared/store/reducers/client-name.actions';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import { Store } from '@ngrx/store';
import ApiService from '@services/api.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import dayjs from 'dayjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './client-create-modal.component.html',
  styleUrls: ['./client-create-modal.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_FORMATS
    }
  ]
})
export class ClientCreateModalComponent extends AbstractModalDialog implements OnInit, OnDestroy, GeneratesObject {
  public clientNameForm: UntypedFormGroup;

  public clientSubscriptionsForm: FormGroup<{
    subscriptionStart: FormControl<Date>,
    subscriptionDuration: FormControl<number>,
    subscriptionNbAuthorizedAccounts: FormControl<number>,
    subscriptionContractId: FormControl<string>,
    subscriptionModules: FormArray<FormGroup<{
      id: FormControl<number>,
      active: FormControl<boolean>,
      moduleId: FormControl<number>,
      name: FormControl<string>,
      nbLicences: FormControl<number>,
      nbUsedLicences: FormControl<number>,
      limitedDuration: FormControl<boolean>,
      isDefault: FormControl<boolean>
    }>>
  }>;

  public organizationsList: Organization[];
  public title = 'TITLE.CREATE_CLIENT';
  private createQuery: boolean;

  constructor(public appConfig: AppConfig,
              public appManager: AppManager,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiService: ApiService,
              private organizationsService: OrganizationsService,
              private store: Store<any>,
              private fb: UntypedFormBuilder) {
    super();

    this.createQuery = false;
    this.hideSpinner = (): boolean => this.createQuery;

    // Load organizations list
    this.organizationsService.loadData().subscribe(
      (response) => {
        // set organization list
        this.organizationsList = response.sort(
          (organizationA, organizationB) => organizationA.name.localeCompare(organizationB.name)
        );
      }
    );
  }

  public ngOnInit(): void {
    /*
    Form to edit the client name
     */
    this.clientNameForm = this.fb.group({
      clientName: this.fb.control(
        '',
        Validators.compose([Validators.required, Validators.maxLength(this.appConfig.NAME_MAX_LENGTH)]),
        ExtraValidators.isValueTaken(this.apiService, EntityTypeEnum.CLIENT, 'name', '', {}, {
          store: this.store,
          fieldName: 'clientName'
        })
      )
    });
    if (this.appManager.currentOrganization == null) {
      this.clientNameForm.addControl('organization', this.fb.control('', Validators.compose([Validators.required])));
    }

    /*
    Form to edit the client subscription
     */
    this.clientSubscriptionsForm = this.fb.group({
      subscriptionStart: this.fb.control('', Validators.required),
      subscriptionDuration: this.fb.control('', Validators.compose([
        Validators.required,
        ExtraValidators.number,
        ExtraValidators.gte(1)
      ])),
      subscriptionNbAuthorizedAccounts: this.fb.control('', Validators.compose([
        Validators.required,
        ExtraValidators.number,
        ExtraValidators.gte(1)
      ])),
      subscriptionContractId: this.fb.control(null, Validators.maxLength(this.appConfig.CONTRACT_ID_MAX_LENGTH)),
      subscriptionModules: this.fb.array([])
    });

    /*
    Request to get all modules
     */
    const query = gql`
      query ModuleList {
        modules(onlySubscribable: true) {
          id
          code
          order
          isDefault
        }
      }
    `;
    this.apiService.query({query})
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.createQuery = true;
        const modules = plainToInstance(Module, data['modules'] as Module[]);

        this.initialiseForm(modules);
      });
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new ClientNameReset({currentValue: '', originalValue: ''}));

    // Prevent memory leak
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Date picker filter to block past days
   * @param {Date} d
   * @returns {boolean}
   */
  public datepickerFilter(d: any): boolean {
    // Compares the 'filtered' date to the start of today (12:00 am)
    return dayjs(d).isSame(dayjs().startOf('day')) || dayjs(d).isAfter(dayjs().startOf('day'));
  }

  private initialiseForm(modules: Module[]): void {
    const moduleFormArray = this.fb.array(modules.sort((moduleA, moduleB) =>
      moduleA.order.compareTo(moduleB.order)
    ).map((module: Module) => {
      const moduleItem = this.fb.group({
        active: this.fb.control({value: module.isDefault, disabled: module.isDefault}),
        id: this.fb.control(module.id),
        name: this.fb.control(module.code),
        nbLicences: this.fb.control(
          {value: '', disabled: true},
          Validators.compose([Validators.required, ExtraValidators.number])
        ),
        limitedDuration: this.fb.control({value: false, disabled: true}),
        isDefault: this.fb.control(module.isDefault)
      });

      // Change detection for the module checkboxes
      moduleItem.get('active').valueChanges.pipe(debounceTime(50), takeUntil(this.destroy$)).subscribe((value) => {
        if (value) {
          // Enable validation on the number of licences field
          moduleItem.get('nbLicences').enable();
          moduleItem.get('nbLicences').markAsTouched();

          // If number of licences was not previously set, default to 1
          if (moduleItem.get('nbLicences').value === '') {
            moduleItem.get('nbLicences').setValue(1);
          }

        } else {
          // Disable validation
          moduleItem.get('nbLicences').disable();

          // Reset fields
          moduleItem.get('nbLicences').reset('');
          moduleItem.get('limitedDuration').reset(false);
        }
      });

      return moduleItem;
    }));

    this.clientSubscriptionsForm.setControl('subscriptionModules', moduleFormArray);
  }

  /**
   * Parse the form's data to extract data for creating a Client and its initial subscription.
   * @returns Input data for a Client and initial Subscription.
   */
  public getGeneratedObject(): {organizationId: string, client: ClientInput} {
    const startDate = dayjs(this.clientSubscriptionsForm.value.subscriptionStart);
    const endDate = startDate.add(this.clientSubscriptionsForm.value.subscriptionDuration, 'month');

    return {
      organizationId: this.appManager.currentOrganization
        ? this.appManager.currentOrganization.id
        : this.clientNameForm.get('organization').value,
      client: {
        name: this.clientNameForm.get('clientName').value,
        subscription: {
          startDate: startDate.format(),
          endDate: endDate.format(),
          nbUser: this.clientSubscriptionsForm.value.subscriptionNbAuthorizedAccounts,
          contractId: this.clientSubscriptionsForm.value.subscriptionContractId,
          subscriptionModules: this.clientSubscriptionsForm.controls.subscriptionModules.controls
            .filter(moduleFormGroup => moduleFormGroup.controls.active.value)
            .map(moduleFormGroup => {
              const moduleEndDate = moduleFormGroup.value.limitedDuration ? startDate.add(1, 'month') : endDate;
              return {
                startDate: startDate.format(),
                endDate: moduleEndDate.format(),
                limitedDuration: moduleFormGroup.controls.limitedDuration.value,
                nbLicence: moduleFormGroup.controls.isDefault.value
                  ? this.clientSubscriptionsForm.controls.subscriptionNbAuthorizedAccounts.value
                  : moduleFormGroup.controls.nbLicences.value,
                moduleId: moduleFormGroup.value.id,
                userIds: []
              };
            })
        }
      }
    };
  }

}
