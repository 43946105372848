import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Asset, AssetInput, RelatedAsset } from '@app/core/model/entities/asset/asset';
import { Document } from '@app/core/model/entities/document/document';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { UsersService } from '@app/shared/services/users.service';
import ApiService from '@services/api.service';
import { FieldService } from '@services/field.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToClassFromExist, plainToInstance } from 'class-transformer';
import { from, mergeMap, Observable, Subject, switchMap } from 'rxjs';
import { map, tap, toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AssetsService {
  private sidePanelToggleSubject = new Subject<Asset | null>();

  private addAssetSubject = new Subject<Asset>();
  private updateAssetSubject = new Subject<Asset>();
  private deleteAssetsSubject = new Subject<Asset[]>();

  private apiService = inject(ApiService);
  private appManager = inject(AppManager);
  private router = inject(Router);
  private activationEndService = inject(ActivationEndService);
  private fieldService = inject(FieldService);
  private usersService = inject(UsersService);

  private readonly assetInfoGraphqlFragment = gql`
    fragment AssetInfo on Asset {
      id
      name
      identifier
      computedProperties
      properties @include(if: $fetchFullData)
      assetState @include(if: $fetchFullData)
      organizationId @include(if: $fetchFullData)
      creationUserId @include(if: $fetchFullData)
      creationDate @include(if: $fetchFullData)
      lastChangeUserId @include(if: $fetchFullData)
      lastChangeDate @include(if: $fetchFullData)
      dataDate @include(if: $fetchFullData)
    }`;

  /**
   * Emits Assets of the current Organization that are accessible to the authenticated User.
   * @param entityType Optional. EntityType to use for filtering Assets,
   * returning only those whose related AssetType include the module corresponding to the EntityType.
   * @param fetchFullData Optional. Determines if we want to query all fields in the Asset entity, or just a minimal subset
   * @return Observable emitting assets of the current Organization that are accessible to the authenticated User.
   */
  public getAssets(entityType?: EntityTypeEnum, fetchFullData: boolean = true): Observable<Asset[]> {
    const query = gql`
      query AccessibleAssets($organizationId: String!, $entityType: EntityType, $fetchFullData: Boolean! = true) {
        accessibleAssets(organizationId: $organizationId, entityType: $entityType) {
          ...AssetInfo
        }
      }${this.assetInfoGraphqlFragment}
    `;
    const variables = {
      organizationId: this.appManager.currentOrganization.id,
      entityType: entityType,
      fetchFullData: fetchFullData
    };

    return this.apiService.query({query, variables})
      .pipe(
        map(data => plainToInstance(Asset, data['accessibleAssets'] as Asset[])),
        // Fetch Users who created and last updated the Asset
        switchMap(assets => from(assets)),
        mergeMap(asset => this.usersService.fetchUsersInfo(asset)),
        toArray()
      );
  }

  /**
   * Emits an Asset whenever it is updated.
   */
  public get assetAdded$(): Observable<Asset> {
    return this.addAssetSubject.asObservable();
  }

  /**
   * Emits an Asset whenever it is updated.
   */
  public get assetUpdated$(): Observable<Asset> {
    return this.updateAssetSubject.asObservable();
  }

  /**
   * Emits an Asset whenever it is deleted.
   */
  public get assetsDeleted$(): Observable<Asset[]> {
    return this.deleteAssetsSubject.asObservable();
  }

  /**
   * Emits an Asset whenever the side panel opens, or null whenever it closes.
   */
  public get sidePanelToggle$(): Observable<Asset | null> {
    return this.sidePanelToggleSubject.asObservable();
  }

  /**
   * Fetch the Asset to be displayed based on the ID contained in the activated route's path.
   * Query Graphql cache first since the guard already query the server.
   * @param route Activated route.
   * @return Asset.
   */
  public resolve(route: ActivatedRouteSnapshot): Observable<Asset> {
    const query = gql`
      query Asset($id: String!, $fetchFullData: Boolean! = true) {
        asset(id: $id) {
          ...AssetInfo
        }
      }
      ${this.assetInfoGraphqlFragment}
    `;
    const variables = {
      id: route.paramMap.get('id')
    };
    return this.apiService.query({query, variables, fetchPolicy: 'cache-first'})
      .pipe(
        map(data => plainToInstance(Asset, data['asset'])),
        // Fetch Users who created and last updated the Asset
        switchMap(asset => this.usersService.fetchUsersInfo(asset)),
        tap(() => this.activationEndService.getRefreshHeaderSubject().next())
      );
  }

  /**
   * Fetch an Asset by its ID
   * @param assetId ID of the Asset to return.
   * @return Asset
   */
  public loadAsset(assetId: string): Observable<Asset> {
    const query = gql`
      query AssetById($assetId: String!, $fetchFullData: Boolean! = true) {
        asset(id: $assetId) {
          ...AssetInfo
        }
      }
      ${this.assetInfoGraphqlFragment}
    `;
    const variables = {assetId};
    return this.apiService.query({query, variables, fetchPolicy: 'network-only'})
      .pipe(map(data => plainToInstance(Asset, data['asset'])));
  }

  /**
   * Emits a list of existing values for an Asset's state within the current Organization.
   */
  public get availableAssetStates$(): Observable<string[]> {
    return this.fieldService.getField('assetState', EntityTypeEnum.ASSET)
      .pipe(map(field => field.fieldValues));
  }


  /**
   * Make an API request to search for assets by their name.
   * Limit the results to 10.
   *
   * @param searchName The name of the asset to search for.
   * @param exclude ID of the asset to exclude from the search.
   * @param entityType Optional. EntityType to use for filtering Assets,
   * returning only those whose related AssetType include the module corresponding to the EntityType.
   * @return An Observable of Asset entities that match the search.
   */
  public searchAssetsByNames(
    searchName: string = '',
    exclude?: string,
    entityType?: EntityTypeEnum
  ): Observable<RelatedAsset[]> {
    const query = gql`
      query SearchAssetsByName($organizationId: String!, $searchName: String!, $exclude: String, $entityType: EntityType) {
        searchAssetsByName(organizationId: $organizationId, searchName: $searchName, exclude: $exclude, entityType: $entityType) {
          id
          name
          identifier
          computedProperties
        }
      }
    `;
    const variables = {
      organizationId: this.appManager.currentOrganization.id,
      searchName,
      exclude,
      entityType
    };

    return this.apiService.query({query, variables})
      .pipe(
        map(data => {
          return plainToInstance(Asset, data['searchAssetsByName'] as Asset[])
            // Sort the assets by their identifier.
            .sort((assetA, assetB) => {
              return assetA.identifier.compareTo(assetB.identifier);
            })
            .map(asset => asset.toRelatedAsset());
        })
      );
  }

  /**
   * Make an API request to create a new Asset, then add it to the grid.
   * @param assetInput Data to use for creating a new Asset.
   * @return Created Asset.
   */
  public createAsset(assetInput: AssetInput): Observable<Asset> {
    const mutation = gql`
      mutation CreateAsset($assetInput: CreateAssetInput!, $organizationId: String!, $fetchFullData: Boolean! = true) {
        createAsset( assetInput: $assetInput, organizationId: $organizationId ) {
          ...AssetInfo
        }
      }${this.assetInfoGraphqlFragment}
    `;
    const variables = {
      organizationId: this.appManager.currentOrganization.id,
      assetInput
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(Asset, data['createAsset'])),
        switchMap(asset => this.usersService.fetchUsersInfo(asset))
      );
  }

  /**
   * Check whether the current Organization has any Asset.
   * @return True if the Organization has at least one Asset or false otherwise.
   */
  public doesOrganizationHaveAssets(): Observable<boolean> {
    const query = gql`
      query DoesOrganizationHaveAssets($organizationId: String!) {
        doesOrganizationHaveAssets(organizationId: $organizationId)
      }
    `;
    const variables = {
      organizationId: this.appManager.currentOrganization.id
    };

    return this.apiService.query({query, variables})
      .pipe(map(data => data['doesOrganizationHaveAssets'] as boolean));
  }

  /**
   * Make an API request to update an Asset.
   * @param asset Existing Asset.
   * @param assetInput Data for updating the Asset.
   * @return Updated Asset.
   */
  public updateAsset(asset: Asset, assetInput: AssetInput): Observable<Asset> {
    const mutation = gql`
      mutation UpdateAsset($assetId: String!, $assetInput: UpdateAssetInput!, $fetchFullData: Boolean! = true) {
        updateAsset(assetId: $assetId, assetInput: $assetInput) {
          ...AssetInfo
        }
      }${this.assetInfoGraphqlFragment}
    `;
    const variables = {
      assetId: asset.id,
      assetInput: {
        name: asset.name,
        assetState: asset.assetState,
        ...assetInput
      }
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToInstance(Asset, data['updateAsset'])),
        switchMap(asset => this.usersService.fetchUsersInfo(asset)),
        tap(updatedAsset => this.updateAssetSubject.next(updatedAsset))
      );
  }

  /**
   * Set the Asset's main picture to be displayed in the dashboard. The Asset's properties are updated with the ID of
   * Document related to the picture.
   * @param asset Asset which to set the main picture of.
   * @param mainPictureDocument Document to be used as main picture.
   * @return Updated Asset with main picture set.
   */
  public setAssetMainPicture(asset: Asset, mainPictureDocument: Document): Observable<Asset> {
    const mutation = gql`
      mutation SetAssetsMainPicture($assetId: String!, $mainPictureDocumentId: String!) {
        setAssetMainPicture(assetId: $assetId, mainPictureDocumentId: $mainPictureDocumentId) {
          id
          properties
          lastChangeDate
          lastChangeUserId
        }
      }
    `;
    const variables = {
      assetId: asset.id,
      mainPictureDocumentId: mainPictureDocument.id
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => plainToClassFromExist(asset, data['setAssetMainPicture'])),
        switchMap(asset => this.usersService.fetchUsersInfo(asset))
      );
  }

  /**
   * Make an API request to delete the Asset.
   * @param assets Asset to delete.
   * @return True if Asset is successfully deleted, false otherwise.
   */
  public deleteAssets(assets: Asset[]): Observable<boolean> {
    const mutation = gql`
      mutation DeleteAsset($assetIds: [String!]!) {
        deleteAssets(assetIds: $assetIds)
      }
    `;
    const variables = {
      assetIds: assets.map(asset => asset.id)
    };

    return this.apiService.mutate({mutation, variables})
      .pipe(
        map(data => data['deleteAssets'] as boolean),
        tap(success => success && this.deleteAssetsSubject.next(assets))
      );
  }

  /**
   * Open the side panel displaying the Asset.
   * @param asset Asset to be displayed in the side panel.
   */
  public openAssetSidePanel(asset: Asset): void {
    this.sidePanelToggleSubject.next(asset);
  }

  /**
   * Close the side panel.
   */
  public closeAssetSidePanel(): void {
    this.sidePanelToggleSubject.next(null);
  }

  /**
   * Navigate to the Asset's sheet and open the requested tab.
   * @param assetId ID of the Asset to navigate to.
   * @param tabName Name of the tab to open after navigating to the Asset's sheet.
   * @return Promise that resolves to true if navigation succeeds, false if navigation fails or rejects on error.
   */
  public navigateToAssetSheet(assetId: string, tabName: string): Promise<boolean> {
    return this.router.navigate([
      'organization',
      this.appManager.currentOrganization.id,
      'assets',
      'asset-sheet',
      assetId,
      tabName
    ]);
  }
}
