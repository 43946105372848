import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PresignedUrl } from '@app/core/model/entities/document/document';
import ApiService from '@services/api.service';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { UploadFile, UploadInput } from 'ngx-uploader';
import { Observable, OperatorFunction, timeout, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class FileService {

  private apiService = inject(ApiService);
  private httpClient = inject(HttpClient);

  /**
   * Download the file associated with a document.
   * @param documentId ID of the document to download.
   * @return Observable emitting the downloaded file's URL.
   */
  public downloadFile(documentId: string): Observable<string> {
    return this.getDocumentFileUrl(documentId)
      .pipe(tap(presignedUrl => window.open(presignedUrl)));
  }

  /**
   * Download a ZIP file containing all files associated with a list of documents.
   * @param documentIds IDs of the documents to zip and download.
   * @return Observable emitting the URL to the downloaded ZIP file.
   */
  public downloadZipFile(documentIds: string[]): Observable<string> {
    // TODO TTT-3576 Remove polling
    const waitForZipFile: OperatorFunction<string, string> = switchMap(presignedUrl => {
        return this.httpClient.get(presignedUrl, {observe: 'response', responseType: 'blob'})
          .pipe(
            catchError(() => timer(5000).pipe(map(() => presignedUrl), waitForZipFile)),
            map(() => presignedUrl)
          );
      }
    );
    const query = gql`
      query ZipDocuments($documentIds: [String!]!) {
        zippedDocumentsUrl(documentIds: $documentIds) {
          documentId
          url
        }
      }
    `;
    const variables = {documentIds};
    return this.apiService.query({query, variables})
      .pipe(
        map(data => {
          const presignedUrl = data['zippedDocumentsUrl'] as { documentId: string, url: string };
          return presignedUrl.url;
        }),
        waitForZipFile, // Poll presigned URL until file exists
        timeout(900000), // Timeout after 900 seconds
        tap(presignedUrl => window.open(presignedUrl)) // Download file
      );
  }

  /**
   * Generate a presigned URL to a document's file.
   * @param documentId ID of the document for which to generate a URL.
   * @return Observable that emits the URL to the file.
   */
  public getDocumentFileUrl(documentId: string): Observable<string> {
    return this.getDocumentFileUrls([documentId])
      .pipe(map(presignedUrls => presignedUrls[documentId]));
  }

  /**
   * Generate a presigned URL for each document.
   * @param documentIds IDs of the documents for which to generate a URL.
   * @return Observable that emits a map of the documents' IDs to their corresponding URL.
   */
  public getDocumentFileUrls(documentIds: string[]): Observable<{ [documentId: string]: string }> {
    const query = gql`
      query DocumentUrls($documentIds: [String!]!) {
        documentsUrls(documentIds: $documentIds) {
          documentId
          url
        }
      }
    `;
    const variables = {documentIds};
    return this.apiService.query({query, variables})
      .pipe(
        map(data => data['documentsUrls'] as { documentId: string, url: string }[]),
        map(presignedUrls => presignedUrls.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.documentId]: curr.url
          };
        }, {}))
      );
  }

  /**
   * Upload a list of files. Prepare UploadInputs containing presigned URLs that can then be used for uploading
   * the files' data.
   * @param files Files to upload.
   * @param entityId ID of the entity to which uploaded files will be linked.
   * @param entityType Type of entity to which uploaded files will be linked.
   * @param organizationId ID of the Organization to which the files are related.
   * @param documentType Type of document related to uploaded files.
   * @param importTemplate Whether the uploaded files is to be used for importing entities. Defaults to false.
   * @return Observable that emits an UploadInput for each
   */
  public uploadFiles(
    files: UploadFile[],
    entityId: string,
    entityType: EntityTypeEnum,
    organizationId: string,
    documentType: DocumentTypeEnum,
    importTemplate = false
  ): Observable<UploadInput> {
    const fileNames = files.map(file => file.name);
    const presignedUrls$ = importTemplate
      ? this.getPutPresignedUrlToImport(fileNames, entityType, organizationId)
      : this.getPutPresignedUrls(fileNames, entityId, entityType, organizationId, documentType);
    return presignedUrls$.pipe(
      switchMap(presignedUrls => {
        return presignedUrls.map((presignedUrl): UploadInput => {
          // DocumentName is the origin name of the file. fileName is the name of the file stored in the file system
          const file = files.find(it => it.name.toLowerCase() === presignedUrl.documentName.toLowerCase());
          file.name = presignedUrl.fileName;
          return {
            type: 'uploadFile',
            url: presignedUrl.url,
            method: 'PUT',
            file: file,
            includeWebKitFormBoundary: false
          };
        });
      })
    );
  }

  /**
   * Make an API call to get presigned URLs for uploading a list of files.
   * @param fileNames Names of files that need to be uploaded.
   * @param entityId ID of the entity to which uploaded files will be linked.
   * @param entityType Type of entity to which uploaded files will be linked.
   * @param organizationId ID of the Organization to which the files are related.
   * @param documentType Type of document related to uploaded files.
   * @return Observable that emits a list of presigned URLs.
   * @private
   */
  private getPutPresignedUrls(
    fileNames: string[],
    entityId: string,
    entityType: EntityTypeEnum,
    organizationId: string,
    documentType: DocumentTypeEnum
  ): Observable<PresignedUrl[]> {
    const query = gql`
      query PutPresignedUrl(
        $entityId: String!,
        $entityType: EntityType!,
        $fileNames: [String!]!,
        $organizationId: String!
        $documentType: DocumentTypeEnum!
      ) {
        getPutPresignedUrl(
          entityId: $entityId,
          entityType: $entityType,
          fileNames: $fileNames,
          organizationId: $organizationId,
          documentType: $documentType
        ) {
          fileName
          documentName
          url
        }
      }
    `;
    const variables = {entityId, entityType, fileNames, organizationId, documentType};
    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(PresignedUrl, data['getPutPresignedUrl'] as PresignedUrl[])));
  }

  /**
   * Make an API call to get presigned URLs for uploading a list of import files.
   * @param fileNames Names of files that need to be uploaded.
   * @param entityType Type of entity to which uploaded files will be linked.
   * @param organizationId ID of the Organization to which the files are related.
   * @return Observable that emits a list of presigned URLs.
   * @private
   */
  private getPutPresignedUrlToImport(
    fileNames: string[],
    entityType: EntityTypeEnum,
    organizationId: string
  ): Observable<PresignedUrl[]> {
    const query = gql`
      query PutPresignedUrlToImport($entityType: EntityType!, $fileNames: [String!]!, $organizationId: String!) {
        getPutPresignedUrlToImport(entityType: $entityType, fileNames: $fileNames, organizationId: $organizationId) {
          fileName
          documentName
          url
        }
      }
    `;
    const variables = {fileNames, entityType, organizationId};
    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(PresignedUrl, data['getPutPresignedUrlToImport'] as PresignedUrl[])));
  }

  public getFileNameFromUploadFileResponse(file: UploadFile): string {
    return file.name;
  }
}
