<div class="error-box">
  <div class="error-image"></div>
  <div class="error-body">
    <h2>{{ 'TITLE.UNAUTHORIZED' | translate }}</h2>
    <p>{{ 'MESSAGE.UNAUTHORIZED' | translate }}</p>
    <p>{{ 'MESSAGE.USE_INVITATION_LINK' | translate }}</p>

    @if (authService.error$ | async; as error) {
      <h3>{{ 'LABEL.ERROR_DETAILS' | translate }}</h3>
      <pre>{{ error }}</pre>
    }

    <button color="accent" routerLink="/" class="pull-left" mat-raised-button>{{ 'BUTTON.LOGIN' | translate }}</button>
  </div>
</div>
