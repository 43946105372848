<div class="fx-layout-row">
  <div class="fx-fill">
    <div class="org-selector fx-layout-row fx-justify-start fx-align-baseline fx-gap-3-percent">
      <span class="">{{ 'LABEL.ORGANIZATION_REPORT' | translate }}</span>
      <mat-form-field appearance="outline" class="fx-flex-20-percent">
        <mat-label>{{ 'LABEL.ORGANIZATION' | translate }}</mat-label>
        <mat-select-autocomplete [dataSource]="organizations$"
                                 [displayWith]="Organization.getName"
                                 [formControl]="organizationForm"
                                 required>
        </mat-select-autocomplete>
      </mat-form-field>
    </div>

    @if (userReport) {
      @if (reportForm.get('activateReports').value) {
        <div class="m-t-10 m-b-10 field-container fx-layout-col fx-justify-space-between fx-align-baseline fx-gap-20">
          @if (userReport?.lastSendDate.isValid()) {
            <div class="fx-layout-row fx-justify-start fx-align-baseline">
              <div>
                {{ 'LABEL.LAST_SENT_REPORT_DATE' | translate }} {{ formatDate(userReport?.lastSendDate) }}
              </div>
              <div class="m-l-20">
                <button (click)="sendLastReport()" [id]="'send_last_report'"
                        mat-stroked-button>{{ 'LABEL.REFER' | translate }}
                </button>
              </div>
            </div>
          }
          @if (sendDate$ | async; as sendDate) {
            <div>
              {{ 'LABEL.NEXT_REPORT' | translate }} {{ formatDate(sendDate) }}
            </div>
          }
        </div>
      } @else {
        @if (!organizationForm.invalid) {
          <div class="m-l-20 m-t-10 m-b-10">
            {{ 'LABEL.NO_REPORT_SCHEDULED' | translate }}
          </div>
        }
      }

      <form [formGroup]="reportForm">
        @if (!organizationForm.invalid) {
          <section>
            <h3 class="report-section-title">{{ 'LABEL.GENERAL_SETTINGS' | translate }}</h3>
            <div class="report-field-container">
              <div class="fx-layout-row fx-gap-10-percent m-b-10">
                <mat-label>{{ 'LABEL.GET_REPORT' | translate }}</mat-label>
                <mat-slide-toggle formControlName="activateReports"
                                  [checked]="reportForm.get('activateReports').value"></mat-slide-toggle>
              </div>
              @if (reportForm.get('activateReports').value) {
                <div>
                  <div class="m-b-10">{{ 'LABEL.CONFIG_PERIODICITY_REPORT' | translate }}</div>
                  <div class="fx-layout-col fx-gap-10">
                    <div class="fx-layout-row fx-justify-start fx-align-baseline fx-gap-7-percent">
                      <span class="fx-flex-20-percent">{{ 'LABEL.DAYS_WEEK' | translate }}</span>
                      <mat-form-field appearance="outline" class="fx-flex-25-percent">
                        <mat-select formControlName="dayOfWeek" [id]="'dayOfWeek'">
                          @for (day of daysOfWeek; track day) {
                            <mat-option [value]="day">
                              {{ 'LABEL.DAY_' + day | translate }}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                      <span>{{ 'LABEL.AT' | translate }}</span>
                      <mat-form-field appearance="outline" class="fx-flex-25-percent">
                        <mat-select formControlName="hour" [id]="'hour'">
                          @for (hour of hours; track hour) {
                            <mat-option [value]="hour">
                              {{ hour }}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="fx-layout-row fx-justify-start fx-align-baseline fx-gap-7-percent">
                      <span class="fx-flex-20-percent">{{ 'LABEL.PERIODICITY' | translate }}</span>
                      <mat-form-field appearance="outline" class="fx-flex-25-percent">
                        <mat-select formControlName="periodicity" [id]="'periodicity'">
                          @for (periodicity of periodicityWeekList; track periodicity) {
                            <mat-option [value]="periodicity">
                              {{ 'LABEL.PERIODICITY_WEEK_' + periodicity | translate }}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              }
            </div>
          </section>
        }

      @if (reportForm.get('activateReports').value) {
        <section>
          <h3 class="report-section-title">{{'LABEL.REPORT_CONTENT' | translate}}</h3>
          <div class="report-field-container">
            <div>
              <div>{{'LABEL.REPORT_ACTIONS_CONFIG' | translate}}</div>
              <div>{{'LABEL.REPORT_ACTIONS_DESCRIPTIONS' | translate}}</div>
            </div>
            <div class="m-t-10 fx-layout-col fx-gap-15">
              <mat-expansion-panel formGroupName="entitiesHistory">
                <mat-expansion-panel-header>{{'LABEL.ASSET' | translate}}</mat-expansion-panel-header>
                <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                  <mat-label class="fx-flex-20-percent">{{'LABEL.ASSET_ADDED' | translate}}</mat-label>
                  <mat-slide-toggle class="fx-flex-20-percent" formControlName="onAssetCreated"
                  [checked]="reportForm.get('entitiesHistory').get('onAssetCreated').value"></mat-slide-toggle>
                </div>
                <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                  <mat-label class="fx-flex-20-percent">{{'LABEL.ASSET_MODIFIED' | translate}}</mat-label>
                  <mat-slide-toggle class="fx-flex-20-percent" formControlName="onAssetUpdated"
                  [checked]="reportForm.get('entitiesHistory').get('onAssetUpdated').value"></mat-slide-toggle>
                </div>
                <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                  <mat-label class="fx-flex-20-percent">{{'LABEL.ASSET_DELETED' | translate}}</mat-label>
                  <mat-slide-toggle class="fx-flex-20-percent" formControlName="onAssetDeleted"
                  [checked]="reportForm.get('entitiesHistory').get('onAssetDeleted').value"></mat-slide-toggle>
                </div>
              </mat-expansion-panel>
              @if (accessManager.hasAccess(Permission.VIEW_SPACE)) {
                <mat-expansion-panel formGroupName="entitiesHistory">
                  <mat-expansion-panel-header>{{'LABEL.SPACE' | translate}}</mat-expansion-panel-header>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.SPACE_ADDED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onSpaceCreated"
                    [checked]="reportForm.get('entitiesHistory').get('onSpaceCreated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.SPACE_MODIFIED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onSpaceUpdated"
                    [checked]="reportForm.get('entitiesHistory').get('onSpaceUpdated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.SPACE_DELETED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onSpaceDeleted"
                    [checked]="reportForm.get('entitiesHistory').get('onSpaceDeleted').value"></mat-slide-toggle>
                  </div>
                </mat-expansion-panel>
              }
              @if (accessManager.hasAccess(Permission.VIEW_DOCUMENT)) {
                <mat-expansion-panel
                  formGroupName="entitiesHistory">
                  <mat-expansion-panel-header>{{'LABEL.DOCUMENT' | translate}}</mat-expansion-panel-header>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.DOCUMENT_ADDED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onDocumentCreated"
                    [checked]="reportForm.get('entitiesHistory').get('onDocumentCreated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.DOCUMENT_MODIFIED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onDocumentUpdated"
                    [checked]="reportForm.get('entitiesHistory').get('onDocumentUpdated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.DOCUMENT_DELETED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onDocumentDeleted"
                    [checked]="reportForm.get('entitiesHistory').get('onDocumentDeleted').value"></mat-slide-toggle>
                  </div>
                </mat-expansion-panel>
              }
              @if (accessManager.hasAccess(Permission.VIEW_CHECK)) {
                <mat-expansion-panel>
                  <mat-expansion-panel-header>{{'LABEL.CHECK' | translate}}</mat-expansion-panel-header>
                  <ng-container formGroupName="entitiesHistory">
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.CHECK_ADDED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onCheckCreated"
                      [checked]="reportForm.get('entitiesHistory').get('onCheckCreated').value"></mat-slide-toggle>
                    </div>
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.CHECK_MODIFIED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onCheckUpdated"
                      [checked]="reportForm.get('entitiesHistory').get('onCheckUpdated').value"></mat-slide-toggle>
                    </div>
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.CHECK_DELETED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onCheckDeleted"
                      [checked]="reportForm.get('entitiesHistory').get('onCheckDeleted').value"></mat-slide-toggle>
                    </div>
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.CHECK_EXPIRED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onCheckExpired"
                      [checked]="reportForm.get('entitiesHistory').get('onCheckExpired').value"></mat-slide-toggle>
                    </div>
                  </ng-container>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <span>{{'LABEL.CHECK_TO_RENEW_IN' | translate}}</span>
                    <mat-form-field appearance="outline" class="fx-flex-25-percent">
                      <mat-select formControlName="checkToRenewNumber" [id]="'checkToRenewNumber'">
                        @for (periodicity of periodicityMonthList; track periodicity) {
                          <mat-option [value]="periodicity">
                            {{periodicity}}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="fx-flex-25-percent">
                      <mat-select formControlName="checkToRenewPeriodicity"
                        [id]="'checkToRenewPeriodicity'">
                        @for (timeUnit of timeUnits; track timeUnit) {
                          <mat-option [value]="timeUnit.days">
                            {{'LABEL.' + timeUnit.unit | translate}}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                    <ng-container formGroupName="entitiesHistory">
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="checkRenewalEnabled"
                        [checked]="reportForm.get('entitiesHistory').get('checkRenewalEnabled').value">
                      </mat-slide-toggle>
                    </ng-container>
                  </div>
                  @if (reportForm.get('checkPeriodicityError').value) {
                    <div>
                      <span class="text-warning">{{'LABEL.REPORT_CHECK_TO_RENEW_PERIODICITY_ERROR' | translate}}</span>
                    </div>
                  }
                </mat-expansion-panel>
              }
              @if (accessManager.hasAccess(Permission.VIEW_OCCUPANT)) {
                <mat-expansion-panel
                  formGroupName="entitiesHistory">
                  <mat-expansion-panel-header>{{'LABEL.OCCUPANT' | translate}}</mat-expansion-panel-header>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.OCCUPANT_ADDED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onOccupantCreated"
                    [checked]="reportForm.get('entitiesHistory').get('onOccupantCreated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.OCCUPANT_MODIFIED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onOccupantUpdated"
                    [checked]="reportForm.get('entitiesHistory').get('onOccupantUpdated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.OCCUPANT_DELETED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onOccupantDeleted"
                    [checked]="reportForm.get('entitiesHistory').get('onOccupantDeleted').value"></mat-slide-toggle>
                  </div>
                </mat-expansion-panel>
              }
              @if (accessManager.hasAccess(Permission.VIEW_OCCUPANT)) {
                <mat-expansion-panel>
                  <mat-expansion-panel-header>{{'LABEL.LEASE_IN_OCCUPANT' | translate}}</mat-expansion-panel-header>
                  <ng-container formGroupName="entitiesHistory">
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.LEASE_ADDED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onLeaseCreated"
                      [checked]="reportForm.get('entitiesHistory').get('onLeaseCreated').value"></mat-slide-toggle>
                    </div>
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.LEASE_MODIFIED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onLeaseUpdated"
                      [checked]="reportForm.get('entitiesHistory').get('onLeaseUpdated').value"></mat-slide-toggle>
                    </div>
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.LEASE_DELETED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onLeaseDeleted"
                      [checked]="reportForm.get('entitiesHistory').get('onLeaseDeleted').value"></mat-slide-toggle>
                    </div>
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.LEASE_EXPIRED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onLeaseExpired"
                      [checked]="reportForm.get('entitiesHistory').get('onLeaseExpired').value"></mat-slide-toggle>
                    </div>
                  </ng-container>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <span>{{'LABEL.LEASE_TO_RENEW_IN' | translate}}</span>
                    <mat-form-field appearance="outline" class="fx-flex-25-percent">
                      <mat-select formControlName="leaseToRenewNumber" [id]="'leaseToRenewNumber'">
                        @for (periodicity of periodicityMonthList; track periodicity) {
                          <mat-option [value]="periodicity">
                            {{periodicity}}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="fx-flex-25-percent">
                      <mat-select formControlName="leaseToRenewPeriodicity"
                        [id]="'leaseToRenewPeriodicity'">
                        @for (timeUnit of timeUnits; track timeUnit) {
                          <mat-option [value]="timeUnit.days">
                            {{'LABEL.' + timeUnit.unit | translate}}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                    <ng-container formGroupName="entitiesHistory">
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="leaseRenewalEnabled"
                        [checked]="reportForm.get('entitiesHistory').get('leaseRenewalEnabled').value">
                      </mat-slide-toggle>
                    </ng-container>
                  </div>
                  @if (reportForm.get('leasePeriodicityError').value) {
                    <div>
                      <span class="text-warning">{{'LABEL.REPORT_LEASE_TO_RENEW_PERIODICITY_ERROR' | translate}}</span>
                    </div>
                  }
                </mat-expansion-panel>
              }
              @if (accessManager.hasAccess(Permission.VIEW_CONTRACT)) {
                <mat-expansion-panel>
                  <mat-expansion-panel-header>{{'LABEL.CONTRACT_OF_MAINTENANCE' | translate}}</mat-expansion-panel-header>
                  <ng-container formGroupName="entitiesHistory">
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.CONTRACT_ADDED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onContractCreated"
                      [checked]="reportForm.get('entitiesHistory').get('onContractCreated').value"></mat-slide-toggle>
                    </div>
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.CONTRACT_MODIFIED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onContractUpdated"
                      [checked]="reportForm.get('entitiesHistory').get('onContractUpdated').value"></mat-slide-toggle>
                    </div>
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.CONTRACT_DELETED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onContractDeleted"
                      [checked]="reportForm.get('entitiesHistory').get('onContractDeleted').value"></mat-slide-toggle>
                    </div>
                    <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                      <mat-label class="fx-flex-20-percent">{{'LABEL.CONTRACT_EXPIRED' | translate}}</mat-label>
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="onContractExpired"
                      [checked]="reportForm.get('entitiesHistory').get('onContractExpired').value"></mat-slide-toggle>
                    </div>
                  </ng-container>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <span>{{'LABEL.CONTRACT_TO_RENEW_IN' | translate}}</span>
                    <mat-form-field appearance="outline" class="fx-flex-25-percent">
                      <mat-select formControlName="contractToRenewNumber"
                        [id]="'contractToRenewNumber'">
                        @for (periodicity of periodicityMonthList; track periodicity) {
                          <mat-option [value]="periodicity">
                            {{periodicity}}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="fx-flex-25-percent">
                      <mat-select formControlName="contractToRenewPeriodicity"
                        [id]="'contractToRenewPeriodicity'">
                        @for (timeUnit of timeUnits; track timeUnit) {
                          <mat-option [value]="timeUnit.days">
                            {{'LABEL.' + timeUnit.unit | translate}}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                    <ng-container formGroupName="entitiesHistory">
                      <mat-slide-toggle class="fx-flex-20-percent" formControlName="contractRenewalEnabled"
                        [checked]="reportForm.get('entitiesHistory').get('contractRenewalEnabled').value">
                      </mat-slide-toggle>
                    </ng-container>
                  </div>
                  @if (reportForm.get('contractPeriodicityError').value) {
                    <div>
                      <span class="text-warning">{{'LABEL.REPORT_LEASE_TO_RENEW_PERIODICITY_ERROR' | translate}}</span>
                    </div>
                  }
                </mat-expansion-panel>
              }
              @if (accessManager.hasAccess(Permission.VIEW_WORK)) {
                <mat-expansion-panel formGroupName="entitiesHistory">
                  <mat-expansion-panel-header>{{'LABEL.WORK' | translate}}</mat-expansion-panel-header>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.WORK_ADDED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onWorkCreated"
                    [checked]="reportForm.get('entitiesHistory').get('onWorkCreated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.WORK_MODIFIED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onWorkUpdated"
                    [checked]="reportForm.get('entitiesHistory').get('onWorkUpdated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.WORK_DELETED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onWorkDeleted"
                    [checked]="reportForm.get('entitiesHistory').get('onWorkDeleted').value"></mat-slide-toggle>
                  </div>
                </mat-expansion-panel>
              }
              @if (accessManager.hasAccess(Permission.VIEW_PROJECT)) {
                <mat-expansion-panel
                  formGroupName="entitiesHistory">
                  <mat-expansion-panel-header>{{'LABEL.PROJECT' | translate}}</mat-expansion-panel-header>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.PROJECT_ADDED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onProjectCreated"
                    [checked]="reportForm.get('entitiesHistory').get('onProjectCreated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.PROJECT_MODIFIED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onProjectUpdated"
                    [checked]="reportForm.get('entitiesHistory').get('onProjectUpdated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.PROJECT_DELETED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onProjectDeleted"
                    [checked]="reportForm.get('entitiesHistory').get('onProjectDeleted').value"></mat-slide-toggle>
                  </div>
                </mat-expansion-panel>
              }
              @if (accessManager.hasAccess(Permission.VIEW_EQUIPMENT)) {
                <mat-expansion-panel formGroupName="entitiesHistory">
                  <mat-expansion-panel-header>{{'LABEL.EQUIPMENT' | translate}}</mat-expansion-panel-header>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.EQUIPMENT_ADDED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onEquipmentCreated"
                    [checked]="reportForm.get('entitiesHistory').get('onEquipmentCreated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.EQUIPMENT_MODIFIED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onEquipmentUpdated"
                    [checked]="reportForm.get('entitiesHistory').get('onEquipmentUpdated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{'LABEL.EQUIPMENT_DELETED' | translate}}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onEquipmentDeleted"
                    [checked]="reportForm.get('entitiesHistory').get('onEquipmentDeleted').value"></mat-slide-toggle>
                  </div>
                </mat-expansion-panel>
              }
              @if (accessManager.hasAccess(Permission.VIEW_INVESTMENT)) {
                <mat-expansion-panel
                  formGroupName="entitiesHistory">
                  <mat-expansion-panel-header>{{ 'LABEL.INVESTMENT' | translate }}</mat-expansion-panel-header>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{ 'LABEL.INVESTMENT_ADDED' | translate }}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onInvestmentCreated"
                                      [checked]="reportForm.get('entitiesHistory').get('onInvestmentCreated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{ 'LABEL.INVESTMENT_MODIFIED' | translate }}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onInvestmentUpdated"
                                      [checked]="reportForm.get('entitiesHistory').get('onInvestmentUpdated').value"></mat-slide-toggle>
                  </div>
                  <div class="fx-layout-row fx-justify-space-between fx-align-baseline">
                    <mat-label class="fx-flex-20-percent">{{ 'LABEL.INVESTMENT_DELETED' | translate }}</mat-label>
                    <mat-slide-toggle class="fx-flex-20-percent" formControlName="onInvestmentDeleted"
                                      [checked]="reportForm.get('entitiesHistory').get('onInvestmentDeleted').value"></mat-slide-toggle>
                  </div>
                </mat-expansion-panel>
              }
            </div>
          </div>
          @if (reportForm.get('noReportDataError').value) {
            <div class="m-t-15 text-right report-field-container">
              <span class="text-danger">{{'LABEL.REPORT_NO_DATA_ERROR' | translate}}</span>
            </div>
          }
        </section>
      }

        @if (!organizationForm.invalid) {
          <div
            class="actions report-field-container m-t-20 m-b-10 fx-layout-row fx-justify-end fx-align-center">
            <button (click)="onButtonResetClick()" class="m-r-20"
                    mat-stroked-button>{{ 'LABEL.CANCEL' | translate }}
            </button>
            <button [disabled]="this.reportForm.get('noReportDataError').value" (click)="updateUserReport()"
                    mat-raised-button
                    color="accent">{{ 'BUTTON.SAVE' | translate }}
            </button>
          </div>
        }
      </form>
    }
  </div>
</div>
