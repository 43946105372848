import { AbstractBean } from '@app/core/model/abstract-bean';
import { User } from '@app/core/model/client/user';
import { Transform, Type } from 'class-transformer';
import dayjs, { Dayjs } from 'dayjs';

export abstract class Entity extends AbstractBean {

  @Transform(fn => dayjs(fn.value), {toClassOnly: true})
  @Type(() => String)
  public creationDate: Dayjs;
  @Transform(fn => dayjs(fn.value), {toClassOnly: true})
  @Type(() => String)
  public lastChangeDate: Dayjs;
  @Type(() => User)
  public creationUser: User;
  public creationUserId: number;
  @Type(() => User)
  public lastChangeUser: User;
  public lastChangeUserId: number;
  public properties: Record<string, any>;
  public computedProperties: Record<string, any>;

  protected static inputMapping: Record<string, InputMapping>;

  /**
   * Apply transformation to an Entity update input before calling the API.
   * Do nothing by default, specifics behaviors are implemented in child classes.
   * @param _
   */
  public static transformPropertiesForInput(_: Record<string, unknown>): void {
    // Custom behavior in child classes
  }
}

export interface InputMapping {
  input: string[];
  output: string;
  transform?: (output: any) => any;
}

export interface IRelatedEntity {
  id: string;
  name?: string;
  identifier?: number | string;
  displayName?: string;
}
