<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_PROJECT' | translate }}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="projectForm">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.PROJECT_NAME' | translate }}</mat-label>
        <input matInput id="input_project_name" formControlName="name" required/>
        @if (projectForm.get('name').hasError('required')) {
          <mat-error>
            {{ 'ERROR.FIELD_REQUIRED' | translate }}
          </mat-error>
        }
        @if (projectForm.get('name').hasError('maxlength')) {
          <mat-error>
            {{ 'ERROR.FIELD_MAX_LENGTH' | translate: {value: appConfig.FIELD_MAX_LENGTH} }}
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.PROJECT_ASSETS' | translate }}</mat-label>

        <mat-chip-grid #chipList>
          @for (asset of (selectedAssets| slice:0:10); track asset.id) {
            <mat-chip-row (removed)="selectToggle(asset)"
              [removable]="true">
              {{ asset.toString() }}
              <button matChipRemove>
                <i class="mdi mdi-close-circle"></i>
              </button>
            </mat-chip-row>
          }
          @if (selectedAssets.length > 10) {
            <div class="chips-container fx-layout-row fx-align-center">
              <span>
                {{ 'TOOLTIP.AND_MORE' | translate: {quantity: (selectedAssets.length - 10)} }}
              </span>
            </div>
          }
          <input #inputField #trigger="matAutocompleteTrigger"
            formControlName="assetName"
            [matAutocomplete]="suggestions"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            class="chip-input"
            type="text">
        </mat-chip-grid>
        <mat-autocomplete #suggestions="matAutocomplete"
          (optionSelected)="keepPanelOpen()">
          @if (filteredAssets | async; as allFilteredAssets) {
            @if (allFilteredAssets.length > 0) {
              <mat-option class="suggestion">
                <div class="checkbox-container fx-layout-row fx-align-center"
                  (click)="optionClicked(allFilteredAssets, $event)">
                  <mat-checkbox [checked]="allSelected" class="checkbox">
                    {{'GRID.SELECT_ALL' | translate}}
                  </mat-checkbox>
                </div>
              </mat-option>
            }
            @for (filteredAsset of allFilteredAssets; track filteredAsset) {
              <mat-option class="suggestion">
                <div class="checkbox-container fx-layout-row fx-align-center"
                  (click)="optionClicked( [filteredAsset], $event)">
                  <mat-checkbox [checked]="filteredAsset.checked" class="checkbox">
                    {{ filteredAsset.asset.toString() }}
                  </mat-checkbox>
                </div>
              </mat-option>
            }
          }
        </mat-autocomplete>
        @if (projectForm.get('selectedAssetIds').hasError('required')) {
          <mat-error>
            {{ 'ERROR.FIELD_REQUIRED' | translate }}
          </mat-error>
        }
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button [id]="'create-project-cancel-button'" matDialogClose="cancel">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button mat-raised-button [id]="'create-project-add-button'" [disabled]="projectForm.invalid"
      color="accent"
      matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
