<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center fx-gap-5">
  <input #inputText [formControl]="formControl" matInput class="mat-input-element ag-input-field-input" type="text"
         (focusin)="fullRowEdit && inputText.select()"
         (beforeinput)="validateKeypressEvent($event)"
         (paste)="validatePasteEvent($event)"/>

  @if (control.invalid) {
    <div>
      <i aria-hidden="true" class="mdi mdi-help-circle tooltip-icon" [matTooltip]="errorTooltip"></i>
    </div>
  }
  @if (!fullRowEdit) {
    <div>
      <i aria-hidden="true" class="mdi mdi-check" (click)="stopEditing()"></i>
    </div>
  }
</div>
