import { Injectable } from '@angular/core';
import { Report } from '@app/core/model/client/report';
import ApiService from '@services/api.service';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';

@Injectable()
export class MyReportService {

  private readonly reportGraphqlFragment: any;

  constructor(private apiService: ApiService) {
    this.reportGraphqlFragment = gql`
      fragment ReportConfig on UserReport {
        id
        organizationId
        enabled
        lastSendDate
        weekday
        timeOfDay
        frequency
        assetConfig
        spaceConfig
        checkConfig
        occupantConfig
        leaseConfig
        contractConfig
        workConfig
        equipmentConfig
        projectConfig
        investmentConfig
        documentConfig
      }
    `;
  }

  /**
   * Check if the current user report exists for the organization.
   * @param organizationId The ID of the organization.
   * @return Whether the report exists.
   */
  public doesUserReportExist(organizationId: string): Observable<boolean> {
    const query = gql`
      query DoesUserReportExist($organizationId: String!) {
        doesUserReportExist(organizationId: $organizationId)
      }
    `;
    const variables = {organizationId};

    return this.apiService.query({query, variables})
      .pipe(map(data => data['doesUserReportExist'] as boolean));
  }

  /**
   * Get user report by organization id
   * @param organizationId
   * @returns The User report
   */
  public getReportByOrganizationId(organizationId: string): Observable<Report> {
    const query = gql`
      query UserReport($organizationId: String!) {
        userReport(organizationId: $organizationId) {
          ...ReportConfig
        }
      }${this.reportGraphqlFragment}
    `;
    const variables = {organizationId};
    return this.apiService.query({query, variables})
      .pipe(map(data => plainToInstance(Report, data['userReport'])));
  }

  /**
   * Send last report for the organization
   * @param organizationId
   */
  public sendLastReport(organizationId: string): Observable<any> {
    const mutation = gql`
      mutation SendLastUserReport($organizationId: String!) {
        sendLastUserReport(organizationId: $organizationId)
      }
    `;
    const variables = {organizationId};

    return this.apiService.mutate({mutation, variables});
  }

  public updateUserReport(reportData: any): Observable<any> {
    const mutation = gql`
      mutation UpdateUserReport($reportId: Int!, $reportInput: UserReportInput!, $organizationId: String!) {
        updateUserReport(reportId: $reportId, reportInput: $reportInput, organizationId: $organizationId) {
          ...ReportConfig
        }
      }${this.reportGraphqlFragment}
    `;

    return this.apiService.mutate({mutation, variables: reportData});
  }

  /**
   * Create a user report configuration for the current user in the given organization.
   * @param organizationId The ID of the organization.
   * @param timezoneId The ID of the timezone.
   * @return The report.
   */
  public createReport(organizationId: string, timezoneId: string): Observable<Report> {
    const mutation = gql`
      mutation CreateUserReport($organizationId: String!, $timezoneId: String!) {
        createUserReport(organizationId: $organizationId, timezoneId: $timezoneId) {
          ...ReportConfig
        }
      }${this.reportGraphqlFragment}
    `;
    const variables = {organizationId, timezoneId};

    return this.apiService.mutate({mutation, variables})
      .pipe(map(data => plainToInstance(Report, data['createUserReport'])));
  }
}
