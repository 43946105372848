<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_WORK' | translate }}</h1>
  <mat-divider></mat-divider>
  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="workForm">

      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.WORK_ACTION' | translate }}</mat-label>
        <input matInput id="input-work-action" formControlName="action" required/>
        @if (workForm.get('action').hasError('required')) {
          <mat-error>
            {{ 'ERROR.FIELD_REQUIRED' | translate }}
          </mat-error>
        }
        @if (workForm.get('action').hasError('maxlength')) {
          <mat-error>
            {{ 'ERROR.FIELD_MAX_LENGTH' | translate: { value: appConfig.FIELD_MAX_LENGTH } }}
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.WORK_STATE' | translate }}</mat-label>
        <mat-select id="input_work_state" formControlName="state" required>
          @for (state of workStates | async; track state) {
            <mat-option [value]="state">
              {{ state }}
            </mat-option>
          }
        </mat-select>
        @if (workForm.get('state').hasError('required')) {
          <mat-error>
            {{ 'ERROR.FIELD_REQUIRED' | translate }}
          </mat-error>
        }
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button id="create-work-cancel-button" matDialogClose="cancel">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button mat-raised-button id="create-work-add-button" [disabled]="workForm.invalid" color="accent"
      matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
