import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Document } from '@app/core/model/entities/document/document';
import { Entity } from '@app/core/model/entities/entity';
import { Expose, Type } from 'class-transformer';

export class Investment extends Entity {
  public objectType = EntityTypeEnum.INVESTMENT;

  public identifier: number;
  public type: EntityTypeEnum;
  public entityId: string;
  public organizationId: string;
  public dataDate: string;

  @Type(() => Document) public documents: Document[] = [];

  @Expose()
  public toString(): string {
    return this.computedProperties?.['displayName'] ?? `${this.identifier.toString()} - ${this.name}`;
  }
}

export interface InvestmentInput {
  name?: string;
  type?: EntityTypeEnum;
  entityId?: string;
  properties?: Record<string, any>;
}
