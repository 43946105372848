import { AbstractBean } from '@app/core/model/abstract-bean';
import { Client } from '@app/core/model/client/client';
import {
  ClientSubscriptionModule,
  ClientSubscriptionModuleInput
} from '@app/core/model/client/client-subscription-module';
import CustomDuration, { DurationResult } from '@app/shared/extra/custom-duration';
import { Expose, Transform, Type } from 'class-transformer';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export class ClientSubscription extends AbstractBean {
  public nbUser: number;
  public contractId: string;

  @Type(() => String)
  @Transform(fn => dayjs(fn.value), {toClassOnly: true})
  public startDate: Dayjs;
  @Transform(fn => dayjs(fn.value), {toClassOnly: true})
  @Type(() => String)
  public endDate: Dayjs;
  @Type(() => ClientSubscriptionModule)
  public activeSubscriptionModules: ClientSubscriptionModule[];
  @Type(() => Client)
  public client: Client;

  /**
   * Whether this subscription is currently active, i.e. has already started and has not expired yet.
   */
  @Expose()
  public get isActive(): boolean {
    return dayjs().isBetween(this.startDate, this.endDate);
  }

  /**
   * Subscription's duration, starting from the start date.
   */
  @Expose()
  public get duration(): DurationResult {
    return CustomDuration.calculate(this.startDate, this.endDate);
  }

  @Expose()
  public toString(): string {
    return '';
  }

}

export interface ClientSubscriptionInput {
  startDate: string;
  endDate: string;
  nbUser: number;
  contractId?: string;
  subscriptionModules: ClientSubscriptionModuleInput[];
}
