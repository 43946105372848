import { inject, Injectable } from '@angular/core';
import { MutationOptions, QueryOptions, SubscriptionOptions } from '@apollo/client';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export default class ApiService {
  private apollo = inject(Apollo);

  /**
   * Send a GraphQL query request and return an Observable emitting the received data or error.
   * @param options Query options.
   * @return Observable emitting the content of the GraphQL response's 'data' field or the first error.
   */
  public query<T, V = {}>(options: QueryOptions<V, T>): Observable<T> {
    return this.apollo.query<T, V>(options)
      .pipe(
        map(response => {
          if (response.error) {
            throw response.error;
          } else if (response.errors) {
            throw response.errors[0];
          } else {
            return response.data;
          }
        })
      );
  }

  /**
   * Send a GraphQL mutation request and return an Observable emitting the received data or error.
   * @param options Mutation options.
   * @return Observable emitting the content of the GraphQL response's 'data' field or the first error.
   */
  public mutate<T, V = {}>(options: MutationOptions<T, V>): Observable<T> {
    return this.apollo.mutate<T, V>(options)
      .pipe(
        map(response => {
          if (response.errors) {
            throw response.errors[0];
          } else {
            return response.data;
          }
        })
      );
  }

  /**
   * Send a GraphQL subscription request and return an Observable emitting the received data or error.
   * @param options Subscription options.
   * @return Observable emitting the content of the GraphQL subscription messages' 'data' field or the first error.
   */
  public subscribe<T, V = {}>(options: SubscriptionOptions<V, T>): Observable<T> {
    return this.apollo.subscribe<T, V>(options)
      .pipe(
        map(response => {
          if (response.errors) {
            throw response.errors[0];
          } else {
            return response.data;
          }
        })
      );
  }

}
